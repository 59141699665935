<template>
  <div class="profilePassword">
    <v-row
      justify="center"
      class="vertical-centered"
    >
      <v-col>
        <modify-password
          v-if="loggedInUser"
          :org-id="loggedInUser.org_id"
          :user-id="loggedInUser.id"
          :check-current-password="true"
          @submit="editPassword"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PasswordAPI } from '@/API/authenticator/PasswordAPI';
import ModifyPassword from '@/components/common/elements/Password/ModifyPassword';

export default {
  name: 'ProfilePassword',

  components: { ModifyPassword },

  computed: {
    ...mapGetters(['loggedInUser']),
  },

  methods: {
    async editPassword(form) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await PasswordAPI.changePassword(form);
        this.$store.commit(
          'setSuccessMessage',
          this.$t('profile.updated'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch(error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.profilePassword {
  width: 90%;
  height: 100%;
}
</style>
