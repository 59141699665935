<template>
  <TableWithFooter
    class="top-gap"
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-col cols="auto" />
      <v-col cols="6">
        <div style="margin-left: 16px">
          {{ $t('dataPoints.label_group') }}
        </div>
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        class="justify-center"
      >
        {{ $t('display') }}
      </v-col>
      <v-col
        v-if="user.role === 'orgadmin'"
        cols="4"
      >
        <div style="width: 100%">
          <div class="d-flex justify-center mt-1">
            {{ $t('docTypes.as_table') }}
          </div>
          <div
            class="d-flex justify-center"
            style="margin-top: -10px"
          >
            <v-switch
              v-model="allTableDisplay"
              class="d-block"
              color="primary"
              density="compact"
              :disabled="disabled"
              @change="updateBatchTableDisplay(pages[currentPage])"
              hide-details
            />
          </div>
        </div>
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <draggable
          v-model="pages[currentPage]"
          ghost-class="ghost-dp"
          handle=".handle"
          @change="(event) => handleDrag(event, pages[currentPage])"
        >
          <div
            v-for="item in pages[currentPage]"
            :key="item.id"
          >
            <v-row
              class="table-row fade-in table-row-height"
              style="border-bottom: 1px solid #eee !important"
            >
              <v-col cols="auto">
                <v-icon
                  color="grey"
                  size="17"
                  class="clickable handle"
                  style="opacity: 0.5"
                >
                  fas fa-grip-vertical
                </v-icon>
              </v-col>
              <v-col cols="6">
                <ItemName
                  :key="item.id"
                  :ref="`name_${item.id}`"
                  style="z-index: 202; max-width: calc(100% - 24px)"
                  :item="item"
                  :clickable="false"
                />
              </v-col>
              <v-col
                v-if="user.role === 'orgadmin'"
                class="justify-center"
              >
                <v-switch
                  v-model="item.display_in_review"
                  color="primary"
                  density="compact"
                  :disabled="disabled"
                  @change="updateIntermediate(item)"
                  hide-details
                />
              </v-col>
              <v-col
                v-if="user.role === 'orgadmin'"
                class="justify-center"
                cols="4"
              >
                <v-switch
                  v-model="item.table_display"
                  color="primary"
                  density="compact"
                  :disabled="disabled || item.aggregation_method === 'cluster'"
                  @change="updateTableDisplay(item)"
                  hide-details
                />
              </v-col>
            </v-row>
          </div>
        </draggable>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { ExtractionGroupAPI } from '@/API/extract/ExtractionGroupAPI';
import { VueDraggableNext } from "vue-draggable-next";
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';

export default {
  name: 'LabelGroupsReviewTable',

  components: {
    ItemName,
    TableWithFooter,
    draggable: VueDraggableNext,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      itemsPerPage,
      currentPage,
      allTableDisplay: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
    },

    currentPage() {
      this.allTableDisplay = !!this.pages[this.currentPage] && this.pages[this.currentPage].every(item => item.table_display);
    },

    pages() {
      this.allTableDisplay = !!this.pages[this.currentPage] && this.pages[this.currentPage].every(item => item.table_display);
    }
  },

  methods: {
    handleDrag(event, currentPageGroups) {
      const groups = [...this.allItems];
      const currentIndex = (this.currentPage - 1) * this.itemsPerPage;
      this.updatePos(event.moved.element.id, currentIndex + event.moved.newIndex);
      // For smooth transition until position is updated, and items are reloaded
      groups.splice(currentIndex, this.itemsPerPage, ...currentPageGroups);
      this.$emit('setItems', [...groups]);
    },

    async updatePos(id, pos) {
      try {
        this.$store.commit('setLoadingScreen', true);
        await ExtractionGroupAPI.put(id, { position: pos });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
        this.$emit('getItems');
      }
    },

    updateTableDisplay(group) {
      this.$store.commit('setLoadingScreen', true);
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await ExtractionGroupAPI.put(group.id, { table_display: group.table_display });
          displaySnackbarSuccess(this.$t('docTypes.dataPoints.group_updated'));
          this.$emit('saveName');
          this.allTableDisplay = this.pages[this.currentPage].every(item => item.table_display)
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }, waitTime);
    },

    async updateBatchTableDisplay(groups) {
      this.$store.commit('setLoadingScreen', true);
      try {
        this.$emit('setItems', groups.map(group => ({ ...group, table_display: this.allTableDisplay })));
        const promises = groups.map(group => ExtractionGroupAPI.put(group.id, { table_display: this.allTableDisplay }));
        await Promise.all(promises);
        displaySnackbarSuccess(this.$t('docTypes.dataPoints.group_updated'));
        this.$emit('saveName');
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    updateIntermediate(group) {
      this.$store.commit('setLoadingScreen', true);
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await ExtractionGroupAPI.put(group.id, { intermediate: !group.display_in_review });
          displaySnackbarSuccess(this.$t('docTypes.dataPoints.group_updated'));
          this.$emit('saveName');
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }, waitTime);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['saveName', 'setItems', 'getItems'],
}
</script>
