<template>
  <div class="workflows-view page-padding py-7">
    <PageTabs
      v-model="activeTab"
      :tabs="filteredTabItems"
    />
    <template v-if="activeTab === 'input'">
      <v-radio-group
        id="inputRadio"
        v-model="activeInput"
        class="inline-middle mt-6"
        inline
      >
        <v-radio
          class="radio-box mb-0"
          value="mailboxes"
          :label="$t('mailboxes.title')"
        />
        <v-radio
          class="radio-box mb-0 ml-3"
          value="S3"
          :label="$t('workflows.s3.title')"
          disabled
        />
        <v-radio
          class="radio-box mb-0 ml-3"
          value="ftp"
          :label="$t('workflows.ftp.title')"
          disabled
        />
      </v-radio-group>
      <MailboxesTable
        v-if="activeInput === 'mailboxes'"
        style="margin-top: -20px;"
      />
    </template>
    <template v-if="activeTab === 'workflows'">
      <WorkflowsTable />
    </template>
    <template v-if="activeTab === 'jobs'">
      <JobsTable />
    </template>
    <template v-if="activeTab === 'resources'">
      <ResourcesView />
    </template>
  </div>
</template>

<script>
import MailboxesTable from '@/components/extract/views/Workflows/Mailboxes/MailboxesTable';
import PageTabs from '@/components/common/elements/General/PageTabs';
import WorkflowsTable from '@/components/extract/views/Workflows/WorkflowsTable';
import JobsTable from '@/components/extract/views/Workflows/JobsTable';
import ResourcesView from '@/components/extract/views/Workflows/ResourcesView';

export default {
  name: 'WorkflowsView',

  components: {
    MailboxesTable,
    PageTabs,
    WorkflowsTable,
    JobsTable,
    ResourcesView,
  },

  data() {
    let activeTab = 'workflows';

    if (this.$route.query.activeTab) {
      activeTab = this.$route.query.activeTab;
    }

    return ({
      activeInput: 'mailboxes',
      activeTab: activeTab,
      tabItems: [
        {
          name: 'workflows',
          title: this.$t('workflows.title'),
          products: ['workflows'],
        },
        {
          name: 'jobs',
          title: this.$t('workflows.jobs.title'),
          products: ['workflows'],
        },
        {
          name: 'input',
          title: this.$t('workflows.input'),
          products: ['workflows', 'classify'],
        },
        {
          name: 'resources',
          title: this.$t('workflows.resources.title'),
          products: ['workflows'],
        },
      ],
    });
  },

  computed: {
    filteredTabItems() {
      return this.tabItems.filter(
        tab => tab.products.every(
          product => this.$store.getters.loggedInUser.products.includes(product),
        ),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.workflows-view {
  height: fit-content;
}
</style>
