<template>
  <v-dialog
    v-model="openDialog"
    max-width="400"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card class="dialog-card">
      <h2 class="dialog-title mb-8">
        {{ $t('dataPoints.generative.generative_copy_name_title') }}
      </h2>
      <div class="warning-message bottom-gap">
        <v-icon
          class="mr-2"
          style="margin-bottom: 1px;"
          size="14"
        >
          fas fa-info-circle
        </v-icon>
        <small>
          {{ $t('dataPoints.generative.name_warning') }}
        </small>
      </div>
      <p class="bottom-gap-sm">
        {{ $t('dataPoints.generative.generative_copy_name_message') }}
      </p>
      <v-text-field
        ref="nameField"
        v-model="name"
        style="width: 280px !important"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('dataPoints.type_name')"
        hide-details
      />
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('close')"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            :disabled="trimmedName.length < 1"
            @click="$emit('save', trimmedName)"
            block
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DataPointNameDialog',

  data() {
    return ({
      openDialog: this.modelValue,
      name: '',
    });
  },

  computed: {
    trimmedName() {
      return this.name.trim();
    },
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
      if (open) {
        this.name = this.initialName;
      }
    },

    modelValue(show) {
      this.openDialog = show;
      if (show) {
        this.$nextTick(() => this.$refs.nameField.focus());
      }
    },
  },

  mounted() {
    this.handleKeydown();
  },

  methods: {
    handleKeydown() {
      document.addEventListener("keydown", event => {
        if (this.openDialog === false || this.trimmedName.length < 1) {
          return;
        }
        switch (event.key) {
          case "Enter":
            this.$emit('save', this.trimmedName);
            break;
        }
      });
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    initialName: {
      type: String,
      default: '',
    },
  },

  emits: ['close', 'save', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}


.warning-message {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border: 1px solid rgb(var(--v-theme-primary));
  padding: 8px 10px;
  padding-right: 15px;
  border-radius: 4px;
}
</style>
