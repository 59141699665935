export function getExtension(filename) {
  return filename.split('.').pop().toLowerCase();
}

export function getExtensionIcon(extension) {
  switch (extension) {
    case 'pdf':
      return 'fas fa-file-pdf';
    case 'doc':
      return 'fas fa-file-word';
    case 'docx':
      return 'fas fa-file-word';
    case 'docm':
      return 'fas fa-file-word';
    case 'xls':
      return 'fas fa-file-excel';
    case 'xlsx':
      return 'fas fa-file-excel';
    case 'xlsm':
      return 'fas fa-file-excel';
    case 'ppt':
      return 'fas fa-file-powerpoint';
    case 'pptm':
      return 'fas fa-file-powerpoint';
    case 'pptx':
      return 'fas fa-file-powerpoint';
    case 'py':
      return 'fas fa-file-code';
    default:
      return 'fas fa-file-alt';
  }
}
