<template>
  <v-dialog
    v-model="showSummary"
    class="summary-dialog"
    @click:outside="cancelEdit"
  >
    <v-card class="pa-4">
      <QuillEditor
        v-model:content="localEditorContent"
        content-type="html"
        :options="editorOptions"
      />
      <v-row class="d-flex justify-end pt-4">
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="saveContent"
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="cancelEdit"
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  name: 'WorkflowSummaryDialog',
  components: {
    QuillEditor
  },

  data() {
    return {
      showSummary: this.modelValue,
      localEditorContent: this.editorContent,
      editorOptions: {
        theme: 'snow'
      }
    };
  },

  watch: {
    modelValue(show) {
      this.showSummary = show;
      if (show) {
        this.localEditorContent = this.editorContent;
      }
    }
  },

  methods: {

    saveContent() {
      this.$emit('summary-updated', this.localEditorContent);
      this.$emit('update:modelValue', false);
    },

    cancelEdit() {
      this.$emit('update:modelValue', false);
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    workflow: {
      type: Object,
      required: true,
    },
    editorContent: {
      type: String,
      required: true,
    }
  },
  emits: ['update:modelValue', 'summary-updated'],
}
</script>

<style lang="scss" scoped>
.summary-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  overflow: auto;
  max-width: 70%;
}
</style>
