<template>
  <div class="emails-datasets-table page-padding py-7">
    <BreadcrumbComponent />
    <div class="emails-dataset-actions-container">
      <v-text-field
        v-model="searchedDatasetName"
        class="right-gap name-filter-field inline-middle"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('filter')"
        @input="onNameFilterInput"
      />
      <div class="emails-datasets-button__container">
        <ButtonWithIcon
          class="create-dataset-button"
          icon="fas fa-plus"
          color="primary"
          variant="outlined"
          :message="$t('emailsDatasets.create')"
          @click="() => openEmailsDatasetEditor()"
          rounded
        />
        <ButtonWithIcon
          class="import-datasets-button"
          icon="fas fa-download"
          color="primary"
          variant="outlined"
          :message="$t('import')"
          @click="() => displayUploadZippedEmailsDatasetsDialog = true"
          rounded
        />
      </div>
    </div>
    <TableWithFooter
      :loading="false"
      :paginated-items-length="datasets.length"
      :total="totalDatasets"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      @sort="onSort"
      sortable
    >
      <template #header>
        <v-col cols="6">
          {{ $t('emailsDatasets.dataset') }}
        </v-col>
        <v-col cols="3">
          {{ $t('emailsDatasets.emails_count_column') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="dataset in datasets"
            :key="dataset.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="6">
              <v-tooltip
                v-if="dataset.status === 'uploading'"
                bottom
              >
                <template #activator="{ props }">
                  <v-icon
                    class="right-gap-sm"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-spinner fa-pulse
                  </v-icon>
                </template>
                {{ $t(`emailsDatasets.${dataset.status}_tooltip`) }}
              </v-tooltip>
              <ItemName
                :key="dataset.id"
                type="dataset"
                class="inline-top"
                :item="dataset"
                @name-click="openEmailsDatasetView(dataset.id)"
              />
            </v-col>
            <v-col cols="3">
              {{ dataset.emails_count }}
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <UploadZippedEmailsDatasetsDialog
      v-model="displayUploadZippedEmailsDatasetsDialog"
    />
  </div>
</template>

<script>

import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter.vue';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';
import UploadZippedEmailsDatasetsDialog from './UploadZippedEmailsDatasetsDialog.vue';


export default {
  name: 'EmailsDatasetView',

  components: {
    ItemName,
    TableWithFooter,
    BreadcrumbComponent,
    ButtonWithIcon,
    UploadZippedEmailsDatasetsDialog,
  },

  constants: {
    FETCH_DATASETS_TIMEOUT: 4000,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      datasets: [],
      totalDatasets: 0,
      itemsPerPage,
      currentPage,
      searchedDatasetName: '',
      descendingSort: true,
      displayUploadZippedEmailsDatasetsDialog: false,
    };
  },

  computed: {
    isUploadingDataset() {
      /* Return True if there is a dataset in an uploading status. */
      const datasetsStatuses = this.datasets.map((dataset) => dataset.status);

      return datasetsStatuses.some((status) => status === 'uploading');
    },
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
      this.fetchEmailsDatasets();
    },

    currentPage() {
      this.fetchEmailsDatasets();
    },
  },

  async created() {
    this.setBreadcrumb();
    await this.fetchEmailsDatasets();
    if (this.isUploadingDataset) {
      this.initRecurrentDatasetsFetching();
    }
  },

  unmounted() {
    this.cancelRecurrentDatasetsFetching();
  },

  methods: {
    async fetchEmailsDatasets() {
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const searchedDatasetName = this.searchedDatasetName.trim().toLowerCase();
      const response = await EmailsDatasetsAPI.fetchAll({
        name: searchedDatasetName, offset, limit: this.itemsPerPage, descendingSort: this.descendingSort});

      this.datasets = response.data;
      this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
    },

    async onNameFilterInput() {
      this.resetCurrentPage();
      await this.fetchEmailsDatasets();
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },

    openEmailsDatasetEditor() {
      this.$router.push({ name: 'EmailsDatasetEditor'});
    },

    openEmailsDatasetView(id) {
      this.$router.push({ name: 'EmailsDatasetView', params: { datasetId: id } });
    },

    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
          { title: this.$t('menu.studio') },
          { title: this.$t('menu.emails_datasets') },
        ]
      );
    },

    initRecurrentDatasetsFetching() {
      this.recurrentDatasetsFetchingId = setInterval(() => {
        this.fetchEmailsDatasets();
        if (!this.isUploadingDataset) {
          this.cancelRecurrentDatasetsFetching();
        }
      }, this.$options.constants.FETCH_DATASETS_TIMEOUT);
    },

    cancelRecurrentDatasetsFetching() {
      clearInterval(this.recurrentDatasetsFetchingId);
    },

    onSort(descendingSort) {
      this.descendingSort = descendingSort;
      this.fetchEmailsDatasets();
    },
  },

  emits: [],
}
</script>

<style lang="scss" scoped>

.emails-dataset-actions-container {
  display: inline-flex;
  gap: .25em;
  align-items: start;
}

.name-filter-field {
  width: 300px;
}

.emails-datasets-button__container {
  height: 42px;
  display: flex;
  gap: 1em;
  align-items: center;
}

</style>
