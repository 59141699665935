<template>
  <div class="new-type-view page-padding py-7">
    <BreadcrumbComponent />
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-text-field
          v-model="typeName"
          class="right-gap"
          style="width: 280px"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('docTypes.type_name')"
          @keydown.enter="addNew"
        />
        <v-btn
          color="primary"
          style="margin-top: -20px"
          :disabled="typeName.trim() === ''"
          @click="addNew"
          rounded
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </div>
    <div
      class="d-flex align-center"
      style="height: 44px;"
    >
      <v-tooltip right>
        <template #activator="{ props }">
          <v-icon
            class="right-gap-sm clickable"
            color="primary"
            size="16"
            v-bind="props"
          >
            fas fa-info-circle
          </v-icon>
        </template>
        {{ $t(`docTypes.languages.message`) }}
      </v-tooltip>
      <span class="form-label right-gap-sm">
        {{ $t(`docTypes.languages.name`) }}
      </span>
      <v-chip
        v-for="(language, i) in languages"
        :key="language"
        class="mr-1"
        style="background-color: #CCC2FF !important"
        close-icon="fas fa-times"
        variant="outlined"
        :closable="languages.length > 1"
        @click:close="removeLanguage(i)"
      >
        {{ languageMap[language] }}
      </v-chip>
      <v-chip
        v-if="languages.length === 0"
        class="font-italic"
        variant="outlined"
        style="background-color: #CCC2FF !important"
      >
        {{ $t(`docTypes.no_languages`) }}
      </v-chip>
      <v-btn
        v-if="!addingLanguage"
        class="left-gap-sm small-button"
        color="primary"
        :disabled="allowedLanguages.length === 0"
        @click="addingLanguage = true"
        rounded
      >
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
      <v-select
        v-else
        style="width: 200px;"
        class="inline-middle left-gap-sm flex-grow-0 mt-0"
        variant="outlined"
        color="primary"
        density="compact"
        item-title="text"
        item-value="value"
        :placeholder="$t('docTypes.choose_language')"
        :items="allowedLanguages"
        @update:model-value="addLanguage"
      />
    </div>
    <FileUploader
      v-if="user.role === 'orgadmin'"
      class="mt-12"
      @files-selected="handleFilesSelect"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { FileAPI } from '@/API/extract/FileAPI';

import FileUploader from '@/components/common/elements/Forms/FileUploader';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";

import ui_mixin from '@/mixins/ui';

export default {
  name: 'NewTypeView',

  mixins: [ui_mixin],

  components: {
    FileUploader,
    BreadcrumbComponent,
  },

  data() {
    return ({
      typeName: '',
      id: -1,
      files: [],
      languages: ['fr'],
    })
  },

  computed: {
    allowedLanguages() {
      return ['en', 'fr']
        .filter(l => !this.languages.includes(l))
        .map(l => ({text: this.languageMap[l], value: l }));
    },

    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  mounted() {
    const breadcrumb = [
      { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
      {
        title:
          this.$store.getters.loggedInUser.role === 'sysadmin' ?
            this.$t('docTypes.recital_library') :
            this.$t('breadcrumb.docTypes'),
        href: {name: 'Suite'} ,
      },
      { title: this.$t('docTypes.create'), href: '/suite/studio/agents/extraction/create' },
    ];
    if (this.$store.getters.sourceModel) {
      breadcrumb.push({ title: `${this.$t('docTypes.custom_from_model')} ${this.$store.getters.sourceModel.customModel}`});
    } else {
      breadcrumb.push({ title: this.$t('docTypes.custom') });
    }
    this.$store.commit('setBreadcrumb', breadcrumb);
  },

  methods: {
    addLanguage(language) {
      this.languages.push(language);
      this.addingLanguage = false;
    },

    removeLanguage(index) {
      this.languages = [...this.languages.filter((l, i) => i !== index)];
    },

    async handleModels() {
      const sourceModel = this.$store.getters.sourceModel;
      if (sourceModel) {
        const readingOrder = sourceModel.readingOrder;
        if (readingOrder) {
          await this.updateReadingOrder(readingOrder);
        }
        if (sourceModel.groupsToCreate && sourceModel.groupsToCreate.length > 0) {
          await this.preCreateGroups();
        } else {
          this.addDPs(sourceModel.labels, 'custom');
        }
      }
    },

    async updateReadingOrder(readingOrder) {
      try {
        this.$store.commit("setLoadingScreen", true);
        await http.put(
          `system_2/document_type/${this.id}/`,
          { custom_reading_order: readingOrder }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async preCreateGroups() {
      let groupsToCreate = [];
      if (this.$store.getters.sourceModel) {
        groupsToCreate = this.$store.getters.sourceModel.groupsToCreate;
      }
      for (let group of groupsToCreate) {
        try {
          await http.post('system_2/extraction_groups/', group, {
            params: {
              document_type_id: this.id,
            }
          });
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
      }
    },

    addDPs(entities) {
      if (this.$store.getters.sourceModel) {
        http.post(`system_2/data_point/create_from_labels/${this.id}/`, entities, {
          params: {
            custom_model_id: this.$store.getters.sourceModel.customModel,
            custom_model_version: this.$store.getters.sourceModel.modelVersion,
          }
        });
      }
    },

    async addNew() {
      this.$store.commit('setLoadingScreen', true);
      try {
        const {data: id} = await http.post(
          'system_2/document_type/',
          {
            name: this.typeName,
            extracted_pages: ['ALL'],
            languages: this.languages,
          }
        );
        this.id = id;
        await this.handleModels();
        this.$store.commit('setSuccessMessage', this.$t('docTypes.added'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
        if (this.files.length > 0) {
          await this.uploadFiles();
        }
        if (this.files.length === 0 || this.user.role === 'sysadmin') {
          this.goToDP();
        }
      } catch (error) {
        this.$store.commit('setLoadingScreen', false);
        return
      }
    },

    async handleFilesSelect(files) {
      this.files = files;
      if (this.id >= 0) {
        await this.uploadFiles();
      }
    },

    async uploadFiles() {
      const length = this.files.length;
      if (length > 0) {
        if (typeof this.files === 'object') {
          this.files = Object.values(this.files).map(item => item);
        }
        for (let i = 0; i < length; i++) {
          await this.startUpload(this.files[i]);
        }
        this.goToDP();
      }
    },

    goToDP() {
      this.$store.commit('setSourceModel', null);
      if (this.user.role === 'sysadmin') {
        this.$router.push({
          name: 'ExtractionAgents',
        });
      } else {
        this.$router.push({
          name: 'DocType',
          params: {
            id: this.id,
            tab: 'documents',
          },
        });
      }
    },

    async startUpload(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await FileAPI.post(this.id, file);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  }
}
</script>
