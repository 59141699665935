<template>
  <v-dialog
    v-model="openDialog"
    class="snippet-dialog"
    max-width="800"
    @input="v => $emit('update:modelValue', v)"
    @keydown.esc="$emit('update:modelValue', false)"
  >
    <v-card class="dialog-card">
      <h2 class="dialog-title mb-3">
        {{ title }}
      </h2>
      <div style="position: relative">
        <div
          ref="code"
          class="code-area code-snippet language-python"
          rows="20"
          readonly
          disabled
        >
          {{ snippetText }}
        </div>
        <v-tooltip
          color="#423f4f"
          bottom
        >
          <template #activator="{ props }">
            <div
              v-bind="props"
              class="v-btn v-btn--elevated v-theme--lightTheme bg-primary copy-icon small-button clickable"
              @click="handleCopy"
            >
              <span class="v-btn__overlay" />
              <v-icon size="16">
                fas fa-clipboard
              </v-icon>
            </div>
          </template>
          <span class="white--text">
            {{ $t('copy') }}
          </span>
        </v-tooltip>
      </div>
      <div class="dialog-button-container top-gap-sm">
        <v-btn
          color="primary"
          style="font-size: 12px; font-weight: 600; letter-spacing: initial;"
          @click="$emit('update:modelValue', false)"
          rounded
        >
          {{ $t('close') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { nextTick } from 'vue';
import hljs from 'highlight.js';
import '@/assets/default_highlights.css';

export default {
  name: 'SnippetDialog',

  data() {
    return({
      openDialog: this.modelValue,
    });
  },

  watch: {
    openDialog(open) {
      if (open) {
        nextTick(() => {
          hljs.highlightElement(this.$refs.code, { language: 'python' });
        });
      }
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  methods: {
    handleCopy() {
      navigator.clipboard.writeText(this.snippetText);
      this.$store.commit(
        'setSuccessMessage', this.$t('docTypes.snippet_copy')
      );
      this.$store.commit('setSuccessSnackbar', true);
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    snippetText: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue'],
}
</script>

<style lang="scss" scoped>
.code-snippet {
	width: 100%;
	background-color: rgb(var(--v-theme-grey-lighten2));
  border-radius: 4px;
  border: 1px solid rgb(var(--v-theme-grey-darken2));
	white-space: pre-wrap;
	resize: none;
	font-family: monospace;
	padding: 1rem;
	position: relative;
  cursor: text;
}

.copy-icon {
	position: absolute;
	top: 7px;
	right: 5px;
	z-index: 205;
}

.dialog-button-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
</style>
