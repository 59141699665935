<template>
  <div class="annotation-toolbox">
    <div class="annotation-helpers-container">
      <div
        v-if="isAnnotating && displayAnnotationModeButtons"
        class="annotation-buttons-container"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              :class="[
                'annotation-toolbox-button',
                'small-button',
                {
                  'is-selected': annotationSelectionMode === 'draw',
                  'is-unselected': annotationSelectionMode !== 'draw',
                },
              ]"
              v-bind="props"
              @click="$emit('update:annotationSelectionMode', 'draw')"
            >
              <v-icon size="20">
                mdi mdi-select-drag
              </v-icon>
            </v-btn>
          </template>
          {{ $t('models.draw_mode') }}
        </v-tooltip>
        <v-tooltip
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              :class="[
                'annotation-toolbox-button',
                'small-button',
                {
                  'is-selected': annotationSelectionMode === 'select',
                  'is-unselected': annotationSelectionMode !== 'select',
                },
              ]"
              v-bind="props"
              @click="$emit('update:annotationSelectionMode', 'select')"
            >
              <v-icon size="16">
                {{ 'fas fa-hand-pointer' }}
              </v-icon>
            </v-btn>
          </template>
          {{ $t('models.select_mode') }}
        </v-tooltip>
      </div>
      <div
        v-if="displayExtractorConfigTools"
        class="annotation-buttons-container"
      >
        <v-tooltip
          v-if="displayShowText"
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              :class="[
                'annotation-toolbox-button',
                'small-button',
                {
                  'is-selected': showText,
                  'is-unselected': !showText,
                },
              ]"
              v-bind="props"
              @click="$emit('update:showText', !showText)"
            >
              <v-icon size="20">
                mdi mdi-format-text
              </v-icon>
            </v-btn>
          </template>
          {{ $t('models.show_text') }}
        </v-tooltip>
      </div>
      <div class="annotation-buttons-container">
        <v-tooltip
          v-if="displayShowTokens"
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              :class="[
                'annotation-toolbox-button',
                'small-button',
                {
                  'is-selected': showTokens,
                  'is-unselected': !showTokens,
                },
              ]"
              :disabled="annotationSelectionMode === 'click'"
              v-bind="props"
              @click="() => {
                if (annotationSelectionMode !== 'click') {
                  $emit('update:showTokens', !showTokens);
                }
              }"
            >
              <v-icon size="16">
                {{ 'fas fa-paragraph' }}
              </v-icon>
            </v-btn>
          </template>
          {{ $t('models.show_words') }}
        </v-tooltip>
        <v-tooltip
          v-if="displayShowEntities"
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              :class="[
                'annotation-toolbox-button',
                'small-button',
                {
                  'is-selected': showEntities,
                  'is-unselected': !showEntities,
                },
              ]"
              v-bind="props"
              @click="$emit('update:showEntities', !showEntities)"
            >
              <v-icon size="16">
                {{ 'fas fa-align-justify' }}
              </v-icon>
            </v-btn>
          </template>
          {{ $t('models.show_entities') }}
        </v-tooltip>
        <div
          v-if="isDatascientist"
          class="datascience-bar"
          style="display: contents;"
        >
          <v-tooltip
            bottom
          >
            <template #activator="{ props }">
              <v-btn
                variant="text"
                :class="[
                  'annotation-toolbox-button',
                  'small-button',
                  {
                    'is-selected': showBlocks,
                    'is-unselected': !showBlocks,
                  },
                ]"
                v-bind="props"
                @click="$emit('update:showBlocks', !showBlocks)"
              >
                <v-icon size="16">
                  {{ 'fas fa-th-large' }}
                </v-icon>
              </v-btn>
            </template>
            {{ $t('models.show_blocks') }}
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ props }">
              <v-btn
                variant="text"
                :class="[
                  'annotation-toolbox-button',
                  'small-button',
                  {
                    'is-selected': showTables,
                    'is-unselected': !showTables,
                  },
                ]"
                v-bind="props"
                @click="$emit('update:showTables', !showTables)"
              >
                <v-icon size="16">
                  {{ 'fas fa-table' }}
                </v-icon>
              </v-btn>
            </template>
            {{ $t('models.show_tables') }}
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ props }">
              <v-btn
                variant="text"
                :class="[
                  'annotation-toolbox-button',
                  'small-button',
                  {
                    'is-selected': showReadingOrder,
                    'is-unselected': !showReadingOrder,
                  },
                ]"
                v-bind="props"
                @click="$emit('update:showReadingOrder', !showReadingOrder)"
              >
                <v-icon size="16">
                  {{ 'fas fa-list-ol' }}
                </v-icon>
              </v-btn>
            </template>
            {{ $t('models.reading_order') }}
          </v-tooltip>
        </div>
      </div>
      <CustomSelect
        v-if="showReadingOrder"
        class="reading-order-select overflow-y-visible ml-4"
        name-field="title"
        value-field="value"
        width="250px"
        placeholder="select"
        :selected="selectedReadingOrder"
        :items="[
          { title: 'Default', value: 'default' },
          { title: 'Roma2', value: 'roma2' },
          { title: 'Roma3', value: 'roma3' },
        ]"
        @selected-changed="$emit('update:selectedReadingOrder', $event)"
        clickable
      />
      <div
        class="annotation-buttons-container"
      >
        <v-tooltip
          v-if="showReadingOrder || isDatascientist"
          bottom
        >
          <template #activator="{ props }">
            <v-btn
              variant="text"
              :class="[
                'annotation-toolbox-button',
                'small-button',
                {
                  'is-selected': useTables,
                  'is-unselected': !useTables,
                },
              ]"
              v-bind="props"
              @click="$emit('update:useTables', !useTables)"
            >
              <v-icon size="16">
                {{ 'fas fa-table' }}
              </v-icon>
            </v-btn>
          </template>
          {{ $t('models.use_tables') }}
        </v-tooltip>
      </div>
    </div>
    <div
      v-if="displayAnnotationTools"
      class="annotation-buttons-container"
    >
      <div
        v-for="(mode, i) in modes"
        :key="i"
      >
        <v-tooltip bottom>
          <template #activator="{ props }">
            <v-btn
              variant="text"
              :class="[
                'annotation-toolbox-button',
                'small-button',
                {
                  'is-selected': annotationMode === mode.name,
                  'is-unselected': annotationMode !== mode.name,
                },
              ]"
              :disabled="mode.disabled"
              v-bind="props"
              @click="annotationMode = mode.name"
            >
              <v-icon size="16">
                {{ mode.icon }}
              </v-icon>
            </v-btn>
          </template>
          {{ $t(`models.select_${mode.name}`) }}
        </v-tooltip>
      </div>
      <div
        v-for="(autoMode, j) in automatics"
        :key="j"
      >
        <v-tooltip bottom>
          <template #activator="{ props }">
            <v-btn
              variant="text"
              v-bind="props"
              :class="[
                'annotation-toolbox-button',
                'small-button', {
                  'is-selected': automaticMode === autoMode.name,
                  'is-unselected': automaticMode !== autoMode.name,
                },
              ]"
              @click="changeAutoMode(autoMode.name)"
            >
              <v-icon size="16">
                {{ autoMode.icon }}
              </v-icon>
            </v-btn>
          </template>
          {{ $t(`models.select_${autoMode.name}`) }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/common/elements/Forms/CustomSelect.vue';

export default {
  name: "AnnotationToolbox",

  components: {
    CustomSelect,
  },

  computed: {
    modes() {
      return [
        {
          name: 'text',
          icon: 'fas fa-font',
          disabled: false,
        },
        {
          name: 'lines',
          icon: 'fas fa-list-ul',
          disabled: this.labelType === 'location',
        },
      ];
    },

    automatics() {
      return [
        {
          name: 'fixed-section',
          icon: 'fas fa-bullseye',
          disabled: this.labelType === 'location',
        },
        {
          name: 'all-similar',
          icon: 'fas fa-search',
          disabled: this.labelType === 'location',
        },
      ];
    },

    annotationMode: {
      get() {
        return this.$store.getters.annotationMode;
      },
      set(mode) {
        this.$store.commit('setAnnotationMode', mode);
      },
    },

    automaticMode: {
      get() {
        return this.$store.getters.automaticMode;
      },
      set(value) {
        this.$store.commit('setautomaticMode', value);
      },
    },

    displayShowTokens() {
      return ['annotation', 'validator', 'review'].includes(this.type) && this.document && this.document.id > 0;
    },

    displayShowEntities() {
      return this.type === 'validator' && this.document && this.document.id > 0;
    },

    isDatascientist() {
      if (this.config.datascience) {
        return true;
      }

      const user = this.$store.getters.loggedInUser;
      if (user) {
        return user.is_datascientist;
      }
      return false;
    },

    displayAnnotationTools() {
      return this.type === 'annotation';
    },
  },

  watch: {
    annotationMode(mode) {
      if (mode !== 'text') {
        this.automaticMode = '';
      }
    },
  },

  methods: {
    changeAutoMode(autoMode) {
      if (this.automaticMode === autoMode) {
        this.automaticMode = '';
      } else {
        this.automaticMode = autoMode;
        this.annotationMode = 'text';
      }
    }
  },

  props: {
    labelType: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: '',
    },

    document: {
      type: Object,
      default: () => ({}),
    },

    config: {
      type: Object,
      default: () => ({}),
    },

    showTokens: {
      type: Boolean,
      default: false,
    },

    showEntities: {
      type: Boolean,
      default: false,
    },

    showBlocks: {
      type: Boolean,
      default: false,
    },

    showTables: {
      type: Boolean,
      default: false,
    },

    showReadingOrder: {
      type: Boolean,
      default: false,
    },

    useTables: {
      type: Boolean,
      default: false,
    },

    selectedReadingOrder: {
      type: String,
      default: '',
    },

    isAnnotating: {
      type: Boolean,
      default: false,
    },

    annotationSelectionMode: {
      type: String,
      default: 'draw',
    },

    displayAnnotationModeButtons: {
      type: Boolean,
      default: true,
    },

    displayExtractorConfigTools: {
      type: Boolean,
      default: false,
    },

    displayShowText: {
      type: Boolean,
      default: false,
    },

    showText: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:showTokens',
    'update:showEntities',
    'update:showBlocks',
    'update:showTables',
    'update:showReadingOrder',
    'update:useTables',
    'update:selectedReadingOrder',
    'update:annotationSelectionMode',
    'update:showText',
  ],
}
</script>

<style lang="scss" scoped>
.annotation-toolbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .annotation-buttons-container {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: rgb(var(--v-theme-primary));
    border-radius: 4px;
    opacity: 0.8;

    .annotation-toolbox-button {
      height: 34px;

      &.is-selected {
        opacity: 1;
      }

      &.is-unselected {
        opacity: 0.4;
      }
    }
  }

  .annotation-helpers-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.reading-order-select {
  ::v-deep .custom-select {
    height: 34px !important;
  }
}
</style>
