<template>
  <div class="pa-4">
    <h3 class="text-h3 mb-0">
      {{ $t('workflows.description') }}
    </h3>
    <v-card
      class="mt-2 pa-2"
    >
      <QuillEditor
        ref="quillEditor"
        v-model:content="editorContent"
        class="editor-content"
        content-type="html"
        :read-only="disabled"
        :options="editorOptions"
      >
        <template
          v-if="!disabled"
          #toolbar
        >
          <div id="my-toolbar">
            <button class="ql-bold" />
            <button class="ql-italic" />
            <button class="ql-underline" />

            <button>
              <v-icon
                class="clickable mb-2"
                @click="isEditing = true"
              >
                fas fa-expand
              </v-icon>
            </button>
          </div>
        </template>
      </QuillEditor>
      <div class="d-flex justify-end pt-4">
        <v-btn
          color="primary"
          :disabled="disabled"
          @click="handleSummaryUpdate(editorContent)"
          rounded
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-card>

    <WorkflowSummaryDialog
      v-model="isEditing"
      :workflow="workflow"
      :editor-content="editorContent"
      @summary-updated="handleSummaryUpdate"
    />
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import WorkflowSummaryDialog from '@/components/extract/views/Workflows/WorkflowsEditor/WorkflowSummaryDialog.vue';


export default {
  name: 'WorkflowSummary',
  components: {
    WorkflowSummaryDialog,
    QuillEditor
  },
  data() {
    return {
      isEditing: false,
      editorContent: this.workflow.summary || '',
    };
  },

  computed: {
    editorOptions() {
      return {
        theme: 'snow',
        modules: {
          toolbar: this.disabled ? false : {
            container: '#my-toolbar'
          }
        }
      };
    }
  },

  watch: {
    disabled: {
      immediate: true,
      handler(newValue) {
        this.$nextTick(() => {
          if (this.$refs.quillEditor?.getQuill()) {
            this.$refs.quillEditor.getQuill().enable(!newValue);
          }
        });
      }
    }
  },

  methods: {
    handleSummaryUpdate(newSummary) {
      this.editorContent = newSummary;
      this.$emit('summary-updated', newSummary);
    }
  },

  props: {
    workflow: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['summary-updated'],
};
</script>

<style lang="scss" scoped>
::v-deep .editor-content {
  width: 100%;
  height: calc(77vh - 200px);
  overflow: visible;

  &.ql-disabled {
    opacity: 0.5;
    pointer-events: auto;
    .ql-editor {
      cursor: default;
    }
  }

  ol, ul {
    padding-left: 40px;
  }
}
::v-deep #my-toolbar {
  display: flex;
  width: 100%;
}
::v-deep #my-toolbar button:last-child {
  margin-left: auto;
}
</style>
