<template>
  <v-card
    elevation="0"
    class="category-container mb-7 pa-5"
    :style="{
      'background-color':
        categoryMap[classifiedPages.category_id] === 'Unknown' ?
          'rgb(224, 204, 184)	 !important' : 'rgb(221, 219, 219) !important'
    }"
    :class="{
      'mr-0': last,
      'mr-7': !last,
    }"
  >
    <ItemName
      v-if="classifiedPages && classifiedPages.pages"
      :key="classifiedPages.id"
      class="text-overline primary--text"
      style="z-index: 202; line-height: 1rem;"
      font-size="11px"
      :style="{maxWidth: `${classifiedPages.pages.length * 110}px`}"
      :item="{
        id: classifiedPages.id,
        name: categoryMap[classifiedPages.category_id],
      }"
      :clickable="false"
      :show-id="false"
    />
    <v-card-text class="d-flex flex-wrap justify-center align-center pa-0">
      <template
        v-for="(cluster, clusterIndex) in pageClusters"
        :key="clusterIndex"
      >
        <div
          :class="[
            'd-flex flex-wrap justify-center align-center',
            { 'pages-wrapper': pageClusters.length > 1 }
          ]"
        >
          <template
            v-for="(page, index) in cluster"
            :key="page.id"
          >
            <FilePage
              :page="page"
              :categories="categories"
              :category-map="categoryMap"
              :display-categories="displayCategories"
              :selected="selectedPage && selectedPage.id === page.id"
              @select="id => $emit('selectPage', id)"
              @refresh="$emit('refresh')"
            />
            <v-icon
              v-if="shouldShowBreakIcon(clusterIndex, index)"
              class="inline-middle top-gap cursor-pointer mx-2"
              color="primary"
              style="font-size: 20px; transform: rotate(90deg);"
              @click="handleBreakClick(getOriginalIndex(clusterIndex, index))"
            >
              {{ getBreakIcon(getOriginalIndex(clusterIndex, index)) }}
            </v-icon>
          </template>
        </div>
        <v-icon
          v-if="clusterIndex < pageClusters.length - 1"
          class="inline-middle top-gap cursor-pointer mx-2 mb-2"
          color="primary"
          style="font-size: 20px; transform: rotate(90deg);"
          @click="handleBreakClick(getClusterBreakIndex(clusterIndex))"
        >
          {{ getBreakIcon(getClusterBreakIndex(clusterIndex)) }}
        </v-icon>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import FilePage from '@/components/extract/elements/Workflows/PageCorrection/FilePage';
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'FileContainer',

  components: {
    FilePage,
    ItemName,
  },

  computed: {
    pageClusters() {
      if (!this.classifiedPages.pages) return [];

      const clusters = [[]];
      let currentCluster = 0;

      this.classifiedPages.pages.forEach((page, index) => {
        clusters[currentCluster].push(page);
        if (this.classifiedPages.breaks.includes(index)) {
          currentCluster++;
          clusters.push([]);
        }
      });

      return clusters;
    }
  },

  methods: {
    handleBreakClick(index) {
      const hasBreak = this.classifiedPages.breaks.includes(index);
      this.$emit(hasBreak ? 'removeBreak' : 'addBreak', index, this.currentClusterIndex);
    },

    getBreakIcon(index) {
      return this.classifiedPages.breaks.includes(index) ? 'fas fa-plus' : 'fas fa-divide';
    },

    shouldShowBreakIcon(clusterIndex, index) {
      const cluster = this.pageClusters[clusterIndex];
      return this.enableBreaks && index !== cluster.length - 1;
    },

    getOriginalIndex(clusterIndex, index) {
      let originalIndex = index;
      for (let i = 0; i < clusterIndex; i++) {
        originalIndex += this.pageClusters[i].length;
      }
      return originalIndex;
    },

    getClusterBreakIndex(clusterIndex) {
      let index = 0;
      for (let i = 0; i <= clusterIndex; i++) {
        index += this.pageClusters[i].length;
      }
      return index - 1;
    }
  },

  props: {
    classifiedPages: {
      type: Object,
      required: true,
    },
    currentClusterIndex: {
      type: Number,
      required: true,
    },
    last: {
      type: Boolean,
      default: false,
    },
    displayCategories: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    categoryMap: {
      type: Object,
      default: () => {},
    },
    selectedPage: {
      type: Object,
      default: () => {},
    },
    enableBreaks: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selectPage', 'refresh', 'removeBreak', 'addBreak'],

}
</script>
<style lang="scss" scoped>
  .category-container {
    padding-bottom: 10px !important;
  }

  .file-container__header {
    max-width: 150px;
    max-height: 20px;
  }

  .item-name-style {
    z-index: 202;
    line-height: 1rem;
    max-width: 110px;
    font-size: 11px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .pages-wrapper {
    border: 2px dashed white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
</style>