<template>
  <div class="dataset-options">
    <div
      v-for="processingParam, i in processingParameters"
      :key="i"
      class="radio-box right-gap-sm bottom-gap-sm"
      style="width: fit-content"
      :class="{ 'inline-top': type === 'create' }"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <v-tooltip right>
          <template #activator="{ props }">
            <v-icon
              class="right-gap-sm clickable left-gap-sm"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ $t(`docTypes.${processingParam}.message`) }}
        </v-tooltip>
        <small>
          {{ $t(`docTypes.${processingParam}.name`) }}
        </small>
      </div>
      <v-switch
        v-model="dataset[processingParam]"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        :disabled="disableOptions(processingParam)"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <div
      v-if="type === 'create'"
      class="right-gap-sm radio-box inline-top bottom-gap-sm"
      style="width: fit-content"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <small class="left-gap-sm">
          {{ $t("datasets.advanced") }}
        </small>
      </div>
      <v-switch
        v-model="dataset.advanced"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <v-select
      v-model="dataset.ocr_model"
      style="width: 370px !important; margin: 0px;"
      class="right-gap-sm inline-middle"
      variant="outlined"
      color="primary"
      density="compact"
      item-title="name"
      item-value="value"
      :class="{ 'inline-top': type === 'create' }"
      :items="ocrModelOpts"
      :label="$t('settings.ocr_model.title')"
      @update:model-value="updateOCRModel"
      hide-selected
    />
    <v-select
      v-model="dataset.custom_reading_order"
      style="width: 370px !important; margin: 0px;"
      class="right-gap-sm inline-middle"
      variant="outlined"
      color="primary"
      density="compact"
      item-title="name"
      item-value="value"
      :class="{ 'inline-top': type === 'create' }"
      :items="readingOrderOpts"
      :label="$t('docTypes.custom_reading_order.name')"
      :disabled="dataset.is_legacy_ro"
      @update:model-value="$emit('change', dataset);"
      hide-selected
    />
  </div>
</template>

<script>
import ui_mixin from '@/mixins/ui.js';
import { ConfigAPI } from '@/API/extract/ConfigAPI';

export default {
  name: 'DatasetOptions',

  mixins: [ui_mixin],

  data() {
    return ({
      orgConfig: {},
      processingParameters: [
        'force_ocr',
        'straighten_pages',
        'use_deskew',
        'use_tables',
        'detect_checkboxes',
      ],
    })
  },

  computed: {
    ocrModelOpts() {
      return [
        {name: this.$t('settings.ocr_model.default', { ocrModel: this.$t(`settings.ocr_model.${this.orgConfig.ocr_model?.toLowerCase()}`) }), value: null},
        {name: this.$t('settings.ocr_model.tesseract'), value: 'TESSERACT'},
        {name: this.$t('settings.ocr_model.doctr'), value: 'DOCTR'},
        {name: this.$t('settings.ocr_model.google'), value: 'GOOGLE'},
        {name: this.$t('settings.ocr_model.azure'), value: 'AZURE'},
      ];
    },

    readingOrderOpts() {
      return [
        {name: this.$t('docTypes.custom_reading_order.default'), value: null},
        {name: this.$t('docTypes.custom_reading_order.roma2'), value: 'roma2'},
        {name: this.$t('docTypes.custom_reading_order.roma3'), value: 'roma3'},
      ];
    }
  },

  async mounted() {
    this.orgConfig = await ConfigAPI.get();
  },

  methods: {
    disableOptions(option) {
      if (option === 'straighten_pages') {
        if (
          !this.dataset.force_ocr
          || !["GOOGLE", "AZURE", null].includes(this.dataset.ocr_model)
          || this.dataset.ocr_model === null && !["GOOGLE", "AZURE"].includes(this.orgConfig.ocr_model)
        ) {
          return true;
        }
      }
      if (option === 'use_deskew' && !this.dataset.force_ocr) {
        return true;
      }
      return false;
    },

    setOCRCompatibility() {
      if (this.dataset.force_ocr === false) {
        this.dataset.straighten_pages = false;
        this.dataset.use_deskew = false;
      } else if (
        !["GOOGLE", "AZURE", null].includes(this.dataset.ocr_model)
        || this.dataset.ocr_model === null && !["GOOGLE", "AZURE"].includes(this.orgConfig.ocr_model)
      ) {
        this.dataset.straighten_pages = false;
      }

    },

    updateOCRModel() {
      this.setOCRCompatibility();
      this.$emit('change', this.dataset);
    }
  },

  props: {
    dataset: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      required: true,
    }
  },

  emits: ['change'],
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-input {
    .v-label {
      top: 50% !important;
      transform: translateY(-50%) !important;
      padding-left: 0 !important;
    }
  }
</style>