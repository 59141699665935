<template>
  <div class="group-value-highlights">
    <div
      v-for="group in valueGroups"
      :key="group.index"
    >
      <div
        v-for="value in group.values"
        :key="value.id"
      >
        <v-tooltip
          v-if="hasLocationData(value)"
          :value="isHighlighted(value)"
          bottom
        >
          <template #activator="{ props }">
            <div
              :ref="`value${value.id}`"
              class="highlight-frame"
              :style="{
                left: `${getHighlightX(value)}vh`,
                top: `${getHighlightY(value)}vh`,
                width: `${getWidth(value)}vh`,
                height: `${getHeight(value)}vh`,
                'border-color': getColor(group.index),
                'background-color': getHighlightColor(value, group.index),
              }"
              v-bind="props"
              @mouseover="innerHighlighted = value"
              @mouseout="innerHighlighted = null"
            />
          </template>
          {{ getValue(value) }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupValueHighlights",

  data() {
    return ({
      innerHighlighted: null,
    })
  },

  methods: {
    getHighlightColor(value, index) {
      if (this.isHighlighted(value) || this.subgroupHighlighted(value)) {
        return `${this.getColor(index)}60`;
      }
      return '';
    },

    isHighlighted(value) {
      const highlighted = this.innerHighlighted ? this.innerHighlighted : this.highlighted;
      if (highlighted && Object.keys(highlighted).length > 0) {
        return this.highlighted.id === value.id;
      }
      return false;
    },

    subgroupHighlighted(value) {
      const highlighted = this.innerHighlighted ? this.innerHighlighted : this.highlighted;
      if (highlighted && Object.keys(highlighted).length > 0) {
        return highlighted.subgroup_index === value.subgroup_index;
      }
      return false;
    },

    getColor(index) {
      return this.$store.getters.annotationColors[index % 9];
    },

    hasLocationData(dp) {
      return (
        dp &&
        (
          (dp.valid_location && Object.prototype.hasOwnProperty.call(dp.valid_location , 'x_max') && this.area(dp.valid_location) > 0) ||
          (dp.location && Object.prototype.hasOwnProperty.call(dp.location , 'x_max') && this.area(dp.location) > 0)
        )
      );
    },

    area(location) {
      return (location.x_max - location.x_min) * (location.y_max - location.y_min);
    },

    getHighlightX(value) {
      if (value && value.status === 'invalid') {
        return value.valid_location.x_min * this.pageWidth;
      }
      if (value.location) {
        return value.location.x_min * this.pageWidth;
      }
    },

    getHighlightY(value) {
      if (value && value.status === 'invalid') {
        return value.valid_location.y_min * this.pageHeight;
      }
      if (value.location) {
        return value.location.y_min * this.pageHeight;
      }
    },

    getWidth(value) {
      if (value && value.status === 'invalid') {
        return (value.valid_location.x_max - value.valid_location.x_min) * this.pageWidth;
      }
      return (value.location.x_max - value.location.x_min) * this.pageWidth;
    },

    getHeight(value) {
      if (value && value.status === 'invalid') {
        return (value.valid_location.y_max - value.valid_location.y_min) * this.pageHeight;
      }
      return (value.location.y_max - value.location.y_min) * this.pageHeight;
    },

    getValue(value) {
      if (value.status !== 'invalid') {
        if (value.value) {
          return value.value;
        }
        return '';
      }
      return value.valid_value;
    },
  },

  props: {
    valueGroups: {
      type: Array,
      default: () => [],
    },
    highlighted: {
      type: Object,
      default: () => {},
    },
    verified: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
    originalWidth: {
      type: Number,
      required: true,
    },
    originalHeight: {
      type: Number,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.highlight {
  opacity: 0.4;
  position: absolute;
  cursor: pointer;
}

.highlight-frame {
  position: absolute;
  cursor: pointer;
  border-bottom: 3px solid;
}
</style>
