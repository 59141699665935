
import { http } from '@/plugins/axios';

const BASE_PATH = 'auth/api/v1/';

export class PasswordAPI {
  static passwordRecoveryRequest(email) {
    return http
      .post(`${BASE_PATH}users/password/recovery/${email}/`, {
        params: { noAuth: true },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static passwordRecoveryConfirm(token, newPassword) {
    return http
      .post(`${BASE_PATH}users/password/recovery/?token=${token}`, {
        new_password: newPassword,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static changePassword(body) {
    return http
      .put(`${BASE_PATH}users/password`, body)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static passwordReset(id) {
    return http
      .post(`${BASE_PATH}users/${id}/password_reset/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
