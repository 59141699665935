<template>
  <div
    v-if="classificationAgent"
    class="files-table page-padding py-7"
  >
    <BreadcrumbComponent />
    <HeaderName
      :item="classificationAgent"
      :editable="false"
    />
    <TableActions
      type="correctionDocs"
      :number-of-selected="selected.length"
      :edit-condition="false"
      :delete-condition="true"
      @delete-click="deleteDialog = true"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="documents.length"
      :total="totalDocuments"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton
            v-model="sortDesc"
            class="mr-1"
            style="margin-top: -5px"
          />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          {{ $t('datatable.header.docName') }}
        </v-col>
        <v-col cols="2">
          {{ $t('type') }}
        </v-col>
        <v-col cols="2">
          {{ $t('datatable.header.addDate') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in documents"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="ml-6"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col
              cols="6"
              @click="goToDocument(item)"
            >
              <ItemName
                :key="item.id"
                style="z-index: 202"
                type="file"
                name-field="filename"
                :item="item"
              />
            </v-col>
            <v-col cols="2">
              {{ item.is_bundle ? $t('workflows.documents.bundle') : $t('workflows.documents.document') }}
            </v-col>
            <v-col cols="2">
              <small class="gray--text">
                {{ formatDate(item.created_on) }}
              </small>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('datatable.delete_file')"
      :message="$t('datatable.deleteConfirmation')"
      @confirm="deleteDocuments"
    />
    <ProgressDialog
      v-model="progressDialog"
      :all-delete="allDelete"
      :current-delete="currentDelete"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import ItemName from '@/components/common/elements/General/ItemName.vue';
import ConfirmDialog from '@/components/common/elements/Tables/ConfirmDialog.vue';
import ProgressDialog from '@/components/common/elements/Tables/ProgressDialog.vue';
import SortButton from '@/components/common/elements/Tables/SortButton.vue';
import TableActions from '@/components/common/elements/Tables/TableActions.vue';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter.vue';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { formatDate } from '@/utils/classes/Formatting';
import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';
import { ClassifyDocumentsAPI } from '@/API/classify/ClassifyDocumentsAPI';
import BreadcrumbComponent from '@/components/common/elements/Navigation/BreadcrumbComponent.vue';
import HeaderName from '@/components/common/elements/General/HeaderName.vue';


export default {
  name: 'ClassificationAgentDocumentsTable',

  components: {
    ConfirmDialog,
    ItemName,
    ProgressDialog,
    SortButton,
    TableActions,
    TableWithFooter,
    BreadcrumbComponent,
    HeaderName,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      allDelete: 0,
      currentDelete: 0,
      allSelected: false,
      documents: [],
      loading: false,
      deleteDialog: false,
      progressDialog: false,
      sortDesc: true,
      trimmedFilter: '',
      itemsPerPage,
      currentPage,
      totalDocuments: 0,
      classificationAgent: null,
    };
  },

  computed: {
    selected() {
      if (this.documents.length > 0) {
        return this.documents.filter(item => item.selected);
      }
      return [];
    },
  },

  watch: {
    trimmedFilter: _.debounce(
      async function() {
        this.currentPage = 1;
        await this.getDocuments(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      }, 300
    ),

    currentPage() {
      this.getDocuments(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDocuments(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
    },

    sortDesc() {
      this.getDocuments(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
    },
  },

  async created() {
    await Promise.all([
      this.getClassificationAgent(),
      this.getDocuments(),
    ]);
    this.setBreadcrumb();
  },

  methods: {
    formatDate,
    async getDocuments(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage,
      sortDesc = true, filter = '')
    {
      try {
        this.loading = true;
        const response = await ClassificationAgentsAPI.fetchDocuments({
          classificationAgentId: this.classificationAgentId,
          offset,
          limit,
          sortDesc,
          filename: filter,
          hitlRequired: true,
        });
        this.documents = response.data;
        this.totalDocuments = response.headers['x-total-count'];
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    goToDocument(document) {
      this.$router.push({
        name: 'PageCorrection',
        params: {
          id: document.id,
        },
      });
    },

    handleSelect() {
      this.allSelected = this.documents.every((document) => document.selected);
    },

    toggleSelectAll() {
      this.documents.forEach((document) => {
        document.selected = this.allSelected;
      });
    },

    async deleteDocuments() {
      const total = this.selected.length;
      this.deleteDialog = false;
      this.allDelete = total;
      this.progressDialog = true;
      for (let i = 0; i < total; i++) {
        this.currentDelete = i + 1;
        try {
          await ClassifyDocumentsAPI.delete(this.selected[i].id);
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
      }
      this.progressDialog = false;
      await this.getDocuments(this.currentPage - 1, this.itemsPerPage, this.sortDesc, this.trimmedFilter);
      const lastPage = Math.max(1, Math.ceil(this.totalDocuments / this.itemsPerPage));
      this.currentPage = Math.min(this.currentPage, lastPage);
      this.allSelected = false;
      this.$store.commit('setSuccessMessage', `${this.$t('docTypes.files.deleted')} (${total}).`);
      this.$store.commit('setSuccessSnackbar', true);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.documents = this.documents.map(b => {
        b.selected = false;
        return b;
      });
    },

    async getClassificationAgent() {
      const response = await ClassificationAgentsAPI.fetchClassificationAgent({ id: this.classificationAgentId });
      this.classificationAgent = response;
    },

    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
          { title: this.$t('menu.production') },
          {
            title: this.$t('menu.corrections.title'),
            href: {
              name: 'Corrections',
              query: {
                activeTab: 'classificationAgentsDocumentsReview',
              },
            },
          },
          { title: this.classificationAgent.name },
        ]);
    },
  },

  props: {
    classificationAgentId: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
