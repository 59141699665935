<template>
  <div class="page-padding py-7">
    <BreadcrumbComponent />
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
      @update:model-value="onTabChange"
    />
    <div class="d-flex align-center justify-space-between w-100">
      <div class="d-flex mt-4">
        <v-select
          v-model="timeGranularity"
          class="inline-middle mt-0 right-gap-sm"
          variant="outlined"
          color="primary"
          density="compact"
          style="max-width: 150px;"
          :items="timeGranularities"
        />
        <EntitySelect
          v-if="activeTab === 'classify'"
          name-field="name"
          value-field="id"
          class="inline-middle mt-0 right-gap-sm"
          style="width: 250px;"
          :placeholder="$t('classificationAgents.select_classification_model')"
          :get-items-fn="getClassificationModels"
          @selected-changed="(id) => selectedClassificationModelId = id"
        />
        <EntitySelect
          :key="activeTab"
          name-field="name"
          value-field="id"
          class="inline-middle mt-0 right-gap-sm"
          style="width: 250px;"
          :placeholder="activeTab === 'classify' ? $t('classificationAgents.classification_agent') : $t('docTypes.doc_type')"
          :get-items-fn="activeTab === 'classify' ? getClassificationAgents : getExtractionAgents"
          @selected-changed="(id) => selectedAgentId = id"
        />
        <EntitySelect
          name-field="name"
          value-field="id"
          class="inline-middle mt-0 right-gap-sm"
          style="width: 250px;"
          :placeholder="$t('home.workflows')"
          :get-items-fn="getWorkflows"
          @selected-changed="(id) => selectedWorkflowId = id"
        />
      </div>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <v-btn
            class="mt-4"
            color="primary"
            style="height: 36px"
            v-bind="props"
            @click="downloadMetrics"
            rounded
          >
            <v-icon start>
              fas fa-download
            </v-icon>
            {{ $t('traces.download') }}
          </v-btn>
        </template>
        {{ $t("traces.download_detail") }}
      </v-tooltip>
    </div>
    <div class="text-center mt-2">
      <v-btn
        size="x-large"
        color="primary"
        variant="text"
        @click="dateRangeDialog = true"
        rounded
      >
        {{ formatDate(fromDate, userLanguage, false) }} - {{ formatDate(toDate, userLanguage, false) }}
      </v-btn>
    </div>
    <div class="d-flex flex-wrap justify-space-between w-100">
      <v-card
        v-for="(metric, index) in activeTab === 'classify' ? classifyMetrics : extractMetrics"
        :key="index"
        class="chart-card pb-1 mx-2 mb-6"
        style="width: calc(50% - 16px);"
      >
        <h3 class="text-h3 text-center">
          {{ metric.title }} ({{ metric.total }})
        </h3>
        <div
          class="d-flex justify-space-between align-center"
          style="height: 320px;"
        >
          <v-btn
            icon="fas fa-chevron-left"
            variant="text"
            density="comfortable"
            :disabled="isPreviousDisabled(index)"
            @click="handlePreviousData(index)"
          />
          <div class="w-full">
            <VueApexCharts
              width="100%"
              height="300px"
              type="bar"
              :options="getChartOptions(index)"
              :series="[metricsChartSeries[index]]"
            />
          </div>
          <v-btn
            icon="fas fa-chevron-right"
            variant="text"
            density="comfortable"
            :disabled="isNextDisabled(index)"
            @click="handleNextData(index)"
          />
        </div>
      </v-card>
    </div>
    <DateRangeSelectDialog
      v-model="dateRangeDialog"
      :from-date="new Date(fromDate)"
      :to-date="new Date(toDate)"
      @confirm="onDateRangeSelected"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { formatDate } from '@/utils/classes/Formatting';
import VueApexCharts from 'vue3-apexcharts';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import EntitySelect from '@/components/common/elements/Forms/EntitySelect.vue';
import DateRangeSelectDialog from '@/components/extract/elements/Stats/DateRangeSelectDialog';
import PageTabs from '@/components/common/elements/General/PageTabs';

import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import { MetricsAPI } from '@/API/authenticator/MetricsAPI';

export default {
  name: 'MetricsView',

  components: {
    PageTabs,
    EntitySelect,
    BreadcrumbComponent,
    DateRangeSelectDialog,
    VueApexCharts,
  },

  constants: {
    BASE_CHART_OPTIONS: {
      chart: {
        height: '100%',
        toolbar: {
          show: false,
        },
        type: 'bar',
        stacked: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
        }
      },
      colors: [
        '#6C5DD3',
        '#514A9D',
        '#8F88FF',
        '#C4B5FD',
        '#FF8A48',
      ],
      yaxis: {
        max: (max) => max + max * 0.1,
        forceNiceScale: true,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
    }
  },

  data() {
    return {
      sortDesc: true,
      timeGranularity: "month",
      timeGranularities: [
        { title: this.$t('metrics.day'), value: 'day', format: 'DD/MM/YYYY' },
        { title: this.$t('metrics.week'), value: 'week', format: 'DD/MM/YYYY' },
        { title: this.$t('metrics.month'), value: 'month', format: 'MM/YYYY' },
      ],
      selectedWorkflowId: null,
      selectedClassificationModelId: null,
      selectedAgentId: null,
      dateRangeDialog: false,
      fromDate: moment().subtract(1, 'year'),
      toDate: moment(),
      activeTab: 'classify',
      tabItems: [
        { title: this.$t('classification'), name: 'classify' },
        { title: this.$t('extraction'), name: 'extract' },
      ],
      classifyMetrics: [{
        title: this.$t('metrics.document_classification'),
        offset: 0,
        metrics: [],
        total: 0,
      }, {
        title: this.$t('metrics.bundle_classification'),
        offset: 0,
        metrics: [],
        total: 0,
      }, {
        title: this.$t('metrics.document_discarded'),
        offset: 0,
        metrics: [],
        total: 0,
      }, {
        title: this.$t('metrics.document_corrected'),
        offset: 0,
        metrics: [],
        total: 0,
      }, {
        title: this.$t('metrics.bundle_discarded'),
        offset: 0,
        metrics: [],
        total: 0,
      }, {
        title: this.$t('metrics.bundle_corrected'),
        offset: 0,
        metrics: [],
        total: 0,
      }],
      extractMetrics: [{
        title: this.$t('traces.doc_count'),
        offset: 0,
        metrics: [],
        total: 0,
      },
      {
        title: this.$t('traces.page_count'),
        offset: 0,
        metrics: [],
        total: 0,
      },
      {
        title: this.$t('traces.useful_page_count'),
        offset: 0,
        metrics: [],
        total: 0,
      }],
    };
  },

  computed: {
    userLanguage() {
      return this.$store.getters?.loggedInUser?.language;
    },

    dateRange() {
      return `${this.fromDate.format('YYYY-MM-DD')} - ${this.toDate.format('YYYY-MM-DD')}`;
    },

    metricsChartSeries() {
      if (this.activeTab === 'classify') {
        return this.classifyMetrics.map(metric => ({
          name: metric.title,
          data: metric.metrics.map(m => m.total_documents).reverse()
        }));
      } else {
        return this.extractMetrics.map(metric => ({
          name: metric.title,
          data: metric.metrics.map(m => m.total_documents).reverse()
        }));
      }
    },
  },

  watch: {
    activeTab: {
      handler: 'onTabChange',
      immediate: true
    },
    timeGranularity: {
      handler: 'fetchMetrics',
      immediate: true
    },
    selectedClassificationModelId: {
      handler: 'fetchMetrics',
      immediate: true
    },
    selectedAgentId: {
      handler: 'fetchMetrics',
      immediate: true
    },
    selectedWorkflowId: {
      handler: 'fetchMetrics',
      immediate: true
    },
  },

  created() {
    this.setBreadcrumb();
    this.fetchMetrics();
  },

  methods: {
    formatDate,

    handleDateRangePickerClicked() {
      this.dateRangeDialog = true;
    },

    async downloadMetrics() {
      const params = {
        time_granularity: this.timeGranularity,
        timestamp_from: this.fromDate.format('YYYY-MM-DD'),
        timestamp_to: this.toDate.format('YYYY-MM-DD'),
      };
      let filename = 'metrics';
      try {
        const response = await MetricsAPI.download(params);
        const url = window.URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel',
        }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      }
    },

    handlePreviousData(index) {
      if (this.activeTab === 'classify') {
        this.classifyMetrics[index].offset += 1;
      } else {
        this.extractMetrics[index].offset += 1;
      }
      this.fetchMetrics();
    },

    handleNextData(index) {
      if (this.activeTab === 'classify') {
        this.classifyMetrics[index].offset -= 1;
      } else {
        this.extractMetrics[index].offset -= 1;
      }
      this.fetchMetrics();
    },

    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
          { title: this.$t('menu.monitoring') },
          { title: this.$t('menu.metrics') },
        ]
      );
    },

    onDateRangeSelected(fromDate, toDate) {
      this.toDate = moment(toDate);
      this.fromDate = moment(fromDate);
      this.fetchMetrics();
    },

    async getClassificationModels({filter = '', offset = 0, limit = 5}) {
      const response = await ClassifyModelAPI.getClassificationModels({name: filter, offset: offset, limit: limit});
      return {
        items: response.data,
        total: parseInt(response.headers['x-total-count'], 10),
      };
    },

    async getClassificationAgents({filter = '', offset = 0, limit = 5}) {
      const response = await ClassificationAgentsAPI.fetchAll({name: filter, offset: offset, limit: limit});
      return {
        items: response.data,
        total: parseInt(response.headers['x-total-count'], 10),
      };
    },

    async getExtractionAgents({filter = '', offset = 0, limit = 5}) {
      const response = await DocTypeAPI.get(limit, offset, filter, null, null, null, null, this.sortDesc);
      return {
        items: response.data,
        total: parseInt(response.headers['x-total-count'], 10),
      };
    },

    async getWorkflows({filter = '', offset = 0, limit = 5}) {
      const [data, total] = await WorkflowAPI.getByUUID(null, filter, this.sortDesc, offset, limit);
      return {
        items: data,
        total: total,
      };
    },

    fetchMetrics() {
      const classifyActions = ['classification', 'bundle_classification', 'document_discarded', 'document_corrected', 'bundle_discarded', 'bundle_corrected'];
      const extractActions = ['all_pages', 'useful_pages', 'document_extraction'];
      const actions = this.activeTab === 'classify' ? classifyActions : extractActions;
      actions.forEach(async (action, index) => {
        try {
          const response = await MetricsAPI.get({
            product: this.activeTab,
            action,
            time_granularity: this.timeGranularity,
            timestamp_from: this.fromDate.format('YYYY-MM-DD'),
            timestamp_to: this.toDate.format('YYYY-MM-DD'),
            model_id: this.activeTab === 'classify' ? this.selectedClassificationModelId : null,
            agent_id: this.selectedAgentId,
            workflow_id: this.selectedWorkflowId,
            offset: this.activeTab === 'classify' ? this.classifyMetrics[index].offset : this.extractMetrics[index].offset,
          });
          if (this.activeTab === 'classify') {
            this.classifyMetrics[index].metrics = response.metrics;
            this.classifyMetrics[index].total = response.total;
          } else {
            this.extractMetrics[index].metrics = response.metrics;
            this.extractMetrics[index].total = response.total;
          }
        } catch (error) {
          if (this.activeTab === 'classify') {
            this.classifyMetrics[index].metrics = [];
          } else {
            this.extractMetrics[index].metrics = [];
          }
        }
      });
    },

    onTabChange() {
      this.selectedClassificationModelId = null;
      this.selectedClassificationAgentId = null;
      this.selectedWorkflowId = null;
      this.classificationAgents = [];
      this.fetchMetrics();
    },

    isNextDisabled(index) {
      return this.activeTab === 'classify' ? this.classifyMetrics[index].offset <= 0 : this.extractMetrics[index].offset <= 0;
    },

    isPreviousDisabled(index) {
      return this.activeTab === 'classify' ? this.classifyMetrics[index].offset >= this.classifyMetrics[index].metrics.length - 6 : this.extractMetrics[index].offset >= this.extractMetrics[index].metrics.length - 6;
    },

    getChartOptions(index) {
      const metrics = this.activeTab === 'classify' ?
        this.classifyMetrics[index].metrics :
        this.extractMetrics[index].metrics;

      const categories = metrics.map((metric) => moment(metric.timestamp)
        .format(this.timeGranularities
          .find((granularity) => granularity.value === this.timeGranularity)
          .format))
        .reverse();

      return {
        ...this.$options.constants.BASE_CHART_OPTIONS,
        xaxis: {
          categories,
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: '12px'
            }
          }
        }
      };
    },
  },
}
</script>

<style lang="scss" scoped>
  .warning-message {
    padding: 16px;
    width: 650px;
    color: rgb(var(--v-theme-primary));
    border: solid 1px rgb(var(--v-theme-primary));
    border-radius: 10px;
    background-color: rgb(var(--v-theme-primary-lighten2));
  }
</style>
