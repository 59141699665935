export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

export function datediff(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export function getRemovedInDays(removalDate, deleteRetentionDays) {
  const today = new Date();
  const retentionPeriodEndDate = addDays(new Date(removalDate), deleteRetentionDays);
  const daysUntilRetentionEnds = datediff(today, retentionPeriodEndDate);
  return Math.max(0, daysUntilRetentionEnds);
}
