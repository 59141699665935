<template>
  <div class="suite-login-settings top-gap d-flex settings-body">
    <div class="settings-left">
      <h4>{{ $t('settings.login') }}</h4>
      <div style="font-size: 0.8rem">
        {{ $t('settings.login_explanation') }}
      </div>
    </div>
    <div class="settings-right">
      <div style="margin-bottom: -30px; margin-top: -10px;">
        <v-switch
          v-model="useEmailPasswdAuth"
          class="inline-middle"
          color="primary"
          @change="updateLoginSettings"
          inset
        />
        <div class="left-gap-sm inline-middle bottom-gap">
          <h4>{{ $t('settings.use_email_passwd_auth.title') }}</h4>
        </div>
      </div>
      <div
        v-for="provider in paginatedOidcProviders"
        :key="provider.id"
        style="margin-top: -30px"
        @mouseover="hoveredOidc = provider.id"
        @mouseleave="hoveredOidc = null"
      >
        <v-switch
          v-model="provider.is_active"
          style="margin-top: 1px"
          class="inline-middle"
          color="primary"
          :disabled="!provider.is_active && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders"
          @change="toggleCustomProvider(provider)"
          inset
        />
        <div
          class="left-gap-sm inline-middle"
          style="margin-top: -22px"
        >
          <h4
            class="inline-middle ellipsis"
            style="max-width: 450px"
            :style="{opacity: (!provider.is_active && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders) ? 0.5 : 1 }"
          >
            {{ provider.provider_name }}
          </h4>
          <v-tooltip
            v-if="hoveredOidc === provider.id"
            bottom
          >
            <template #activator="{ props }">
              <v-icon
                class="left-gap-sm clickable"
                size="16"
                v-bind="props"
                @click="editedOidcProvider = provider; editOidcDialog = true;"
              >
                fas fa-pen
              </v-icon>
            </template>
            {{ $t('settings.configure_provider') }}
          </v-tooltip>
          <v-tooltip
            v-if="hoveredOidc === provider.id"
            bottom
          >
            <template #activator="{ props }">
              <v-icon
                class="left-gap-sm clickable"
                size="16"
                v-bind="props"
                @click="selectedOidc = provider.id; deleteOidcDialog = true"
              >
                fas fa-times
              </v-icon>
            </template>
            {{ $t('delete') }}
          </v-tooltip>
          <div
            class="d-flex"
            style="font-size: 0.8rem"
          >
            <div
              class="ellipsis"
              style="max-width: 500px"
              :style="{opacity: (!provider.is_active && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders) ? 0.5 : 1 }"
            >
              {{ provider.callback }}
            </div>
            <v-tooltip
              v-if="hoveredOidc === provider.id"
              bottom
            >
              <template #activator="{ props }">
                <v-icon
                  class="left-gap-sm clickable"
                  style="margin-top: -4px"
                  size="16"
                  v-bind="props"
                  @click="copyToClipboard(provider.callback)"
                >
                  fas fa-clipboard
                </v-icon>
              </template>
              {{ $t('settings.copy_callback') }}
            </v-tooltip>
          </div>
        </div>
      </div>
      <div
        v-if="paginatedOidcProviders.length > maxOidcProviders && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders"
        class="info-box bottom-gap"
      >
        <small>
          <div
            class="inline-middle"
            style="width: 30px"
          >
            <v-icon
              class="info-icon"
              size="16"
            >
              fas fa-info-circle
            </v-icon>
          </div>
          <div
            class="inline-middle"
            style="width: calc(100% - 30px)"
          >
            {{ $t('settings.max_oidc', {number: maxOidcProviders }) }}
          </div>
        </small>
      </div>
      <v-btn
        color="primary"
        class="bottom-gap-sm top-gap-sm"
        style="box-shadow: none"
        variant="outlined"
        @click="editedOidcProvider = null; createOidcDialog = true"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t('settings.add_custom_login_provider') }}
      </v-btn>
      <div class="label text-field-label top-gap-sm">
        {{ $t('custom_login_url') }}
      </div>
      <div
        class="d-flex align-center"
        style="margin-top: -15px"
      >
        <h4 class="ma-0">
          {{ loginUrl }}
        </h4>
        <v-text-field
          v-model="customLoginSlug"
          class="callback inline-middle left-gap-sm"
          placeholder="organization-name"
          aria-placeholder="organization-name"
          variant="outlined"
          color="primary"
          density="compact"
        />
        <v-btn
          color="primary"
          class="ml-2"
          @click="updateLoginSettings"
          rounded
        >
          {{ $t('save') }}
        </v-btn>
        <v-tooltip bottom>
          <template #activator="{ props }">
            <v-icon
              color="primary"
              class="ml-2"
              v-bind="props"
              @click="updateLoginSettings(); copyToClipboard(loginUrl + customLoginSlug)"
            >
              fas fa-clipboard
            </v-icon>
          </template>
          <span>
            {{ $t('settings.copy_login_url') }}
          </span>
        </v-tooltip>
      </div>
    </div>
    <ConfirmDialog
      v-model="deleteOidcDialog"
      :title="$t('oidc_providers.delete')"
      :message="$t('oidc_providers.delete_confirmation.message')"
      @confirm="deleteOidcProviders"
    />
    <CreateOidcProvider
      v-model="editOidcDialog"
      :edited-oidc-provider="editedOidcProvider"
      @close="editOidcDialog = false"
      @refresh="getOidcProviders"
    />
    <CreateOidcProvider
      v-model="createOidcDialog"
      @close="createOidcDialog = false;"
      @refresh="getOidcProviders"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';
import { displaySnackbarSuccess, displaySnackbarError } from '@/utils/SnackbarUtils';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import CreateOidcProvider from '@/components/extract/elements/Settings/CreateOidcProvider';

export default {
  name: 'SuiteLoginSettings',

  components: {
    ConfirmDialog,
    CreateOidcProvider,
  },

  data() {
    return {
      useEmailPasswdAuth: false,
      paginatedOidcProviders: [],
      totalOidcProviders: 0,
      customLoginSlug: "",
      loginUrl: window.location.protocol + "//" + window.location.host + "/suite/login/org/",
      maxOidcProviders: 1,
      hoveredOidc: null,
      editOidcDialog: false,
      createOidcDialog: false,
      editedOidcProvider: {},
      deleteOidcDialog: false,
      selectedOidc: null,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    }
  },

  watch: {
    customLoginSlug(newVal) {
      this.customLoginSlug = newVal.replace(/[^a-z0-9-]/g, '').toLowerCase();
    }
  },

  mounted() {
    this.getOidcProviders();
    this.getOrganization();
  },

  methods: {
    async getOrganization() {
      const org = await OrgsAPI.getOneOrg(this.user.org_id);
      this.useEmailPasswdAuth = org.use_email_passwd_auth;
      this.customLoginSlug = org.custom_login_slug ? org.custom_login_slug : "";
    },

    copyToClipboard (url, message=this.$t('oidc_providers.callback_url_copied')) {
      navigator.clipboard.writeText(url);
      displaySnackbarSuccess(message);
    },

    async deleteOidcProviders() {
      try {
        await http.delete(`auth/api/v1/openid_providers/${this.selectedOidc}`);
        this.getOidcProviders();
        displaySnackbarSuccess(this.$t('oidc_providers.successDelete'));
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.deleteOidcDialog = false;
      }
    },

    toggleCustomProvider(provider) {
      setTimeout(async () => {
        try {
          await http.put(`auth/api/v1/openid_providers/${provider.id}/`, {'is_active': provider.is_active});
          displaySnackbarSuccess(this.$t('settings.update_success'));
        } catch (error) {
          displaySnackbarError(this.$t('oidc_providers.maxActiveAllowedReached'));
        }
      }, 50);
    },

    async updateLoginSettings() {
      try {
        await OrgsAPI.editOrg(this.user.org_id, {
          "use_email_passwd_auth": this.useEmailPasswdAuth,
          "custom_login_slug": this.customLoginSlug
        });
        displaySnackbarSuccess(this.$t('settings.update_success'));
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getOidcProviders(offset = 0, limit = this.itemsPerPage) {
      try {
        const params = {
          limit,
          offset,
        };
        const response = await http.get(
          `auth/api/v1/openid_providers/`,
          { params }
        );
        this.paginatedOidcProviders = response.data;
        this.totalOidcProviders = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },
};
</script>
