<template>
  <div class="changePassword">
    <v-row justify="center">
      <v-col
        :md="5"
        :lg="4"
      >
        <v-card style="padding: 30px">
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="validate()"
          >
            <h2 class="dialog-title mb-3">
              {{ $t('forms.modify_password') }}
            </h2>
            <div>
              <div
                v-if="checkCurrentPassword"
                style="font-size: 0.8rem; margin-bottom: -16px"
              >
                {{ $t('forms.currentPassword') }}
              </div>
              <v-text-field
                v-if="checkCurrentPassword"
                id="current"
                v-model="form.current_password"
                class="changePassword__input"
                variant="outlined"
                color="primary"
                density="compact"
                :rules="currentPasswordRules"
                :type="currentPasswordVisible ? 'text' : 'password'"
                :append-icon="
                  currentPasswordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'
                "
                @click:append="
                  () => (currentPasswordVisible = !currentPasswordVisible)
                "
                placeholder
                required
              />
              <div style="font-size: 0.8rem; margin-bottom: -16px">
                {{ $t('forms.newPassword') }}
              </div>
              <v-text-field
                id="new"
                v-model="form.new_password"
                class="changePassword__input"
                variant="outlined"
                color="primary"
                density="compact"
                :rules="newPasswordRules"
                :type="newPasswordVisible ? 'text' : 'password'"
                :append-icon="newPasswordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click:append="() => (newPasswordVisible = !newPasswordVisible)"
                placeholder
                required
              />
            </div>
            <p class="mt-3 text-body-2">
              {{ $t('forms.password_criteria') }}
            </p>
            <div class="d-flex mt-2 text-body-2">
              <div class="flex-grow-1">
                <div
                  class="changePassword__criteria"
                  :class="{
                    'changePassword__criteria--check': !errors.find(
                      element => element === 'min_length'
                    ),
                  }"
                >
                  <div class="changePassword__criteriaIcon">
                    <v-icon size="14px">
                      {{
                        !errors.find(e => e === 'min_length')
                          ? 'fas fa-check'
                          : 'fas fa-info'
                      }}
                    </v-icon>
                  </div>
                  <p>{{ min_length }} {{ $t('forms.characters') }}</p>
                </div>
                <div
                  v-if="upper"
                  class="changePassword__criteria"
                  :class="{
                    'changePassword__criteria--check':
                      upper & !errors.find(element => element === 'upper'),
                  }"
                >
                  <div class="changePassword__criteriaIcon">
                    <v-icon size="14px">
                      {{
                        upper & !errors.find(e => e === 'upper') ||
                          !upper
                          ? 'fas fa-check'
                          : 'fas fa-info'
                      }}
                    </v-icon>
                  </div>
                  <p>1 {{ $t('forms.upper') }}</p>
                </div>
                <div
                  v-if="digit"
                  class="changePassword__criteria"
                  :class="{
                    'changePassword__criteria--check':
                      digit &&
                      !errors.find(element => element === 'digit'),
                  }"
                >
                  <div class="changePassword__criteriaIcon">
                    <v-icon size="14px">
                      {{
                        (digit &&
                          !errors.find(e => e === 'digit')) ||
                          !digit
                          ? 'fas fa-check'
                          : 'fas fa-info'
                      }}
                    </v-icon>
                  </div>
                  <p>1 {{ $t('forms.number') }}</p>
                </div>
              </div>
              <div class="flex-grow-1">
                <div
                  v-if="lower"
                  class="changePassword__criteria"
                  :class="{
                    'changePassword__criteria--check':
                      lower &&
                      !errors.find(element => element === 'lower'),
                  }"
                >
                  <div class="changePassword__criteriaIcon">
                    <v-icon size="14px">
                      {{
                        (lower &&
                          !errors.find(e => e === 'lower')) ||
                          !lower
                          ? 'fas fa-check'
                          : 'fas fa-info'
                      }}
                    </v-icon>
                  </div>
                  <p>1 {{ $t('forms.lower') }}</p>
                </div>
                <div
                  v-if="special"
                  class="changePassword__criteria"
                  :class="{
                    'changePassword__criteria--check':
                      special &&
                      !errors.find(element => element === 'special'),
                  }"
                >
                  <div class="changePassword__criteriaIcon">
                    <v-icon size="14px">
                      {{
                        (special &&
                          !errors.find(
                            e => e === 'special'
                          )) ||
                          !special
                          ? 'fas fa-check'
                          : 'fas fa-info'
                      }}
                    </v-icon>
                  </div>
                  <p>1 {{ $t('forms.special_character') }}</p>
                </div>
              </div>
            </div>
            <div
              class="changePassword__criteria text-body-2"
              :class="{
                'changePassword__criteria--check': !errors.find(
                  element => element === 'similarity'
                ),
              }"
            >
              <div class="changePassword__criteriaIcon">
                <v-icon size="14px">
                  {{
                    !errors.find(e => e === 'similarity')
                      ? 'fas fa-check'
                      : 'fas fa-info'
                  }}
                </v-icon>
              </div>
              <p>{{ $t('forms.no_similarity') }}</p>
            </div>
            <div class="mt-5">
              <div style="font-size: 0.8rem; margin-bottom: -16px">
                {{ $t('forms.confirm_password') }}
              </div>
              <v-text-field
                id="confirm"
                v-model="newPasswordConfirm"
                class="changePassword__input"
                variant="outlined"
                color="primary"
                density="compact"
                :rules="newPasswordConfirmRules"
                :type="newPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="
                  newPasswordConfirmVisible ? 'fas fa-eye' : 'fas fa-eye-slash'
                "
                @click:append="
                  () => (newPasswordConfirmVisible = !newPasswordConfirmVisible)
                "
                placeholder
                required
              />
            </div>
            <v-btn
              id="submit"
              type="submit"
              class="mt-8"
              color="primary"
              large
              block
              rounded
            >
              {{ $t('forms.save_password') }}
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';

export default {
  name: 'ModifyPassword',

  data() {
    return {
      valid: true,
      preValidate: false,
      current_password: '',
      currentPasswordVisible: false,
      newPasswordVisible: false,
      newPasswordConfirm: '',
      newPasswordConfirmVisible: false,
      min_length: 0,
      digit: false,
      upper: false,
      lower: false,
      special: false,
      isSimilarity: true,
      errors: [
        'min_length',
        'digit',
        'upper',
        'lower',
        'special',
        'similarity',
      ],
      form: {
        current_password: '',
        new_password: '',
      },
      errorsMessages: {
        current_password: [],
        new_password: [],
      },
    };
  },

  computed: {
    newPassword() {
      return this.form.new_password;
    },

    currentPasswordRules() {
      const error = this.$t('forms.current_password_required');
      return [
        v =>
          !!v ||
          this.preValidate ||
          error,
      ]
    },

    newPasswordRules() {
      const requiredError = this.$t('forms.new_password_required');
      const nonCompliantError = this.$t('forms.non_compliant_password');
      const dontMatchError = this.$t('forms.passwords_dont_match');

      return [
        v => !!v || requiredError,
        // () => !this.isSimilarity || this.isSimilarity,
        () =>
          this.errors.length <= 0 || nonCompliantError,
        v =>
          v === this.newPasswordConfirm ||
          this.newPasswordConfirm.length === 0 ||
          dontMatchError,
      ]
    },

    newPasswordConfirmRules() {
      const requiredError = this.$t('forms.new_password_confirmation_required');
      const dontMatchError = this.$t('forms.passwords_dont_match');

      return [
        v =>
          !!v ||
          this.preValidate ||
          requiredError,
        v =>
          v === this.newPassword ||
          this.preValidate ||
          dontMatchError,
      ]
    }
  },

  watch: {
    newPassword() {
      this.checkPassword();
    },

    newPasswordConfirm() {
      this.$refs.form.validate();
    },
  },

  async mounted() {
    try {
      const responseApi = await OrgsAPI.getPasswordPolicy(this.orgId);
      this.min_length = responseApi.min_length;
      this.digit = responseApi.digit;
      this.upper = responseApi.upper;
      this.lower = responseApi.lower;
      this.special = responseApi.special;
      this.max_similarity = responseApi.max_similarity;
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    checkPassword() {
      OrgsAPI.checkPassword(this.userId, this.form.new_password)
        .then(() => {
          this.errors = [];
          this.$refs.form.validate();
        })
        .catch(response => {
          this.errors = response.response.data.body.unmet;
          this.$refs.form.validate();
        });
    },

    editPassword() {
      this.$emit('submit', this.form);
    },

    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.editPassword();
      }
    },
  },

  props: {
    checkCurrentPassword: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
  },

  emits: ['submit'],
};
</script>

<style lang="scss" scoped>
.changePassword {
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  &__input {
    :deep(.v-icon.fa-eye-slash) {
      color: rgb(var(--v-theme-grey-darken2)) !important;
    }
    :deep(.v-icon.fa-eye) {
      color: rgb(var(--v-theme-dark)) !important;
    }
  }

  &__criteria {
    display: flex;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &Icon {
      border: 1px solid rgb(var(--v-theme-grey-darken2));
      color: rgb(var(--v-theme-grey-darken2));
      width: rem(20);
      height: rem(20);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .v-icon {
        font-size: rem(10);
      }
    }

    p {
      margin-left: 8px;
      flex: 1;
    }

    .changePassword__criteriaIcon {
      width: 30px;
      height: 30px;
      border: none;
      background-color: rgb(var(--v-theme-warning));
      color: white;
    }


    &--check {
      .changePassword__criteriaIcon {
        width: 30px;
        height: 30px;
        border: none;
        background-color: rgb(var(--v-theme-success));
        color: white;
      }
    }
  }
}
</style>
