<template>
  <v-dialog
    v-model="shown"
    max-width="1280"
    @click:outside="shown = false"
    @keydown.esc="shown = false"
  >
    <v-card
      v-if="!job"
      class="dialog-card"
    >
      <v-icon
        class="close-icon"
        size="16px"
        @click="shown = false"
      >
        fas fa-times
      </v-icon>
      <h2 class="dialog-title">
        {{ $t('workflows.test.title') }}
      </h2>
      <v-card-text class="pa-2">
        <div class="config-body mb-4">
          <div class="settings-left">
            {{ $t('workflows.name') }}
          </div>

          <div class="settings-right">
            <v-text-field
              v-model="workflow.name"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="true"
              hide-details
            />
          </div>
        </div>
        <div class="config-body mb-4">
          <div class="settings-left">
            {{ $t('workflows.test.data') }}
          </div>
          <div class="settings-right">
            <v-textarea
              v-model="data"
              variant="outlined"
              class="text-mono"
              color="primary"
              density="compact"
              rows="5"
              @keyup="validateJSON"
              hide-details
              no-resize
            />

            <span
              v-if="errorData"
              class="error-message"
            >
              {{ errorData }}
            </span>
          </div>
        </div>
        <div class="config-body mb-4">
          <div class="settings-left">
            {{ $t('workflows.test.custom_metadata') }}
          </div>

          <div class="settings-right">
            <v-text-field
              v-model="custom_metadata"
              variant="outlined"
              class="text-mono"
              color="primary"
              density="compact"
              hide-details
            />
          </div>
        </div>
        <div
          v-for="(file, index) in files"
          :key="index"
          class="config-body mb-4"
        >
          <div class="settings-left">
            {{ $t('workflows.jobs.files') }}
          </div>
          <div class="settings-right d-flex align-center">
            <v-select
              v-model="file.type"
              style="max-width: 200px;"
              variant="outlined"
              color="primary"
              density="compact"
              label="Collection"
              :items="localizedfileItems"
              hide-details
            />

            <ButtonWithIcon
              v-if="!file.file"
              class="ml-2"
              icon="fas fa-upload"
              icon-position="left"
              variant="outlined"
              :color="'primary'"
              :message="$t('workflows.test.upload')"
              :span-class="'ml-2'"
              @click="triggerFileUpload(index)"
              rounded
            />
            <div
              v-else
              class="ellipsis clickable ml-2"
              style="max-width: calc(100% - 240px);"
              @click="triggerFileUpload(index)"
            >
              <small class="font-weight-bold">
                {{ $t('workflows.test.file_name') }}
              </small>
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <small
                    class="d-block ellipsis"
                    v-bind="props"
                  >
                    {{ file.file.name }}
                  </small>
                </template>
                {{ `${file.file.name} (${$t('workflows.test.click_change')})` }}
              </v-tooltip>
            </div>
            <input
              ref="uploader"
              type="file"
              class="d-none"
              @change="handleFileUpload($event, index)"
            >
            <v-tooltip v-if="index === files.length - 1">
              <template #activator="{ props }">
                <v-icon
                  class="fas fa-plus ml-2"
                  v-bind="props"
                  density="compact"
                  color="primary"
                  size="16"
                  @click="addFile"
                />
              </template>
              {{ $t('workflows.test.add_file') }}
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-0">
        <v-btn
          color="primary"
          variant="flat"
          :disabled="!isValid"
          @click="createTestInstance"
          rounded
        >
          {{ $t('workflows.test.launch') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-else
      class="dialog-card pa-2"
    >
      <v-icon
        class="close-icon"
        size="16px"
        @click="shown = false"
      >
        fas fa-times
      </v-icon>

      <h2 class="dialog-title">
        {{ $t("workflows.test.title") }}
      </h2>

      <v-card-text class="pa-2">
        <JobHistory
          :show-back-link="false"
          :job-id="job.id"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import { displaySnackbarError } from '@/utils/SnackbarUtils';
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';
import JobHistory from '@/components/extract/views/Workflows/JobHistory.vue';

export default {
  name: 'WorkflowTestDialog',

  components: {
    ButtonWithIcon,
    JobHistory,
  },

  data() {
    return {
      fileItems: ['email', 'file', 'attachments'],
      shown: false,
      errorData: '',
      job: null,
      data: '',
      custom_metadata: '',
      files: [
        {type: 'email', file: null},
      ],
    };
  },

  computed: {
    isValid() {
      return this.errorData === '' ;
    },

    localizedfileItems(){
      return this.fileItems.map(item => ({
        title: this.$t(`workflows.test.${item}`),
        value: item
      }));
    }
  },

  methods: {
    show() {
      this.job = null;
      this.shown = true;
    },

    triggerFileUpload(index) {
      this.$refs.uploader[index].click();
    },

    handleFileUpload(event, index){
      this.files[index].file = event.target.files[0];
    },

    addFile() {
      this.files.push({type: 'email', file: null});
    },

    validateJSON() {
      this.errorData = '';
      const trimmedData = this.data ? this.data.trim() : '';

      if (trimmedData === '') {
        return;
      }

      try {
        const parsedData = JSON.parse(trimmedData);

        if (parsedData === null || typeof parsedData !== 'object' || Array.isArray(parsedData)) {
          this.errorData = this.$t('workflows.test.object_expected');
          return;
        }
      } catch (error) {
        this.errorData = this.$t('workflows.test.invalid_json_format');
      }
    },

    async createTestInstance() {
      const formData = new FormData();
      const queryParams = {
        start: true,
        is_test: true,
      };

      if (this.custom_metadata) {
        queryParams.custom_metadata = this.custom_metadata;
      }

      queryParams.workflow_id = this.workflow.id;

      if (this.data) {
        try {
          formData.append(
            "data",
            new Blob([this.data], {
              type: "application/json",
            }),
            "data.json",
          );
        } catch (error) {
          displaySnackbarError(
            this.$t('workflows.test.error_adding_test_data')
          );
          return;
        }
      }

      for (const item of this.files) {
        if (item.type && item.file) {
          formData.append(item.type, item.file);
        }
      }

      try {
        this.job = await WorkflowAPI.createJob(formData, queryParams);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    }
  },

  props: {
    workflow: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.config-body {
  display: flex;
  align-items: center;

  .settings-left {
    width: 25%;
    padding-right: 20px;
    font-weight: bold;
  }

  .settings-right {
    width: 75%;
  }

  .file-name-container {
    display: flex;
    align-items: center;

    border: 1px solid #ccc;
    max-width: 100%;
    flex-grow: 0;
  }
}

.d-none {
  display: none !important;
}

.error-message {
  color: red;
  font-size: smaller;
}
</style>
