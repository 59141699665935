import { http } from '@/plugins/axios';

const BASE_PATH = 'system_2/business_rule';

export class BusinessRuleAPI {
  static get(
    offset,
    limit,
    docTypeId,
    nameFilter = '',
    sortDesc = true,
    external = false,
    token = null,
  ) {
    return http.get(
      `${BASE_PATH}/`, {
        params: {
          limit,
          offset,
          name_filter: nameFilter,
          document_type_id: docTypeId,
          sort_desc: sortDesc,
          noAuth: external,
          external,
          token
        },
      }
    )
      .then(response => {
        const total = parseInt(response.headers['x-total-count'], 10);
        return Promise.resolve([response.data, total]);
      })
      .catch(error => Promise.reject(error));
  }

  static getBusinessRule(id, external, token = null) {
    return http.get(
      `${BASE_PATH}/${id}`,
      {
        params: {
          external,
          noAuth: external,
          token,
        }
      }
    );
  }

  static getRuleResults(fileId, external = false, token = null) {
    return http.get(`${BASE_PATH}/${fileId}/result/`, {
      params: { noAuth: external, external, token }
    })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}