<template>
  <v-card class="classify-training-steps training-stepper">
    <NumberBullet
      :num="1"
      :incomplete="step <= 1"
    />
    <div class="inline-middle stepper-title">
      <div>
        {{ $t('models.training_question') }}
      </div>
      <small v-if="step > 1">
        {{ $t(`models.${selectedDatasetType}_dataset_label`) }}
      </small>
    </div>
    <div class="stepper-content">
      <div v-if="step === 1">
        <v-radio-group
          v-model="selectedDatasetType"
          inline
        >
          <div
            v-for="(datasetType_, index) in datasetTypes"
            :key="index"
            class="inline-middle radio-box right-gap-sm"
          >
            <v-radio
              :label="$t(`models.${datasetType_}_dataset_label`)"
              :value="datasetType_"
            />
          </div>
        </v-radio-group>
        <v-btn
          color="primary"
          @click="step = 2"
          rounded
        >
          <v-icon start>
            fas fa-check
          </v-icon>
          {{ $t('continue') }}
        </v-btn>
      </div>
    </div>

    <NumberBullet
      :num="2"
      :incomplete="step <= 2"
    />
    <div class="inline-middle stepper-title">
      <div>
        {{ $t('models.select_datasets') }}
      </div>
      <small v-if="step > 2">
        <div v-if="allDatasets">
          {{ totalCompleteDatasets }} {{ $t('models.categories') }}
        </div>
        <div v-else>
          {{ selectedDatasets.length }} {{ $t('models.categories') }}
        </div>
      </small>
    </div>
    <div class="stepper-content">
      <div v-if="step === 2">
        <div v-if="completeDatasets.length < 2">
          <InfoBox class="bottom-gap">
            {{ $t('models.not_enough_datasets', {number: $store.getters.CLASSIFY_MIN_ENTRIES}) }}
          </InfoBox>
          <v-btn
            color="primary"
            @click="goToDatasets"
            rounded
          >
            {{ $t('models.configure_datasets') }}
          </v-btn>
        </div>
        <div v-else>
          <InfoBox>{{ $t('models.select_min_datasets') }}</InfoBox>
          <v-switch
            v-model="allDatasets"
            class="option-switch"
            style="margin-top: 0px !important"
            color="primary"
            :label="$t('models.add_all_complete')"
            inset
          />
          <div
            ref="datasetContainer"
            class="dataset-container"
            :class="{ 'top-gap': selectedDatasetType === 'emails' }"
            @scroll="handleScroll"
          >
            <div
              v-for="(dataset, index) in completeDatasets"
              :ref="`Option${index}`"
              :key="index"
            >
              <BoxedCheckbox
                :label="`${dataset.name} (${getDatasetNumberOfEntries(dataset)})`"
                :value="isDatasetSelected(dataset.id)"
                @update:model-value="v =>
                  handleDatasetCheckbox(dataset.id, v)"
              />
            </div>
          </div>
          <div
            class="top-gap-sm"
          >
            <MaxWidthChip
              v-for="dataset in selectedDatasets"
              :key="dataset"
              :text-array="[
                completeDatasets.find(d => d.id === dataset).name
              ]"
            />
          </div>
          <div
            style="font-size: 0.9rem"
          >
            {{ $t('models.total_docs_in_selected') }}
            <strong>{{ totalClassifierEntries }}</strong>
            <span v-if="totalClassifierEntries < $store.getters.CLASSIFY_MIN_TOTAL_ENTRIES">
              - {{ $t('models.min_count') }}
              <strong class="mr-1">
                {{ $store.getters.CLASSIFY_MIN_TOTAL_ENTRIES }}
              </strong>
              <v-icon
                style="top: -1px"
                size="16"
              >
                fas fa-exclamation-circle
              </v-icon>
            </span>
            <v-icon
              v-else
              style="top: -1px"
              class="ml-1"
              color="primary"
              size="16"
            >
              fas fa-check
            </v-icon>
          </div>
          <v-btn
            class="top-gap-sm d-block"
            color="primary"
            :disabled="
              (selectedDatasets.length < 2 || totalClassifierEntries < 50)
            "
            @click="step = 3"
            rounded
          >
            <v-icon start>
              fas fa-check
            </v-icon>
            {{ $t('continue') }}
          </v-btn>
        </div>
      </div>
    </div>
    <NumberBullet
      :num="3"
      :incomplete="step <= 3"
    />
    <div class="inline-middle stepper-title">
      <div>
        {{ $t('models.name') }}
      </div>
      <small v-if="step > 3">
        {{ modelName }}
      </small>
    </div>
    <div class="stepper-content">
      <div v-if="step === 3">
        <v-text-field
          v-model="modelName"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('models.type_new_name')"
          @keydown.enter="validateName"
        />
        <v-switch
          v-if="selectedDatasetType === 'documents'"
          v-model="modelFeatures"
          class="option-switch"
          style="margin-top: -19px !important"
          value="content"
          color="primary"
          :label="$t('models.features.content')"
          inset
        />
        <v-btn
          class="top-gap-sm"
          color="primary"
          :disabled="modelName.trim() === ''"
          @click="step = 4"
          rounded
        >
          <v-icon start>
            fas fa-check
          </v-icon>
          {{ $t('continue') }}
        </v-btn>
      </div>
    </div>
    <TrainingStep
      :step="4"
      :current-step="step"
      @click-launch="startTraining"
    />
  </v-card>
</template>

<script>
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';

import NumberBullet from "@/components/common/elements/General/NumberBullet";
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import TrainingStep from '@/components/extract/elements/Training/TrainingStep';
import InfoBox from '@/components/common/elements/General/InfoBox';
import BoxedCheckbox from '@/components/common/elements/Forms/BoxedCheckbox';
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';

export default {
  name: 'ClassifyTrainingSteps',

  components: {
    NumberBullet,
    MaxWidthChip,
    TrainingStep,
    InfoBox,
    BoxedCheckbox,
  },

  data() {
    return ({
      step: 1,
      allDatasets: false,
      completeDatasets: [],
      totalCompleteDatasets: 0,
      selectedDatasets: [],
      modelName: '',
      modelFeatures: ['content'],
      selectedDatasetType: 'emails',
      excludeDatasetsIds: [],
    });
  },

  computed: {
    datasetTypes() {
      if (this.$store.getters.loggedInUser.products.includes('extract')) {
        return ['emails', 'documents'];
      }
      return ['emails'];
    },

    totalClassifierEntries() {
      let nb_files = 0;
      this.selectedDatasets.forEach(id => {
        const dataset = this.completeDatasets.find(ds => ds.id === id);
        if (dataset) {
          if (this.selectedDatasetType === 'documents') {
            nb_files += dataset.nb_files;
          } else {
            nb_files += dataset.emails_count;
          }
        }
      });
      return nb_files;
    },

    numberOfCompleteDatasets() {
      return this.completeDatasets.length;
    },

    lastPos() {
      return this.numberOfCompleteDatasets - 1;
    },
  },

  watch: {
    allDatasets() {
      if (this.allDatasets) {
        this.selectedDatasets = this.completeDatasets.map((dataset) => dataset.id);
      } else {
        this.selectedDatasets = [];
      }
    },

    selectedDatasetType() {
      this.selectedDatasets = [];
      this.allDatasets = false;
      this.getCompleteDatasets();
    }
  },

  mounted() {
    this.getCompleteDatasets();
  },

  methods: {
    goToDatasets() {
      if (this.selectedDatasetType === 'documents') {
        this.$router.push('/suite/studio/datasets');
      } else {
        this.$router.push('/suite/studio/emails_datasets');
      }
    },

    reset() {
      this.$emit('reset');
      this.step = 1;
      this.selectedDatasets = [];
      this.modelName = '';
      this.allDatasets = false;
    },

    async getCompleteDatasets(offset = 0, limit = 20) {
      if (offset && offset >= this.totalCompleteDatasets) {
        return;
      }
      try {
        this.$store.commit('setLoadingScreen', true);
        let response;
        if (this.selectedDatasetType === 'documents') {
          response = await DatasetAPI.getClassify(offset, limit);
        } else {
          response = await EmailsDatasetsAPI.fetchAll({offset, limit, statuses: ['ready']});
        }
        if (offset > 0) {
          this.completeDatasets = [...this.completeDatasets, ...response.data];
        } else {
          this.completeDatasets = response.data;
        }
        this.totalCompleteDatasets = parseInt(response.headers['x-total-count'], 10);
        if (this.allDatasets) {
          this.selectedDatasets = this.completeDatasets.map((dataset) => dataset.id);
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    validateName() {
      this.step = this.modelName.trim() === '' ? 3 : 4;
    },

    handleScroll() {
      const TOP_DIFFERENCE = 300;
      const selectPanel = this.$refs.datasetContainer;
      const lastOption = this.$refs[`Option${this.lastPos}`];
      if (selectPanel && lastOption) {
        const panelTop = selectPanel.getBoundingClientRect().top;
        const lastTop = lastOption[0].getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loading
        ) {
          this.getCompleteDatasets(this.numberOfCompleteDatasets);
        }
      }
    },

    handleDatasetCheckbox(checkedDatasetId, added) {
      if (added) {
        this.selectedDatasets.push(checkedDatasetId);
        if (this.allDatasets) {
          this.excludeDatasetsIds = this.excludeDatasetsIds.filter((datasetId) => datasetId !== checkedDatasetId);
        }
      } else {
        this.selectedDatasets = this.selectedDatasets.filter((dataset) => dataset !== checkedDatasetId);
        if (this.allDatasets) {
          this.excludeDatasetsIds.push(checkedDatasetId);
        }
      }
    },

    async startTraining() {
      try {
        if (this.selectedDatasetType === 'emails') {
          await this.trainEmailsClassifier();
        } else {
          await this.trainDocClassifier();
        }
      } catch (error) {
        this.step = 1;
        return;
      }
      this.reset();
      displaySnackbarSuccess(this.$t('models.being_trained'));
    },

    async trainEmailsClassifier() {
      await ClassifyModelAPI.create({
        name: this.modelName.trim(),
        datasetIds: this.allDatasets ? [] : this.selectedDatasets,
        excludedDatasetIds: this.allDatasets ? this.excludeDatasetsIds : [],
      });
    },

    async trainDocClassifier() {
      await DatasetAPI.trainClassify(
        this.allDatasets ? [] : this.selectedDatasets,
        this.modelName.trim(),
        this.modelFeatures.join(','),
        this.allDatasets,
        this.allDatasets ? this.excludeDatasetsIds : [],
      );
    },

    getDatasetNumberOfEntries(dataset) {
      if (this.selectedDatasetType === 'documents') {
        return dataset.nb_files;
      } else {
        return dataset.emails_count;
      }
    },

    isDatasetSelected(datasetId) {
      return this.selectedDatasets.includes(datasetId);
    },
  },

  emits: ['reset'],
}
</script>


<style lang="scss" scoped>
.training-stepper {
  width: 55%;
  padding-right: 30px;
  margin-right: 30px;

  .stepper-content {
    border-left: 1px solid rgb(var(--v-theme-grey));
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stepper-title {
    height: 20px;
  }

  .dataset-container {
    background-color: rgb(var(--v-theme-grey-lighten2));
    height: 300px;
    padding: 20px;
    border-radius: 6px;
    overflow-x: auto;
  }

  .tab-container {
    padding-top: 20px;
  }

  .option-switch {
    margin-bottom: -19px;
  }
}
</style>
