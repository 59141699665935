<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :rounded="rounded"
    :variant="variant"
    :size="size"
    @click="$emit('click')"
  >
    <v-icon
      :size="iconSize"
      :class="buttonClass"
      start
    >
      {{ icon }}
    </v-icon>
    <slot>
      <span :class="spanClass">
        {{ message }}
      </span>
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonWithIcon',

  computed: {
    buttonClass(){
      return { 'icon-start': this.iconPosition === 'start', 'icon-end': this.iconPosition === 'end', 'icon-middle': this.iconPosition === 'middle' };
    }
  },

  props: {
    icon: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: 'click',
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    iconSize: {
      type: Number,
      default: 17
    },
    iconPosition: {
      type: String,
      default: 'start'
    },
    variant: {
      type: String,
      default: 'flat'
    },
    spanClass: {
      type: String,
      default: ''
    }
  },
  emits: ['click']
}
</script>

<style scoped>
.icon-start {
  margin-right: 0.5rem;
}
.icon-end {
  margin-left: 0.5rem;
}
.icon-middle {
  margin: 0 0.5rem;
}
</style>