<template>
  <div class="api-docs top-gap settings-body d-flex">
    <div class="settings-left">
      <h4>{{ $t('settings.api_settings') }}</h4>
      <div style="font-size: 0.8rem">
        {{ $t('settings.extract_api_explanation') }}
      </div>
    </div>
    <div class="settings-right">
      <div
        v-for="product in userProducts"
        :key="product"
        class="bottom-gap"
      >
        <div class="label text-field-label">
          {{ $t(`settings.${product}_api`) }}
          <v-tooltip right>
            <template #activator="{ props }">
              <v-icon
                size="15"
                v-bind="props"
                :color="getIconStatusColor(serviceStatus[product])"
                end
              >
                fas fa-circle
              </v-icon>
            </template>
            {{ getStatusMessage(serviceStatus[product]) }}
          </v-tooltip>
        </div>
        <v-btn
          style="box-shadow: none; margin-top: 3px;"
          target="_blank"
          color="primary"
          variant="outlined"
          :href="buildSwaggerUrl(product)"
          :disabled="isAPIButtonDisabled(serviceStatus[product])"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-link
          </v-icon>
          {{ $t('settings.swagger') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductHealthCheck } from '@/utils/ProductHealthCheck';

export default {
  name: 'APIDocs',

  data() {
    return {
      userProducts: [],
    };
  },

  computed: {
    serviceStatus() {
      return this.$store.getters.serviceStatus;
    },
  },

  mounted() {
    this.userProducts = [
      ...this.$store.getters.loggedInUser.products,
      'auth',
    ];
    ProductHealthCheck.check(this.userProducts);
  },


  methods: {
    buildSwaggerUrl(product) {
      return `${this.$store.getters.config.backends[product]}${product}/docs`;
    },

    getIconStatusColor(status) {
      if (!status.checked) {
        return 'grey';
      }

      if (!status.running) {
        return 'red';
      }

      if ([true, 'running'].includes(status.status)) {
        return 'green';
      }

      return 'orange';
    },

    getStatusMessage(status) {
      if (!status.checked) {
        return this.$t('settings.product_unchecked');
      }

      if (!status.running) {
        return this.$t('settings.product_unavailable');
      }

      if ([true, 'running'].includes(status.status)) {
        return this.$t('settings.product_running');
      }

      return this.$t('settings.product_status_error');
    },

    isAPIButtonDisabled(status) {
      return !status.checked || !status.running || ![true, 'running'].includes(status.status)
    },
  },
}
</script>