<template>
  <h4 class="inline-middle label mb-2">
    {{ $t(`docTypes.seamless_validation_token.name`) }}
  </h4>
  <v-switch
    v-model="internalExternalLinkSupportEnabled"
    class="inline-middle left-gap-sm"
    color="primary"
    :disabled="disabled"
    @input="onInternalExternalLinkSupportEnabledChange"
    inset
  />
  <p class="bottom-gap-sm seamless-validation-token-message">
    {{ $t(`docTypes.seamless_validation_token.message`) }}
  </p>
  <template v-if="showAnonymousCopyOption">
    <h4 class="inline-middle label mb-2">
      {{ $t(`docTypes.allow_anonymous_copy_to_dataset.name`) }}
    </h4>
    <v-switch
      class="inline-middle left-gap-sm"
      color="primary"
      :disabled="disabled"
      :model-value="allowAnonymousCopyToDataset"
      @update:model-value="$emit('update:allowAnonymousCopyToDataset', $event)"
      inset
    />
    <p
      class="bottom-gap-sm seamless-validation-token-message"
    >
      {{ $t(`docTypes.allow_anonymous_copy_to_dataset.message`) }}
    </p>
  </template>
  <span class="right-gap-sm form-label font-weight-bold">
    {{ $t(`docTypes.seamless_validation_token_duration.name`) }}
  </span>
  <input
    v-model="internalTokenDuration"
    class="input-field right-gap-sm primary--text"
    placeholder="1"
    type="number"
    :min="1"
    :max="$options.constants.MAX_TOKEN_DURATION_HOURS"
    :disabled="disabled"
    @input="onInternalTokenDurationChange"
  >
  <span class="right-gap form-label font-weight-bold">
    {{ $t(`docTypes.seamless_validation_token_duration.hours`) }}
  </span>
  <p
    v-if="tokenDurationError"
    class="error-message"
  >
    {{ tokenDurationError }}
  </p>
</template>

<script>

export default {
  name: 'ExternalLinkConfiguration',

  constants: {
    MAX_TOKEN_DURATION_HOURS: 168, // Maximum duration of one week in hours
  },

  data() {
    return {
      internalExternalLinkSupportEnabled: null,
      internalTokenDuration: null,
      tokenDurationError: null,
    };
  },

  watch: {
    tokenDuration(newVal) {
      this.internalTokenDuration = newVal;
      this.validateTokenDuration();
    },

    isExternalLinkSupportEnabled(newVal) {
      this.internalExternalLinkSupportEnabled = newVal;
    },
  },

  created() {
    this.internalTokenDuration = this.tokenDuration;
    this.internalExternalLinkSupportEnabled = this.isExternalLinkSupportEnabled;
  },

  methods: {
    onInternalExternalLinkSupportEnabledChange() {
      this.$emit('update:isExternalLinkSupportEnabled', this.internalExternalLinkSupportEnabled);
    },

    onInternalTokenDurationChange() {
      if (this.validateTokenDuration()) {
        this.$emit('update:tokenDuration', this.internalTokenDuration);
      }
    },

    validateTokenDuration() {
      if (this.internalTokenDuration > this.$options.constants.MAX_TOKEN_DURATION_HOURS) {
        this.tokenDurationError = this.$t(
          'docTypes.seamless_validation_token_duration.error', {
            maxHours: this.$options.constants.MAX_TOKEN_DURATION_HOURS,
          },
        );
        this.$emit('invalid-token-duration');
        return false;
      }
      this.tokenDurationError = null;
      return true;
    },
  },

  props: {
    isExternalLinkSupportEnabled: {
      type: Boolean,
      required: true
    },

    allowAnonymousCopyToDataset: {
      type: Boolean,
      default: false
    },

    tokenDuration: {
      type: [Number, null],
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showAnonymousCopyOption: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:isExternalLinkSupportEnabled',
    'update:tokenDuration',
    'invalid-token-duration',
    'update:allowAnonymousCopyToDataset',
  ],
}

</script>

<style scoped>
.seamless-validation-token-message {
  margin-top: -30px;
  font-size: 0.8rem;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.input-field {
  display: inline-block;
  background-color: #ffffff00;
  padding: 0 !important;
  border: none;
  border-bottom: 1px solid rgb(var(--v-theme-primary));

  &:focus {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }

  &:focus-visible {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }

  &--hours {
    width: 70px;
  }

  &--pages {
    width: 160px;
  }
}

.form-label {
  font-size: 0.8rem;
}
</style>

