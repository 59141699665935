<template>
  <div class="extract-settings">
    <div class="top-gap d-flex settings-body">
      <div class="settings-left">
        <h4>{{ $t('settings.platform') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.platform_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <ApiTokenSettings app="extract" />
        <hr class="divider-line top-gap bottom-gap">
        <div>
          <div class="label radio-group-label">
            {{ $t('settings.ocr_model.title') }}
          </div>
          <v-radio-group
            v-model="config.ocr_model"
            @change="updateConfig"
            inline
          >
            <div
              v-for="(ocr_m, i) in ocr_models"
              :key="i"
              class="inline-middle radio-box right-gap-sm"
            >
              <v-radio
                :label="$t(`settings.ocr_model.${ocr_m.name}`)"
                :value="ocr_m.code"
              />
            </div>
          </v-radio-group>
        </div>
        <div>
          <div
            class="left-gap-sm inline-middle"
            style="margin-top: -20px; width: 500px"
          >
            <div style="font-size: 0.8rem">
              {{ $t(`settings.ocr_model.message`) }}
            </div>
          </div>
        </div>

        <div class="label radio-group-label top-gap">
          {{ $t('settings.delete_retention_days') }}
        </div>
        <v-radio-group
          v-model="config.delete_retention_days"
          :disabled="invalidUrl"
          @change="updateConfig"
          inline
        >
          <div
            v-for="(period, i) in retention"
            :key="i"
            class="inline-middle radio-box right-gap-sm"
          >
            <v-radio
              :label="$t(`settings.${period.name}`)"
              :value="period.days"
            />
          </div>
        </v-radio-group>

        <hr class="divider-line">
        <div class="label text-field-label top-gap">
          {{ $t('settings.callback') }}
        </div>
        <div class="label text-field-label top-gap-sm">
          {{ $t('url') }}
        </div>
        <div>
          <v-select
            v-model="protocol"
            class="http-select inline-middle"
            density="compact"
            variant="outlined"
            color="primary"
            :items="protocols"
          />
          <v-text-field
            ref="callback"
            v-model="domain"
            class="inline-middle left-gap-sm mt-0"
            style="width: 300px"
            type="url"
            placeholder="www.example.com"
            clear-icon="fas fa-times"
            variant="outlined"
            color="primary"
            density="compact"
            :error="invalidUrl"
            @keyup.enter="updateConfig"
            clearable
          />
          <div
            v-if="invalidUrl"
            class="error-message left-gap inline-middle"
          >
            {{ $t('settings.invalid_url') }}
          </div>
        </div>
        <div class="label text-field-label">
          {{ $t('token') }}
        </div>
        <v-text-field
          v-model="config.file_upload_callback_token"
          class="inline-middle mt-0"
          style="width: 500px"
          clear-icon="fas fa-times"
          variant="outlined"
          color="primary"
          density="compact"
          @keyup.enter="updateConfig"
          clearable
        />
        <div class="label text-field-label">
          {{ $t('settings.custom_auth_header') }}
        </div>
        <v-text-field
          v-model="config.file_upload_callback_auth_header"
          class="callback inline-middle mt-0"
          style="width: 300px"
          clear-icon="fas fa-times"
          variant="outlined"
          color="primary"
          density="compact"
          @keyup.enter="updateConfig"
          clearable
        />
      </div>
    </div>
    <div
      class="settings-body"
      style="text-align: right"
    >
      <v-btn
        color="primary"
        style="margin-left: -10px"
        @click="updateConfig"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import ApiTokenSettings from '@/components/common/elements/Settings/ApiTokenSettings'

import { ConfigAPI } from '@/API/extract/ConfigAPI';

export default {
  name: 'ExtractSettings',

  components: {
    ApiTokenSettings,
  },

  data() {
    return ({
      typeName: '',
      config: {
        ocr_model: "DOCTR",
        file_upload_callback: "",
        file_upload_callback_token: "",
        file_upload_callback_auth_header: "",
        delete_retention_days: '',
      },
      ocr_models: [
        {name: 'tesseract', code: 'TESSERACT'},
        {name: 'doctr', code: 'DOCTR'},
        {name: 'google', code: 'GOOGLE'},
        {name: 'azure', code: 'AZURE'}
      ],
      retention: [
        {name: 'week', days: 7},
        {name: 'month', days: 30},
      ],
      invalidUrl: false,
      domain: "",
      protocol: "http://",
      protocols: ["http://", "https://"],
      extractRunning: this.$store.getters.loggedInUser.products.includes('extract'),
    })
  },

  computed: {
    url: {
      get: function() {
        if (!this.domain) {
          return null;
        }
        return `${this.protocol}${this.domain}`;
      },
    },

    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  mounted() {
    this.getConfig();
  },

  methods: {
    async copyToClipboard (url, message=this.$t('oidc_providers.callback_url_copied')) {
      await navigator.clipboard.writeText(url);
      await this.$store.commit('setSuccessMessage', message);
      this.$store.commit('setSuccessSnackbar', true);
    },

    async getConfig() {
      if (!this.extractRunning) {
        return;
      }
      try {
        this.config = await ConfigAPI.get();
        if (this.config.file_upload_callback) {
          const { protocol, host, pathname } = new URL(this.config.file_upload_callback);
          this.protocol = `${protocol}//`;
          this.domain = host + pathname;
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    checkUrl(value) {
      if (value === null) {
        value = '';
      }
      const pattern = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$|^(?:)$|^$/;
      return pattern.test(value);
    },

    async updateConfig() {
      if (!this.checkUrl(this.url)) {
        this.invalidUrl = true;
      } else {
        this.invalidUrl = false;
        this.$refs.callback.blur();
        this.config.file_upload_callback = this.url;
        try {
          await ConfigAPI.update(this.config);
          this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.error-message {
  color: rgb(var(--v-theme-error));
  font-size: small;
}

.http-select {
  width: 104px;
  margin-top: -20px !important;
}

.info-box {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border-radius: 6px;
  padding: 6px 17px;
  padding-bottom: 10px;
  width: fit-content;

  .info-icon {
    margin-right: 2px;
    top: -1px;
  }
}
</style>
