
import { http } from '@/plugins/axios';

const BASE_PATH = 'production/files/';

export class ProductionFileAPI {
  static get(
    documentTypeId,
    offset,
    limit,
    nameFilter = '',
    onlyUnprocessed = false,
    sortDesc = true,
    onlyPagesWithPendingValues = false,
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          doctype_id: documentTypeId,
          name_filter: nameFilter,
          only_unprocessed: onlyUnprocessed,
          sort_desc: sortDesc,
          offset,
          limit,
          only_pages_with_pending_values: onlyPagesWithPendingValues,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getSingle(id, withPos = false, externalToken = null, onlyPagesWithPendingValues = false) {
    return http
      .get(`${BASE_PATH}${id}`, {
        params: {
          with_review_position: withPos,
          token: externalToken || null,
          noAuth: !!externalToken,
          external: !!externalToken,
          only_pages_with_pending_values: onlyPagesWithPendingValues,
        }
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getDPValues({ id, noAuth, external, token, pages }) {
    pages = pages.length > 0 && pages;
    return http
      .get(`${BASE_PATH}${id}/values/`, {
        params: {
          noAuth,
          external,
          token,
          pages,
        },
        paramsSerializer: {
          indexes: null
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getGroupValues({ id, noAuth, external, token, pages }) {
    pages = pages.length > 0 && pages;
    return http
      .get(`${BASE_PATH}${id}/groups/`, {
        params: {
          noAuth,
          external,
          token,
          pages,
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getObjectValues({ id, noAuth, external, token, pages }) {
    pages = pages.length > 0 && pages;
    return http
      .get(`${BASE_PATH}${id}/objects/`, {
        params: {
          noAuth,
          external,
          token,
          pages,
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
