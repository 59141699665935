<template>
  <div class="dp-models">
    <div class="label">
      {{ $t('dataPoints.select_model') }}
    </div>
    <CustomSelect
      style="margin-top: 5px"
      :items="customModels"
      :selected="selectedModel"
      :placeholder="$t('dataPoints.select_model')"
      @selected-changed="handleModelChange"
      @get-more="(nameFilter, reset) => getMoreModels(numberOfCustomModels, nameFilter, reset)"
    />
    <v-select
      v-if="selectedModel !== 0"
      v-model="selectedModelVersion"
      class="clickable inline-middle top-gap-sm"
      variant="outlined"
      color="primary"
      density="compact"
      style="width: 300px"
      item-title="version"
      :disabled="selectedModel === 0"
      :items="selectedModelVersions"
      :placeholder="$t('models.select_version')"
    />
    <div
      v-if="selectedModel !== 0 && selectedModelVersion !== 0 && showEntity"
      class="top-gap-sm"
    >
      <div class="label">
        {{ $t('dataPoints.select_entity') }}
      </div>
      <v-select
        v-model="selectedCustomEntity"
        class="inline-middle"
        variant="outlined"
        color="primary"
        density="compact"
        item-title="name"
        item-value="id"
        style="width: 300px; margin-top: 5px;"
        :items="selectableCustomEntities"
        :placeholder="$t('dataPoints.select_entity')"
        dense
      />
    </div>
  </div>
</template>

<script>
import model_mixin from '@/mixins/model.js';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';

export default {
  name: 'DPModels',

  mixins: [
    model_mixin,
  ],

  components: {
    CustomSelect,
  },

  data() {
    return ({
      selectedCustomEntity: '',
      selectedModel: 0,
      selectedModelVersion: null,
    })
  },

  computed: {
    model() {
      if (this.selectedModel > 0) {
        return this.customModels.find(m => m.id === this.selectedModel);
      }
      return null;
    },

    version() {
      if (this.selectedModelVersion > 0) {
        return this.model.versions.find(v => v.version === this.selectedModelVersion);
      }
      return null;
    },

    selectedModelVersions() {
      if (this.model) {
        return this.model.versions;
      }
      return [];
    },

    selectableCustomEntities() {
      if (this.model && this.version) {
        return this.version.labels.map(entity => ({ id: entity, name: entity }));
      }
      return [];
    },
  },

  watch: {
    selectedModel() {
      this.$emit('modelChange', this.selectedModel);
    },

    selectedModelVersion() {
      this.$emit('versionChange', this.selectedModelVersion);
      setTimeout(() => {
        if (this.version > 0 && !this.version.includes(this.selectedCustomEntity)) {
          this.selectedCustomEntity = '';
        }
      })
    },

    selectedCustomEntity() {
      this.$emit('entityChange', this.selectedCustomEntity);
    }
  },

  async mounted() {
    await this.getCustomModels();
    if (this.initialModel !== 0) {
      if (this.initialModel > 0) {
        await this.getCustomModelsToId(this.initialModel);
      }
      this.selectedModel = this.initialModel;
      this.selectedModelVersion = this.initialModelVersion;
    }
    if (this.initialEntity !== '') {
      this.selectedCustomEntity = this.initialEntity;
    }
  },

  methods: {
    handleModelChange(id) {
      this.selectedModel = id;
      const model = this.customModels.find(m => m.id === this.selectedModel);

      if (model && model.versions.length > 0) {
        this.selectedModelVersion = model.versions[0].version;
      } else {
        this.selectedModelVersion = 0;
      }

      if (this.selectedModel === this.initialModel) {
        this.selectedCustomEntity = this.initialEntity;
      } else {
        this.selectedCustomEntity = '';
      }
    },

    async getCustomModelsToId(id) {
      if (
        !this.customModels.map(m => m.id).includes(id) &&
        this.totalActiveModels > this.numberOfCustomModels
      ) {
        await this.getCustomModels(this.numberOfCustomModels);
        await this.getCustomModelsToId(id);
      }
    },
  },

  props: {
    initialModel: {
      type: Number,
      default: 0,
    },

    initialEntity: {
      type: String,
      default: '',
    },

    initialModelVersion: {
      type: Number,
      default: 0,
    },

    showEntity: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['modelChange', 'versionChange', 'entityChange'],
}
</script>

<style lang="scss" scoped>
.dp-models {
  padding-bottom: 100px;
}
</style>
