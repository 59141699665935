<template>
  <div class="folder-node mt-2">
    <div
      v-if="show"
      class="d-flex align-center"
      @mouseover="hovering = true"
      @mouseleave="hovering = false"
    >
      <CheckboxEllipsis
        v-model="folder.selected"
        :label="folder.name"
        :disabled="disableFilter || disabled"
        @update:model-value="handleClick"
      />
      <div
        class="ml-1"
        :style="{ opacity: disableFilter || disabled ? 0.5 : 1 }"
      >
        ({{ folder.nb_processed_files }})
      </div>
      <ChevronButton
        v-if="
          folder.nb_children_folders > 0
            || type === 'browse' && !folder.synced
        "
        v-model="folder.open"
        class="ml-1"
        :size="14"
        @update:model-value="handleChevronClick"
      />
      <v-icon
        v-if="type === 'browse' && (showDelete && !disabled || deleting)"
        class="clickable ml-1"
        color="primary"
        size="13"
        @click="deleting = true"
      >
        fas fa-trash
      </v-icon>
    </div>
    <div v-if="show && (folder.folders.length > 0 || type === 'browse')">
      <FolderNode
        v-for="subfolder in folder.folders"
        :key="subfolder.id"
        :ref="`folderNode-${subfolder.id}`"
        class="bottom-gap-sm left-gap"
        :folder="subfolder"
        :show="folder.open"
        :type="type"
        :disabled="folder.selected"
        @update-file-number="updateFileNumber"
        @folder-deleted="removeDeleted"
        @refresh-folders="$emit('refreshFolders'); getFolders(folder, folder.open)"
        @select-parent="handleClick"
      />
      <div
        v-if="type === 'browse' && !folder.synced && !addingNewFolder && folder.open"
        class="clickable left-gap d-flex align-bottom"
        :style="{'margin-top': folder.folders && folder.folders.length > 0 ? '-5px' : '5px'}"
        @click="addingNewFolder = true"
      >
        <v-icon
          color="primary"
          class="mr-2 mt-1"
        >
          fas fa-folder-plus
        </v-icon>
        <ItemName
          class="noselect"
          :item="{ name: $t('search.home.new_folder') }"
          :show-id="false"
        />
      </div>
      <div
        v-if="type === 'browse' && !folder.synced && addingNewFolder && folder.open"
        class="left-gap text-left position-relative"
      >
        <input
          ref="newFolderInput"
          v-model="newFolderName"
          class="simple-input mb-0"
          style="width: 80%;"
          :placeholder="$t('search.home.folder_name')"
          @keyup.enter="createFolder"
          @keyup.escape="addingNewFolder = false; newFolderName = '';"
        >
        <v-icon
          class="right-gap-sm position-absolute clickable"
          style="top: 50%; transform: translateY(-50%);"
          size="13"
          color="primary"
          @click="createFolder"
        >
          fas fa-check
        </v-icon>
      </div>
    </div>
    <ConfirmDialog
      v-model="deleting"
      :title="$t('search.home.delete_folder')"
      :message="$t('search.home.delete_folder_confirmation')"
      @confirm="deleteFolder"
    />
  </div>
</template>

<script>
import { FolderAPI } from '@/API/search/FolderAPI';

import FolderNode from "@/components/search/elements/Home/FolderNode";
import ChevronButton from "@/components/common/elements/General/ChevronButton";
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import CheckboxEllipsis from '@/components/common/elements/General/CheckboxEllipsis';

export default {
  name: 'FolderNode',

  components: {
    FolderNode,
    ChevronButton,
    ConfirmDialog,
    ItemName,
    CheckboxEllipsis,
  },

  data() {
    return {
      addingNewFolder: false,
      deleting: false,
      newFolderName: '',
      hovering: false,
    };
  },

  computed: {
    showDelete() {
      return this.hovering && !this.folder.synced;
    },

    searchFolder: {
      get() {
        return this.$store.getters.searchFolder;
      },
      set(payload) {
        this.$store.commit('setSearchFolder', payload);
      },
    },

    browseFolder: {
      get() {
        return this.$store.getters.browseFolder;
      },
      set(payload) {
        this.$store.commit('setBrowseFolder', payload);
      },
    },

    disableFilter() {
      return this.type === 'filter' && this.folder.nb_processed_files === 0 || this.disabled;
    }
  },

  watch: {
    searchFolder(id) {
      if (id) {
        if (this.folder.id !== id) {
          this.folder.selected = false;
        } else {
          // Necessary to update selected folder when repeating an old query
          if (!this.folder.selected) {
            this.folder.selected = true;
          }
        }
      }
    },

    browseFolder(newFolder) {
      if (newFolder && this.folder.id !== newFolder.id) {
        this.folder.selected = false;
      }
    },

    addingNewFolder(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.newFolderInput.focus();
        });
      }
    },
  },

  methods: {
    async handleChevronClick(open) {
      this.folder.open = open;
      if (this.folder.folders.length === 0) {
        await this.getFolders(this.folder, open);
      }
    },

    async createFolder() {
      try {
        if (!this.newFolderName.trim()) {
          return;
        }
        await FolderAPI.createFolder({
          name: this.newFolderName.trim(),
          parent_id: this.folder.id,
        });
        await this.getFolders(this.folder, true);
        this.$emit('refreshFolders');
      } catch (err) {
        console.log(err);
        return;
      } finally {
        this.addingNewFolder = false;
        this.newFolderName = '';
      }
    },

    async deleteFolder() {
      this.deleting = false;
      try {
        await FolderAPI.deleteFolder(this.folder.id);
        if (this.folder.selected) {
          this.$emit('selectParent');
        }
        this.$emit('folderDeleted', this.folder.id, -this.folder.nb_processed_files);
      } catch (err) {
        console.log(err);
      }
    },

    handleClick() {
      if (this.type === 'filter') {
        this.searchFolder = this.folder.selected ? this.folder.id : null;
      } else {
        this.browseFolder = this.folder.selected ? this.folder : null;
      }
      this.$nextTick(() => this.updateFolders(this.folder.selected));
    },

    updateFileNumber(nbFiles) {
      this.folder.nb_processed_files += nbFiles;
    },

    removeDeleted(id, nbFiles) {
      this.folder.folders = this.folder.folders.filter(f => f.id !== id);
      this.updateFileNumber(nbFiles);
      this.$emit('folderDeleted', id, nbFiles);
    },

    updateFolders(selected) {
      this.folder.folders.forEach(f => {
        f.selected = selected;
        this.$refs[`folderNode-${f.id}`][0].updateFolders(selected);
      });
    },

    async getFolders(folder, open) {
      if (open) {
        try {
          const folders = await FolderAPI.getFolders(this.folder.id);
          this.folder.folders = folders.filter(f => f.parent_folder_id).map(f => {
            const existing = this.folder.folders.find(e => e.id === f.id);
            if (existing) {
              return existing;
            }
            f.open = false;
            f.folders = [];
            f.selected = this.folder.selected;
            return f;
          });
          if (this.type === 'filter') {
            this.folder.folders.forEach(f => {
              this.manageFolderFilter(f.selected, f.id);
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        folder.folders = [];
      }
    },
  },

  props: {
    folder: {
      type: Object,
      required: true,
    },

    show: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'filter',
    },
  },

  emits: ['folderDeleted', 'refreshFolders', 'selectParent'],
}
</script>

<style lang="scss" scoped>
.simple-input {
  width: 100%;
  padding: 5px 0;
  margin-bottom: 10px;
  border-radius: 0 !important;
  border-bottom: solid 1px #aaa;
  border-radius: 5px;
  outline: none;
  font-size: small;

  &:focus, &:hover {
    border-color: rgb(var(--v-theme-primary));
  }

  transition: border-color 0.2s;
}
</style>
