<template>
  <div
    class="page-tabs d-flex"
    :class="{
      'mt-4': !isFullScreen,
      'mt-2': isFullScreen,
      'white': white,
    }"
  >
    <div
      v-if="modelValue === 'documents' && isFullScreen"
      class="filler text-h4 primary--text pr-2"
      style="max-width: 300px"
      :style="{ width: rename ? '100%' : 'auto' }"
    >
      <ItemName
        v-if="type.name"
        class="ml-2 mt-2"
        :clickable="false"
        :item="type"
        :editing="rename"
        :show-edit-icon="user && authorizedRoles.includes(user.role)"
        @save-file-name="saveName"
        @start-name-edit="rename = true"
        @cancel-name-edit="rename = false"
        editing-allowed
      />
    </div>
    <div
      v-if="modelValue === 'datasets' && isFullScreen"
      class="page-tab-between page-tab-first"
    />
    <div
      v-for="(tab, i) in tabs"
      :key="tab.title"
      class="d-flex"
    >
      <div class="page-tab-between" />
      <div
        class="page-tab clickable d-flex noselect"
        :class="{
          'active-tab': modelValue === tabs[i].name
        }"
        :style="{ 'width': wide ? '280px' : '200px' }"
        @click="$emit('update:modelValue', tabs[i].name)"
      >
        {{ tab.title }}
        <v-tooltip
          v-if="tab.info"
          color="#423F4F"
          right
        >
          <template #activator="{ props }">
            <v-icon
              class="info-icon"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          <span style="color: white">
            {{ $t(`${tab.info}`) }}
          </span>
        </v-tooltip>
        <div class="text-h3 primary--text">
          <v-skeleton-loader
            v-if="loading && tab.bigNumber === 0"
            type="heading"
            width="70px"
            style="margin-right: -20px"
          />
          <span v-else-if="tab.bigNumber">
            {{ tab.bigNumber }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="filler text-h3 d-flex align-center pl-5"
      style="width: 100%"
    />
  </div>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'PageTabs',

  components: { ItemName },

  data() {
    return {
      authorizedRoles: [
        "orgadmin",
        "sysadmin",
      ],
      rename: false,
    };
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },
  },

  methods: {
    saveName(id, name) {
      this.$emit('saveName', name, id);
      this.rename = false;
    }
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    tabs: {
      type: Array,
      required: true,
    },

    isFullScreen: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    type: {
      type: Object,
      default: () => {}
    },

    wide: {
      type: Boolean,
      default: false,
    },

    white: {
      type: Boolean,
      default: false
    }
  },

  emits: ['saveName', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.page-tabs {
  .page-tab {
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px !important;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    border-radius: 6px 6px 0 0 !important;
    background-color: white;
    height: 43px;
  }

  .page-tab-between {
    height: 43px;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 8px;
  }

  .page-tab-first {
    width: 20px;
  }

  .filler {
    border-bottom: 1px solid blue;
  }

  .active-tab {
    background-color: #f4f5f9;
    border: 1px solid rgb(var(--v-theme-primary));
    border-bottom: 1px solid #f4f5f9;
  }
}

.page-tabs.white {
  .active-tab {
    background-color: #fff !important;
    border-bottom: 1px solid #fff;
  }
}
</style>
