<template>
  <div class="org-select">
    <div
      class="custom-select noselect"
      :style="{ width: short ? '245px' : '300px' }"
      @click="toggleSelectOn"
    >
      <div class="org-name ellipsis">
        <span :style="{'font-style': numberOfOrgs ? 'normal' : 'italic' }">
          {{ selectedOrg.name }}
        </span>
      </div>
      <v-icon
        class="open-icon"
        size="17"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="(org, i) in orgs"
          :ref="`Option${i}`"
          :key="org.id"
          class="option ellipsis"
          :value="org.id"
          @click="selectedId = org.id"
        >
          {{ org.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';

export default {
  name: 'OrgSelect',

  data() {
    return ({
      selectedId: -1,
      selectOn: false,
      orgs: [],
      loading: false,
    })
  },

  computed: {
    totalOrgs: {
      get() {
        return this.$store.getters.totalOrgs;
      },
      set(count) {
        this.$store.commit('setTotalOrgs', count);
      }
    },

    lastPos() {
      return this.numberOfOrgs - 1;
    },

    numberOfOrgs() {
      return this.orgs.length;
    },

    selectedOrg() {
      if (this.selectedId === 0) {
        if (this.showEvery) {
          return { name: this.$t('organizations.all') };
        } else {
          return { name: this.$t('organizations.select') };
        }
      }
      const org = this.orgs.find(d => d.id === this.selectedId);
      if (this.showEvery) {
        return org || { name: this.$t('organizations.all') };
      }
      return org || { name: this.$t('organizations.select') };
    },
  },

  watch: {
    selected(id) {
      this.selectedId = id;
    },

    selectedId(id) {
      this.$emit('selectedChanged', id);
    },
  },

  async created() {
    await this.getOrgs();
    this.selectedId = this.selected;
  },

  methods: {
    async getOrgs(offset = 0, limit = 20) {
      if (offset && offset >= this.totalOrgs) {
        return;
      }
      try {
        this.loading = true;
        const response = await OrgsAPI.getOrgs(limit, offset);
        if (offset > 0) {
          this.orgs = [...this.orgs, ...response.data];
        } else if (this.showEvery) {
          this.orgs = [
            {
              id: -1,
              name: this.$t('organizations.all'),
            },
            ...response.data,
          ];
        } else {
          this.orgs = response.data;
        }
        this.totalOrgs = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    handleScroll() {
      const TOP_DIFFERENCE = 300;
      const selectPanel = this.$refs.selectPanel;
      const lastOption = this.$refs[`Option${this.lastPos}`];
      if (selectPanel && lastOption) {
        const panelTop = selectPanel.getBoundingClientRect().top;
        const lastTop = lastOption[0].getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loading
        ) {
          this.getOrgs(this.orgs.length);
        }
      }
    },

    toggleSelectOn() {
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        setTimeout(() => {
          this.$refs.selectPanel.focus();
        }, 50);
      }
    }
  },

  props: {
    selected: {
      type: Number,
      required: true,
    },

    showEvery: {
      type: Boolean,
      default: false,
    },

    short: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selectedChanged'],
}
</script>

<style lang="scss" scoped>
.org-select {
  .custom-select {
    height: 40px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;

    .org-name {
      max-width: 250px;
    }

    .open-icon {
      position: absolute;
      right: 11px;
      top: 11px;
      color: rgb(var(--v-theme-dark));
      opacity: var(--v-medium-emphasis-opacity);
    }

    .select-panel {
      width: 300px;
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .select-panel .option {
      height: 40px;
      padding: 8px;
      background-color: white !important;
      box-shadow: 0 0 10px 100px white inset;
      color: black !important;
    }
  }
}
</style>
