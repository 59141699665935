<template>
  <div class="resources-table">
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <TableActions
          ref="tableActions"
          type="resources"
          :number-of-selected="selected.length"
          :delete-condition="movingResources.length === 0"
          :edit-condition="movingResources.length === 0"
          @edit-click="handleEditClick"
          @delete-click="handleDelete"
          @filter-change="(filter) => trimmedFilter = filter === '' ? null : filter"
          @filter-enter="handleFilterEnter"
        />
        <div class="resources-buttons">
          <DropDown
            v-if="movingResources.length === 0 && selected.length === 0"
            icon="fas fa-plus"
            :label="$t('datatable.header.add')"
            :items="createResourcesItems"
          />
        </div>
        <div
          v-if="movingResources.length === 0 && selected.length > 0"
          class="resources-buttons"
        >
          <v-tooltip top>
            <template #activator="{ props }">
              <div v-bind="props">
                <v-btn
                  color="primary"
                  @click="handleMoveClick"
                  rounded
                >
                  <v-icon>
                    fas fa-file-export
                  </v-icon>
                </v-btn>
              </div>
            </template>
            {{ $t('workflows.resources.move') }}
          </v-tooltip>
        </div>
        <div
          v-if="movingResources.length > 0"
          class="resources-buttons"
        >
          <v-btn
            color="primary"
            @click="moveResources"
            rounded
          >
            <v-icon start>
              fas fa-file-import
            </v-icon>
            {{ $t('workflows.resources.move_here') }}
          </v-btn>
          <v-btn
            class="left-gap-sm"
            color="primary"
            variant="outlined"
            @click="movingResources = []"
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
      </div>
      <FolderBreadcrumbs
        style="width: 40%"
        :path="foldersPath"
        @folder-click="openFolderFromBreadcrumb"
      />
    </div>
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedResources.length"
      :total="totalResources"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-row class="flex-nowrap table-row">
          <v-col cols="auto">
            <v-checkbox
              v-model="allSelected"
              class="mt-0"
              :disabled="movingResources.length > 0"
              @change="toggleSelectAll"
              hide-details
            />
          </v-col>
          <v-col cols="4">
            {{ $t('name') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.size') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.addDate') }}
          </v-col>
          <v-col cols="2">
            {{ $t('workflows.updated_at') }}
          </v-col>
          <v-col
            cols="1"
            class="d-flex align-center justify-end"
          >
            {{ $t('snippet') }}
          </v-col>
        </v-row>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedResources"
            :key="item.id"
            class="table-row fade-in table-row-height flex-nowrap"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="mt-0"
                :disabled="movingResources.length > 0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col cols="4">
              <v-icon
                v-if="isFolder(item)"
                class="mr-2"
                color="primary"
                size="22"
              >
                fas fa-folder
              </v-icon>
              <v-icon
                v-else
                class="mr-1"
                color="primary"
                size="18"
              >
                {{ getExtensionIcon(getExtension(item.name)) }}
              </v-icon>
              <ItemName
                v-if="isFolder(item)"
                :key="`folder-${item.id}`"
                style="max-width: calc(100% - 21px);"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editing === `folder-${item.id}`"
                :validator="isResourceNameValid"
                :hint="`${$t('forms.folder_name_hint')} ${$t('forms.folder_unique_hint')}`"
                @save-file-name="(id, newName) => saveName(id, newName, 'folder')"
                @name-click="openFolderFromTable(item)"
              />
              <ItemName
                v-else
                :key="`file-${item.id}`"
                style="max-width: calc(100% - 21px);"
                type="file"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editing === `file-${item.id}`"
                :clickable="false"
                @save-file-name="(id, newName) => saveName(id, newName, 'file')"
              />
            </v-col>
            <v-col cols="2">
              <div v-if="Object.hasOwn(item, 'size')">
                {{ formatSize(item.size) }}
              </div>
            </v-col>

            <v-col cols="2">
              <small
                v-if="Object.hasOwn(item, 'created_at')"
                class="gray--text"
              >
                {{ formatDate(item.created_at) }}
              </small>
              <small
                v-if="Object.hasOwn(item, 'uploaded_at')"
                class="gray--text"
              >
                {{ formatDate(item.uploaded_at) }}
              </small>
            </v-col>
            <v-col cols="2">
              <small
                v-if="item.updated_at"
                class="gray--text"
              >
                {{ formatDate(item.updated_at) }}
              </small>
            </v-col>
            <v-col
              cols="1"
              class="d-flex align-center justify-end"
            >
              <v-tooltip
                v-if="!isFolder(item)"
                color="#423F4F"
                right
              >
                <template #activator="{ props }">
                  <v-btn
                    class="small-button"
                    color="primary"
                    v-bind="props"
                    @click="handleSnippet(item.id)"
                  >
                    <v-icon size="16">
                      fas fa-code
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color: white">
                  {{ $t('docTypes.get_url') }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>

    <CreateFolderDialog
      v-model="createFolderDialog"
      @confirm="createFolder"
    />
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('workflows.resources.delete')"
      :message="$t('workflows.resources.delete_confirmation')"
      @confirm="deleteResources"
    />
    <SnippetDialog
      v-model="showSnippet"
      :title="$t('workflows.resources.snippet_title')"
      :snippet-text="snippet"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { displaySnackbarSuccess, displaySnackbarWarning, displaySnackbarError } from '@/utils/SnackbarUtils';
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import FolderBreadcrumbs from '@/components/common/elements/Files/FolderBreadcrumbs';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import CreateFolderDialog from "@/components/extract/elements/Workflows/CreateFolderDialog";
import SnippetDialog from '@/components/common/elements/General/SnippetDialog';
import DropDown from '@/components/common/elements/General/DropDown.vue';

import format_mixin from '@/mixins/format.js';
import { WorkflowResourcesAPI } from '@/API/workflows/WorkflowResourcesAPI';
import { getExtension, getExtensionIcon } from '@/utils/FileUtils';
import { isResourceNameValid } from '@/utils/FormValidation';

export default {
  name: 'FilesTable',

  mixins: [format_mixin],

  components: {
    ConfirmDialog,
    ItemName,
    TableActions,
    TableWithFooter,
    FolderBreadcrumbs,
    CreateFolderDialog,
    SnippetDialog,
    DropDown,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      foldersPath: [{id: 0, name: 'resources'}],
      createResourcesItems: [
        {
          label: this.$t('workflows.resources.file'),
          icon: 'fas fa-file',
          action: () => {
            this.handleCreateClick('file');
          },
        },
        {
          label: this.$t('workflows.resources.folder'),
          icon: 'fas fa-folder',
          action: () => {
            this.handleCreateClick('folder');
          },
        },
      ],
      paginatedResources: [],
      movingResources: [],
      movingSuccess: 0,
      movingErrors: 0,
      totalResources: 0,
      trimmedFilter: null,
      deleteDialog: false,
      createFolderDialog: false,
      showSnippet: false,
      allSelected: false,
      loading: false,
      editing: null,
      currentFolder: null,
      snippet: '',
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalResources / this.itemsPerPage);
    },

    selected: {
      get() {
        if (this.paginatedResources.length > 0) {
          return this.paginatedResources.filter(f => f.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
    },

    currentPage() {
      this.getResources();
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
      },
      300),

    currentFolder: {
      handler(newVal) {
        this.$emit('changeFolder', newVal);
      },
      deep: true,
    },
  },

  mounted() {
    this.getResources();
  },

  methods: {
    getExtension, getExtensionIcon, isResourceNameValid,

    handleFilterEnter() {
      if (this.paginatedResources.length > 0 && this.isFolder(this.paginatedResources[0])) {
        this.openFolderFromTable(this.paginatedResources[0]);
      }
    },

    handleMoveClick() {
      this.movingResources = [...this.selected];
      displaySnackbarSuccess(this.$t('workflows.resources.moving_resources_selected'));
    },

    handleEditClick() {
      const editing = this.selected[0];
      const type = this.isFolder(editing) ? 'folder' : 'file';
      this.editing = `${type}-${editing.id}`;
    },

    async handleSnippet(id) {
      this.snippet = '';
      try {
        const fileDetails = await WorkflowResourcesAPI.getFile(id);
        this.snippet = `with open('./resources/${fileDetails.path}', "rb") as file:`;
        this.showSnippet = true;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async createFolder(name) {
      try {
        await WorkflowResourcesAPI.createFolder({ name: name, parent_id: this.currentFolder });
        this.createFolderDialog = false;
        this.getResources();
        displaySnackbarSuccess(this.$t('workflows.resources.folder_created'));
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    openFolderFromBreadcrumb(folderIndex) {
      this.foldersPath = this.foldersPath.slice(0, folderIndex + 1);
      this.openFolder(this.foldersPath[folderIndex]);
    },

    async moveResource(resource) {
      try {
        if (this.isFolder(resource)) {
          await WorkflowResourcesAPI.moveFolder(resource.id, this.currentFolder);
        } else {
          await WorkflowResourcesAPI.moveFile(resource.id, this.currentFolder);
        }
        this.movingSuccess++;
      } catch (error) {
        this.movingErrors++;
        return
      }
    },

    async moveResources() {
      const total = this.movingResources.length;
      for (let i = 0; i < total; i++) {
        await this.moveResource(this.movingResources[i]);
      }
      this.movingResources = [];
      await this.getResources();
      this.allSelected = false;
      if (this.movingSuccess > 0 && this.movingErrors === 0) {
        displaySnackbarSuccess(this.$t('workflows.resources.moved'));
      } else if (this.movingSuccess > 0 && this.movingErrors > 0) {
        displaySnackbarWarning(this.$t('workflows.resources.move_warning'));
      } else if (this.movingSuccess === 0 && this.movingErrors > 0) {
        displaySnackbarError(this.$t('workflows.resources.move_error'));
      }
      this.movingSuccess = 0;
      this.movingErrors = 0;
    },

    async getPathDetails(folder) {
      const originalId = folder.id;
      const path = [folder];
      while (folder.parent_id) {
        folder = await WorkflowResourcesAPI.getFolder(folder.parent_id);
        path.unshift({id: folder.id, name: folder.name});
      }
      path.unshift({id: 0, name: 'resources'});
      if (this.currentFolder === originalId) {
        this.foldersPath = path;
      }
    },

    async openFolderFromTable(folder) {
      if (this.trimmedFilter) {
        const folderWithPath = await WorkflowResourcesAPI.getFolder(folder.id);
        this.foldersPath = [
          {name: 'resources', id: 0},
          ...folderWithPath.path.split('/').map(name => ({ id: -1, name }))
        ];
        this.getPathDetails(folder);
        this.currentFolder = folder.id;
        this.currentPage = 1;
        this.$refs.tableActions.filter = '';
        this.trimmedFilter = null;
      } else {
        this.foldersPath.push(folder);
        this.openFolder(folder);
      }
    },

    openFolder(folder) {
      this.currentFolder = folder.id;
      this.resetCurrentPage();
    },

    handleCreateClick(type) {
      if (type === 'file') {
        this.$emit('selectFiles');
      } else if (type === 'folder') {
        this.createFolderDialog = true;
      }
    },

    async getResources(folderId = this.currentFolder) {
      this.loading = true;
      let paginatedFiles = [];
      let paginatedFolders = [];
      let totalFolders = 0;
      let totalFiles = 0;
      try {
        const foldersOffset = (this.currentPage - 1) * this.itemsPerPage;
        let foldersResponse = await WorkflowResourcesAPI.getResourceFolders({
          offset: foldersOffset,
          limit: this.itemsPerPage,
          parent_id: folderId,
          name_filter: this.trimmedFilter,
        });
        paginatedFolders = foldersResponse.data;
        totalFolders = parseInt(foldersResponse.headers['x-total-count'], 10);
        if (paginatedFolders.length < this.itemsPerPage) {
          const filesOffset = Math.max(0, foldersOffset - totalFolders);
          let filesResponse = await WorkflowResourcesAPI.getResourceFiles({
            offset: filesOffset,
            limit: this.itemsPerPage - paginatedFolders.length,
            folder_id: folderId,
            name_filter: this.trimmedFilter,
          });
          paginatedFiles = filesResponse.data;
          totalFiles = parseInt(filesResponse.headers['x-total-count'], 10);
        }
        this.paginatedResources = [...paginatedFolders, ...paginatedFiles];
        this.totalResources = totalFolders + totalFiles;
        if (this.movingResources.length > 0) {
          this.checkMovingCheckboxes();
        }
        if (this.paginatedResources.length > 0) {
          this.handleSelect();
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    isFolder(item) {
      return Object.hasOwn(item, 'parent_id');
    },

    checkMovingCheckboxes() {
      this.paginatedResources.forEach(item => {
        this.movingResources.forEach(movingResource => {
          if (item.id === movingResource.id && (this.isFolder(item) === this.isFolder(movingResource))) {
            item.selected = true;
          }
        });
      });
    },

    handleSelect() {
      this.allSelected = this.paginatedResources.every(f => f.selected);
    },

    toggleSelectAll() {
      this.paginatedResources.forEach(item => {
        item.selected = this.allSelected;
      });
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.getResources();
    },

    handleDelete() {
      this.deleteDialog = true;
    },

    async deleteFolders(foldersIds) {
      try {
        await WorkflowResourcesAPI.deleteFolders(foldersIds);
        return true;
      } catch (error) {
        return false;
      }
    },

    async deleteFiles(filesIds) {
      try {
        await WorkflowResourcesAPI.deleteFiles(filesIds);
        return true;
      } catch (error) {
        return false;
      }
    },

    async deleteFilesAndFolders(resources) {
      let foldersSuccess = false;
      let filesSuccess = false;
      const foldersIds = resources.filter(resource => this.isFolder(resource)).map(resource => resource.id);
      const filesIds = resources.filter(resource => !this.isFolder(resource)).map(resource => resource.id);
      if (filesIds.length > 0) {
        filesSuccess = await this.deleteFiles(filesIds);
      } else {
        filesSuccess = true;
      }
      if (foldersIds.length > 0) {
        foldersSuccess = await this.deleteFolders(foldersIds);
      } else {
        foldersSuccess = true;
      }
      if (filesSuccess && foldersSuccess) {
        displaySnackbarSuccess(this.$t('workflows.resources.deleted'));
      } else {
        displaySnackbarError(this.$t('workflows.resources.delete_error'));
      }
    },

    async deleteResources() {
      this.deleteDialog = false;
      await this.deleteFilesAndFolders(this.selected);
      const pageBeforeDelete = this.currentPage;
      this.currentPage = 1;
      await this.getResources();
      this.currentPage = Math.max(1, Math.min(pageBeforeDelete, this.totalPages));
      if (this.currentPage !== 1) {
        this.getResources();
      }
      this.allSelected = false;
    },

    async saveName(id, newName, type) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          let response;
          if (type === 'folder') {
            response = await WorkflowResourcesAPI.renameFolder(id, newName);
          } else if (type === 'file') {
            response = await WorkflowResourcesAPI.renameFile(id, newName);
          }
          this.paginatedResources = this.paginatedResources.map(resource => {
            if (resource.id === id) {
              return response.data;
            }
            return resource;
          });
          displaySnackbarSuccess(this.$t(`workflows.resources.${type}_renamed`));
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },
  },

  emits: ['selectFiles', 'validate', 'changeFolder'],
}
</script>

<style lang="scss" scoped>
.resources-buttons {
  margin-top: 33px;
}
</style>
