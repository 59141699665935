<template>
  <div
    class="doc-type-view page-padding"
    :class="{
      'doc-type-view__full': isFullScreen,
      'py-4': !isFullScreen,
    }"
  >
    <div v-if="!isFullScreen">
      <v-skeleton-loader
        v-if="loading"
        type="heading"
        width="300px"
        class="mt-5"
        style="margin-left: -10px"
      />
      <v-skeleton-loader
        v-if="loading"
        type="text"
        width="200px"
        class="mb-8"
        style="margin-left: -10px; margin-top: -5px"
      />
      <template v-else>
        <div
          class="d-flex align-center"
          style="margin-top: 30px;"
        >
          <HeaderName
            style="max-width: 600px"
            :item="type"
            :editable="!type.is_locked"
            @save="saveTypeName"
          />
          <v-tooltip
            v-if="type.is_locked"
            right
          >
            <template #activator="{ props }">
              <v-icon
                class="ml-3 mb-1"
                color="primary"
                size="16"
                v-bind="props"
              >
                fas fa-lock
              </v-icon>
            </template>
            {{ $t('docTypes.doctype_is_locked') }}
          </v-tooltip>
        </div>
        <ItemDescription
          style="max-width: 600px"
          :item="type"
          :editable="!type.is_locked"
          @save="saveTypeDescription"
        />
      </template>
    </div>
    <PageTabs
      v-model="activeTab"
      :is-full-screen="isFullScreen"
      :tabs="tabItems"
      :loading="loading"
      :type="type"
      @save-name="saveTypeName"
    />
    <div :class="{ 'mt-8': !isFullScreen }">
      <div v-if="activeTab === 'documents'">
        <DocsView
          v-if="validatingFile.id === -1 && validatingFile.index === -1"
          ref="docsView"
          :doc-type-loading="loading"
          @validate="validateFile"
        />
        <ValidatorView
          v-else
          ref="validatorWindow"
          :validating="validatingFile"
          :document-type="type"
          @add-subgroup="addSubgroup"
          @update-verified-values="updateVerifiedValues"
          @update-verified-files="updateVerifiedFiles"
          @add-file="addFile"
          @get-label-groups="getLabelGroups"
          @got-extractors="windowKey++"
          @back-click="resetValidatingFile"
        />
      </div>
      <ExtractorsView
        v-else-if="$route.params.tab === 'extractors'"
        :data-points="dataPoints"
        :label-groups="labelGroups"
        :doc-type-loading="extractorsLoading"
        :lock-actions="type.is_locked"
        @toggle-select-all="toggleSelectAll"
        @update-data-points="updateDataPoints"
        @update-label-groups="updateLabelGroups"
        @save-d-p-name="saveDPName"
        @save-group-name="getLabelGroups"
        @get-data-points="getDataPoints"
        @get-label-groups="getLabelGroups"
      />
      <ReviewView
        v-else-if="$route.params.tab === 'review'"
        :data-points="dataPoints"
        :label-groups="labelGroups"
        :doc-type-loading="extractorsLoading"
        :lock-actions="type.is_locked"
        :config="typeConfig"
        @update-data-points="updateDataPoints"
        @update-label-groups="updateLabelGroups"
        @save-d-p-name="saveDPName"
        @save-group-name="getLabelGroups"
        @get-data-points="getDataPoints"
        @get-label-groups="getLabelGroups"
        @save="saveTypeConfig"
        @refresh="c => typeConfig = c"
      />
      <BusinessRulesView
        v-else-if="$route.params.tab === 'rules'"
        :extraction-agent="type"
      />
      <DocTypeConfig
        v-else-if="$route.params.tab === 'configuration'"
        :config="typeConfig"
        @refresh="c => typeConfig = c"
        @save="saveTypeConfig"
        @update-lock="(val) => type.is_locked = val"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { http } from "@/plugins/axios";
import { FileAPI } from '@/API/extract/FileAPI';

import DocsView from "@/components/extract/views/Documents/DocsView";
import DocTypeConfig from "@/components/extract/views/DocType/DocTypeConfig";
import ReviewView from "@/components/extract/views/DocType/ReviewView";
import ExtractorsView from "@/components/extract/views/Extractors/ExtractorsView";
import HeaderName from '@/components/common/elements/General/HeaderName';
import ItemDescription from '@/components/common/elements/General/ItemDescription';
import PageTabs from '@/components/common/elements/General/PageTabs';
import ValidatorView from "@/components/extract/views/Documents/ValidatorView";
import BusinessRulesView from "@/components/extract/views/BusinessRules/BusinessRulesView";

import doc_type_mixin from "@/mixins/document_type.js";
import file_mixin from "@/mixins/file.js";
import model_mixin from "@/mixins/model.js";
import validation_mixin from "@/mixins/validation.js";
import { DataPointAPI } from "@/API/extract/DataPointAPI";

export default {
  name: "DocTypeView",

  mixins: [
    doc_type_mixin,
    file_mixin,
    model_mixin,
    validation_mixin,
  ],

  components: {
    DocTypeConfig,
    DocsView,
    HeaderName,
    ItemDescription,
    ExtractorsView,
    PageTabs,
    ValidatorView,
    BusinessRulesView,
    ReviewView,
  },

  data() {
    return {
      dragging: false,
      inside: false,
      loading: false,
      loadingCount: false,
      extractorsLoading: false,
      windowKey: Math.random(),
      invalidStatus: ["uploaded", "ingested", "error"],
      verified: [],
      activeTab: "",
      validatingFile: {
        id: -1,
        index: -1,
      },
    };
  },

  computed: {
    totalRulesDisplay: {
      get() {
        return this.$store.getters.totalRulesDisplay;
      },
      set(total) {
        this.$store.commit("setTotalRulesDisplay", total);
      }
    },

    routeTab() {
      return this.$route.params.tab;
    },

    isFullScreen() {
      return this.$store.getters.fullScreen;
    },

    userRole() {
      return this.user ? this.user.role : "";
    },

    typeConfig: {
      get() {
        return {
          is_locked: this.type.is_locked,
          user_correction: this.type.user_correction,
          rules_prevalidation: this.type.rules_prevalidation,
          post_processing_deletion: this.type.post_processing_deletion,
          image_ocr: this.type.image_ocr,
          force_ocr: this.type.force_ocr,
          ocr_model: this.type.ocr_model,
          seamless_validation_token: this.type.seamless_validation_token || false,
          seamless_validation_token_duration: this.type.seamless_validation_token_duration || 1,
          only_review_pages_with_pending: this.type.only_review_pages_with_pending,
          allow_anonymous_copy_to_dataset: this.type.allow_anonymous_copy_to_dataset,
          use_deskew: this.type.use_deskew,
          signature: this.type.signature,
          qr_code: this.type.qr_code,
          qr_code_pages: this.type.qr_code_pages,
          organized: this.type.organized,
          retry_indexing: this.type.retry_indexing,
          allowed_basic_users: this.type.allowed_basic_users,
          custom_reading_order: this.type.custom_reading_order,
          straighten_pages: this.type.straighten_pages,
          languages: this.type.languages,
          prompt_prefix: this.type.prompt_prefix,
          prompt: this.type.prompt,
          experimental_prompt: this.type.experimental_prompt,
          use_text: this.type.use_text,
          callback_values_as_dict: this.type.callback_values_as_dict,
          line_aggregator_overlap_threshold: this.type.line_aggregator_overlap_threshold,
          column_aggregator_overlap_threshold: this.type.column_aggregator_overlap_threshold,
        };
      },
      set(config) {
        this.type.allowed_basic_users = config.allowed_basic_users;
        this.type = {...this.type};
      },
    },

    type: {
      get() {
        if (this.$store.getters.documentTypes[this.$route.params.id]) {
          return this.$store.getters.documentTypes[this.$route.params.id];
        }
        return { name: "" };
      },
      set(newType) {
        this.$store.commit("setDocumentType", newType);
      },
    },

    dataPoints: {
      cache: false,
      get: function () {
        if (this.$store.getters.dataPoints[this.$route.params.id]) {
          let dataPoints =
            this.$store.getters.dataPoints[this.$route.params.id];
          return dataPoints;
        }
        return [];
      },
      set: function (newDataPoints) {
        this.$store.commit("setDataPoints", {
          typeId: this.$route.params.id,
          dataPoints: newDataPoints,
        });
      },
    },

    labelGroups: {
      cache: false,
      get: function () {
        if (this.$store.getters.labelGroups[this.$route.params.id]) {
          return this.$store.getters.labelGroups[this.$route.params.id];
        }
        return [];
      },
      set: function (newGroups) {
        this.$store.commit("setLabelGroups", {
          typeId: this.$route.params.id,
          labelGroups: newGroups,
        });
      },
    },

    selectedFile: {
      get() {
        return this.$store.getters.validatorFile;
      },
      set(id) {
        this.$store.commit("setValidatorFile", id);
      },
    },

    values: {
      get: function () {
        return this.$store.getters.values;
      },
      set: function (values) {
        this.$store.commit("setAllValues", values);
      },
    },

    groupValues: {
      get: function () {
        return this.$store.getters.groupValues;
      },
      set: function (values) {
        this.$store.commit("setAllGroupValues", values);
      },
    },

    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    extractorsNum() {
      this.windowKey;
      return this.dataPoints.length + this.labelGroups.length;
    },

    tabItems() {
      if (this.userRole === "sysadmin") {
        return [
          {
            title: this.$t("docTypeTabs.dataPoints"),
            bigNumber: this.extractorsNum,
            name: "extractors",
          },
          {
            title: this.$t("menu.configuration.title"),
            bigNumber: "",
            name: "configuration",
          },
        ];
      } else if (this.userRole === "orgadmin") {
        return [
          {
            title: this.$t("docTypeTabs.validation"),
            bigNumber: this.totalFilesDisplay,
            name: "documents",
          },
          {
            title: this.$t("docTypeTabs.dataPoints"),
            bigNumber: this.extractorsNum,
            name: "extractors",
          },
          {
            title: this.$t("docTypeTabs.business_rules"),
            bigNumber: this.totalRulesDisplay,
            name: "rules",
          },
          {
            title: this.$t("menu.configuration_review"),
            bigNumber: "",
            name: "review",
          },
          {
            title: this.$t("menu.configuration.title"),
            bigNumber: "",
            name: "configuration",
          },
        ];
      }
      return [];
    },
  },

  watch: {
    validatingFile() {
      if (this.activeTab === 'documents') {
        this.handleDocumentsFullScreen();
      }
    },

    routeTab(tab) {
      if (this.activeTab !== tab) {
        this.activeTab = tab;
      }
    },

    activeTab(tab) {
      if (tab === 'validation') {
        // Redirect if user types /validation in address bar
        this.validateFirstFile();
      } else {
        this.$router.push({
          name: "DocType",
          params: {
            id: this.type.id,
            tab: this.activeTab,
          },
        });
      }
      this.getVerified();
      if (tab === "documents") {
        this.handleDocumentsFullScreen();
      } else {
        this.$store.commit("setFullScreen", false);
      }
    },
  },

  created() {
    this.extractorsLoading = true;
  },

  async mounted() {
    if (!this.type || this.type.id != this.$route.params.id) {
      this.loading = true;
      this.totalFilesDisplay = 0;
      this.totalRulesDisplay = 0;
      await this.getDocType();
    }
    if (this.$route.params.tab) {
      this.activeTab = this.$route.params.tab;
    } else if (this.userRole === "sysadmin") {
      this.activeTab = "extractors";
    } else {
      this.activeTab = "documents";
    }
    if (!['documents'].includes(this.activeTab)) {
      await this.getFiles();
    }
    await Promise.all([this.getDataPoints(), this.getLabelGroups()]);
    this.extractorsLoading = false;
    if (this.activeTab != 'rules') {
      this.getRuleCount();
    }
    this.$store.commit("resetValues");
    this.loading = false;
    this.windowKey = Math.random();
  },

  methods: {
    isEmpty,

    validateFirstFile() {
      this.validatingFile = {
        id: -1,
        index: 0,
      };
      this.activeTab = 'documents';
    },

    handleDocumentsFullScreen() {
      if (this.validatingFile.id !== -1 || this.validatingFile.index !== -1) {
        this.$store.commit("setFullScreen", true);
        this.$store.commit("setMenu", false);
      } else {
        this.$store.commit("setFullScreen", false);
      }
    },

    async getRuleCount() {
      this.loadingCount = true;
      try {
        const response = await http.get(
          `system_2/business_rule/?document_type_id=${this.$route.params.id}`,
          {
            params: {
              limit: 1,
              offset: 0,
            },
          }
        );
        this.totalRulesDisplay = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loadingCount = false;
      }
    },

    updateLabelGroups(groups) {
      this.labelGroups = groups;
      this.windowKey++;
    },

    addFile() {
      this.activeTab = 'documents';
      setTimeout(() => {
        this.$refs.docsView.selectFiles();
      }, 100);
    },

    async saveDPName() {
      await this.getDocType();
      if (this.selectedFile > 0) {
        await this.getValues(this.selectedFile, true);
      }
    },

    validateFile(file) {
      this.validatingFile = file;
    },

    async saveTypeConfig() {
      try {
        this.$store.commit("setLoadingScreen", true);
        if (this.typeConfig.force_ocr === false) {
          this.typeConfig.straighten_pages = false;
          this.typeConfig.use_deskew = false;
        }
        await http.put(
          `system_2/document_type/${this.$route.params.id}/`,
          {
            ...this.type,
            ...this.typeConfig,
          }
        );
        const type = { ...this.type, ...this.typeConfig };
        this.type = { ...type };
        this.$store.commit(
          "setSuccessMessage",
          this.$t("settings.update_success")
        );
        this.$store.commit("setSuccessSnackbar", true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async saveTypeName(newName) {
      if (newName !== "") {
        try {
          this.$store.commit("setLoadingScreen", true);
          await http.put(`system_2/document_type/${this.$route.params.id}`, {
            name: newName,
          });
          const type = { ...this.type, name: newName };
          this.type = { ...type };
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit("setLoadingScreen", false);
        }
      }
    },

    async saveTypeDescription(newDescription) {
      try {
        this.$store.commit("setLoadingScreen", true);
        await http.put(`system_2/document_type/${this.$route.params.id}`, {
          description: newDescription || "",
        });
        const type = { ...this.type, description: newDescription };
        this.type = { ...type };
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getFiles(offset = 0) {
      if (this.user.role === "orgadmin") {
        try {
          this.loadingCount = true;
          const response = await FileAPI.get(this.$route.params.id, offset, 20);
          if (offset > 0) {
            this.files = [...this.files, ...response.data];
          } else {
            this.files = response.data;
          }
          this.totalFiles = parseInt(response.headers['x-total-count'], 10);
          this.totalFilesDisplay = this.totalFiles;
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.loadingCount = false;
        }
      }
    },

    resetValidatingFile() {
      this.validatingFile = {
        id: -1,
        index: -1,
      };
    },

    async getValues(fileId, force = false) {
      if (force || !this.values[fileId]) {
        try {
          const { data } = await http.get(`files/${fileId}/values`);
          this.$store.commit("setValues", {
            fileId,
            values: data,
          });
          this.values = { ...this.values };
        } catch (error) {
          console.log(error);
        }
      }
    },

    toggleSelectAll(list, currentPage, allSelected) {
      this[list].forEach((item) => {
        if (currentPage.includes(item)) {
          item.selected = allSelected;
        }
      });
    },

    updateDataPoints(dpList) {
      this.dataPoints = dpList;
      this.windowKey++;
      if (this.selectedFile > 0) {
        this.getValues(this.selectedFile, true);
      }
    },

    updateVerifiedFiles(fileId) {
      const verifiedFile = this.files.find((file) => file.id === fileId);
      if (verifiedFile && verifiedFile.status === "to_verify") {
        this.type.nb_verified_files += 1;
        verifiedFile.status = "verified";
      }
    },

    updateVerifiedValues(fileId, dataPointId) {
      if (!this.verified.some((value) => value.fileId === fileId)) {
        this.verified.push({ fileId, dataPointId });
        this.updateVerifiedFiles(fileId);
      }
    },

    getVerified() {
      if (this.verified.length > 0) {
        this.$store.commit("setLoadingScreen", true);
        const dpRequests = this.verified.map(async (dp) => {
          try {
            const { data } = await DataPointAPI.get(dp.dataPointId);
            return data;
          } catch (error) {
            console.log(error);
            return null;
          }
        });

        const valueRequests = this.verified.map(async (dp) => {
          try {
            const { data } = await http.get(
              `files/${dp.fileId}/values/?data_point_id=${dp.dataPointId}`
            );
            return { fileId: dp.fileId, value: data };
          } catch (error) {
            console.log(error);
            return null;
          }
        });

        Promise.all(dpRequests).then((dataPoints) => {
          const currentList = [...this.dataPoints];
          dataPoints.forEach((dp) => {
            const index = this.dataPoints.indexOf(
              this.dataPoints.find((el) => el.id === dp.id)
            );
            if (dp && index > -1) {
              currentList[index] = { ...dp };
            }
          });
          this.dataPoints = [...currentList];
        });

        Promise.all(valueRequests).then((values) => {
          values.forEach((value) => {
            if (value) {
              const dpIndex = this.values[value.fileId].indexOf(
                this.values[value.fileId].find(
                  (dp) => dp.data_point_id === value.value.data_point_id
                )
              );
              this.values[value.fileId][dpIndex] = value.value;
            }
          });
        });
        this.verified = [];
        this.$store.commit("setLoadingScreen", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .doc-type-view {
    height: fit-content;
    &__full {
      padding: 0;
    }
  }
</style>
