<template>
  <div class="training-models-table">
    <TableWithFooter
      no-results-message="models.no_training"
      :loading="loading"
      :paginated-items-length="paginatedVersions.length"
      :total="totalTrainingModelsDisplay"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="9">
          {{ $t('models.in_training') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <div
            v-for="(item, index) in paginatedVersions"
            :key="item.id"
          >
            <v-row
              class="table-row fade-in table-row-height model-name-row"
            >
              <v-col cols="9">
                <ItemName
                  style="margin-top: 0px"
                  :badge="`v${item.version}`"
                  :item="{ name: item.name }"
                  :clickable="false"
                />
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="primary"
                  class="small-button"
                  @click="handleDeleteButton(item.id, item.version)"
                  rounded
                >
                  <v-icon
                    size="16"
                    start
                  >
                    fas fa-times
                  </v-icon>
                  {{ $t('cancel') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="table-row fade-in table-row-height model-progress-row"
            >
              <v-col cols="12">
                <v-progress-linear
                  v-if="paginatedVersionsStatus[index] && paginatedVersionsStatus[index].status === 'in_progress'"
                  bg-color="primary-lighten2"
                  color="primary"
                  height="10"
                  :model-value="paginatedVersionsStatus[index].progress"
                  striped
                />
                <div
                  v-else
                >
                  <div
                    v-if="paginatedVersionsStatus[index] && paginatedVersionsStatus[index].status == 'queued'"
                  >
                    <i>{{ $t('models.status.queue_position') }} {{ paginatedVersionsStatus[index].queue_position }}</i>
                  </div>
                  <div
                    v-else
                  >
                    <i>{{ paginatedVersionsStatus[index] ? $t(`models.status.${paginatedVersionsStatus[index].status}`) : $t('models.status.failed') }}</i>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </template>
    </TableWithFooter>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('models.delete_title_training')"
      :message="$t('models.confirm_delete_training')"
      @confirm="deleteVersion"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { ModelAPI } from '@/API/extract/ModelAPI';
import model_mixin from '@/mixins/model.js';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'TrainingModelsTable',

  mixins: [
    model_mixin,
  ],

  components: {
    ItemName,
    TableWithFooter,
    ConfirmDialog,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      deleteDialog: false,
      modelToDelete: -1,
      versionToDelete: -1,
      loading: false,
      statusCheck: null,
      paginatedVersions: [],
      paginatedVersionsStatus: [],
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalTrainingModelsDisplay / this.itemsPerPage);
    },
  },

  watch: {
    deleteDialog(on) {
      if (on) {
        clearInterval(this.statusCheck);
      } else {
        this.modelToDelete = -1;
        this.versionToDelete = -1
        this.modelsCountCheck();
      }
    },

    itemsPerPage() {
      this.currentPage = 1;
      this.modelsCountCheck();
    },

    currentPage() {
      this.modelsCountCheck();
    },

    paginatedVersions(newValue) {
      newValue.forEach(async (version, index) => {
        try {
          const response = await ModelAPI.get_progress(`${version.id}`, `${version.version}`);
          const data = await response.data;
          this.paginatedVersionsStatus[index] = {
            progress: data.progress,
            status: data.status,
            queue_position: data.queue_position,
          };
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      });
    }
  },

  mounted() {
    this.modelsCountCheck();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    refreshModels() {
      // Changing this setup might result in models not loading after training
      setTimeout(() => {
        this.modelsCountCheck();
      }, 10);
      this.modelsCountCheck();
    },

    modelsCountCheck() {
      this.getModelsCount('active');
      this.getPendingModels();
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (this.totalTrainingModelsDisplay === 0) {
          clearInterval(this.statusCheck);
        } else {
          this.getModelsCount('active');
          this.getPendingModels(
            (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage, false
          );
        }
      }, 10000);
    },

    async getModelsCount(status) {
      try {
        let includeError = true;
        if (status === 'pending') {
          includeError = false;
        }
        const response = await ModelAPI.get(
          0, 1, null, status, null, includeError,
        );
        this[`total${this.statusMapping[status]}ModelsDisplay`] = parseInt(
          response.headers['x-total-count'], 10
        );
      } catch (error) {
        clearInterval(this.statusCheck);
      } finally {
        this.loading = false;
      }
    },

    async getPendingModels(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage,
      loading = true,
    ) {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await ModelAPI.get(
          offset, limit, this.trimmedFilter || '', 'pending', true, false,
        );
        const modelVersions = [];
        response.data.forEach((model) => {
          model.versions.forEach((version) => {
            modelVersions.push({
              id: model.id,
              name: model.name,
              version: version.version,
              versionId: version.id
            });
          });
        });
        modelVersions.sort((a, b) => a.versionId - b.versionId);
        this.paginatedVersions = modelVersions;
        this.totalTrainingModelsDisplay = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        clearInterval(this.statusCheck);
      } finally {
        this.loading = false;
      }
    },

    async deleteVersion() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.delete(`model/${this.modelToDelete}/version/${this.versionToDelete}`);
        this.finishDeletion();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async finishDeletion() {
      this.deleteDialog = false;
      this.totalTrainingModelsDisplay--;
      await this.getPendingModels();
      this.currentPage = 1;
      this.modelToDelete = -1;
      this.versionToDelete = -1;
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton(id, version) {
      this.deleteDialog = true;
      this.modelToDelete = id;
      this.versionToDelete = version;
    },
  }
}
</script>
<style lang="scss" scoped>

.top-gap-0 {
  margin-top: 0;
}

.model-name-row {
  border-bottom: none !important;
}

.model-progress-row {
  height: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #eee;
  font-size: 95%;
}
</style>
