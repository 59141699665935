<template>
  <div class="task-table">
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="tasks.length"
      :total="totalTasks"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton
            v-model="sortDesc"
            style="margin-top: -5px"
          />
        </v-col>
        <v-col>
          {{ $t('search.tasks.type') }}
        </v-col>
        <v-col>
          {{ $t('status') }}
        </v-col>
        <v-col>
          {{ $t('search.tasks.created_on') }}
        </v-col>
        <v-col>
          {{ $t('search.tasks.ended_on') }}
        </v-col>
        <v-col>
          {{ $t('search.tasks.execution_time') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="task in tasks"
            :key="task.id"
            class="table-row fade-in table-row-height"
            :class="{
              clickable: task.state !== 'PENDING',
            }"
            @click="handleClick(task)"
          >
            <v-col cols="auto">
              <div class="left-gap" />
            </v-col>
            <v-col>
              {{ $t(`search.tasks.${task.type}`) }}
            </v-col>
            <v-col>
              <v-icon
                class="mr-1"
                :style="{
                  'margin-top': task.state === 'SUCCESS' && '-2px' || '0',
                }"
              >
                {{ $options.constants.STATUS_ICON_MAP[task.state] }}
              </v-icon>
              {{ $t(`search.tasks.${task.state}`) }}
            </v-col>
            <v-col>
              <span
                v-if="task.creation_time"
                class="gray--text"
              >
                {{ formatDate(task.creation_time) }}
              </span>
              <span v-else>
                —
              </span>
            </v-col>
            <v-col>
              <span
                v-if="task.end_time"
                class="gray--text"
              >
                {{ formatDate(task.end_time) }}
              </span>
              <span v-else>
                —
              </span>
            </v-col>
            <v-col>
              {{ formatElapsedTime(task.execution_time_in_seconds) }}
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <SearchTaskDetailDialog
      v-model="showDetails"
      :task="clickedTask || {}"
      @close="showDetails = false"
    />
  </div>
</template>

<script>
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import SearchTaskDetailDialog from '@/components/search/elements/Tasks/SearchTaskDetailDialog';

import { SearchTaskAPI } from '@/API/search/SearchTaskAPI';
import { useTableWithFooter } from '@/composables/useTableWithFooter';
import { formatElapsedTime, formatDate } from '@/utils/classes/Formatting';


export default {
  name: 'SearchTasksTable',

  components: {
    TableWithFooter,
    SortButton,
    SearchTaskDetailDialog,
  },

  constants: {
    STATUS_CHECK_DELAY: 3000,
    STATUS_ICON_MAP: {
      PENDING: 'fas fa-spinner fa-pulse',
      SUCCESS: 'mdi mdi-check-circle',
      FAILURE: 'mdi mdi-alert-circle',
    },
    HIDE_DETAILS_DELAY: 250,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`
    );

    return ({
      sortDesc: true,
      tasks: [],
      statusCheck: null,
      loading: false,
      itemsPerPage,
      currentPage,
      totalTasks: 0,
      clickedTask: null,
      showDetails: false,
    });
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalTasks / this.itemsPerPage);
    },
  },

  watch: {
    showDetails(newValue) {
      if (!newValue) {
        // Delays updating the value of 'clickedTask' to prevent errors while
        // the SearchTaskDetailDialog component is still mounted
        setTimeout(
          () => this.clickedTask = null,
          this.$options.constants.HIDE_DETAILS_DELAY
        );
      }
    },

    sortDesc() {
      this.getTasks();
    },

    itemsPerPage() {
      clearInterval(this.statusCheck);
      this.resetCurrentPage();
      this.refresh();
    },

    currentPage() {
      clearInterval(this.statusCheck);
      this.refresh();
    },
  },

  mounted() {
    this.loading = true;
    this.tasks = [];
    this.refresh();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    formatDate,
    formatElapsedTime,
    handleClick(task) {
      if (task.state !== 'PENDING') {
        this.clickedTask = task;
        this.showDetails = true;
      }
    },

    async refresh() {
      await this.getTasks((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
      this.recurrentStatusCheck();
    },

    recurrentStatusCheck() {
      this.statusCheck = setInterval(this.checkStatus, this.$options.constants.STATUS_CHECK_DELAY)
    },

    async checkStatus() {
      if (this.tasks.some(t => t.state === 'PENDING')) {
        await this.getTasks((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },

    async getTasks(offset = 0, limit = this.itemsPerPage) {
      try {
        const [tasks, totalTasks] = await SearchTaskAPI.getTasks(
          offset,
          limit,
          this.sortDesc,
        );
        this.totalTasks = totalTasks;
        this.tasks = tasks;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-row {
  transition: color linear 200ms;
  &:hover {
    color: rgb(var(--v-theme-primary));
  }
}
</style>