import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/health/';

export class ClassifyHealthAPI {
  static get() {
    return http.get(BASE_PATH, { globalErrorHandlingOptOut: true })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
