import _ from 'lodash';

function getDataPointInfo(id, values) {
  const dp = values.find(dp => dp.data_point_id === id);
  return dp && [dp.value.normalized_value, dp.data_point_name] || [null, null];
}

function getExtractionGroupValueSum(groupId, label, groups) {
  const group = groups.find(g => g.group_id === groupId);
  if (!group) {
    return 0;
  }
  const res = group.subgroups.reduce((acc, sg) => {
    return acc + getExtractionGroupValue(parseInt(groupId), label, sg.id, groups) || 0;
  }, 0);
  return res;
}

function getExtractionGroupValue(groupId, label, subgroupId, groupsValues) {
  const group = groupsValues.find(g => g.group_id === groupId);
  if (!group) {
    return null;
  }
  const subgroup = group.subgroups.find(sg => sg.id === subgroupId)
  if (!subgroup) {
    return null;
  }
  const value = subgroup.values.find(v => v.label === label);
  return value && value.normalized_value || null;
}

function getValueBusinessRules(rules, id, extractorType, ruleResults, dataPointValues, groupsValues, subgroupId = null) {
  if (_.isEmpty(ruleResults)) {
    return [];
  }
  return rules.map(br => {
    const extractorRegex = new RegExp(`${extractorType}_${id}`);
    const relevantCodes = br.extractor_codes.filter((c) => extractorRegex.test(c));
    if (relevantCodes.length > 0) {
      let res = br.string;

      br.extractor_codes.forEach(code => {
        let [extType, extractorId, ...label] = code.split('_');
        label = label.join('_');
        if (extType === 'dp') {
          const [value, name] = getDataPointInfo(parseInt(extractorId, 10), dataPointValues);
          res = res.replaceAll(`${code}_name`, name);
          res = res.replaceAll(code, value || 'N/A');
        } else {
          if (br.type === 'internal') {
            const subgroupValueSum = getExtractionGroupValueSum(parseInt(extractorId, 10), label, groupsValues);
            res = res.replaceAll(code, subgroupValueSum);
            res = res.replaceAll(`${label}`, `SUM(${label})`);
          } else if (br.type === 'subgroup'){
            if (!subgroupId) {
              throw new Error('Subgroup ID is required for subgroup business rules');
            }
            const value = getExtractionGroupValue(parseInt(extractorId, 10), label, subgroupId, dataPointValues);
            res = res.replaceAll(code, value || 'N/A');
          }
        }
      });
      return {
        string: res,
        isValid: br.type === 'internal' && ruleResults[br.name] || ruleResults[br.name][subgroupId],
      };
    }
    return null;
  })
}

export function getDataPointBRStrings(businessRules, dataPointValues, groupsValues, ruleResults) {
  if (businessRules.length === 0) {
    return {};
  }
  const res = dataPointValues.reduce((acc, dp) => {
    if (
      dp.value.prevalidated
      && businessRules.some(br => br.extractor_codes.includes(`dp_${dp.data_point_id}`))
    ) {
      const businessRulesStrings = getValueBusinessRules(
        businessRules,
        dp.data_point_id,
        'dp',
        ruleResults,
        dataPointValues,
        groupsValues,
      );
      return {
        ...acc,
        [dp.data_point_id]: businessRulesStrings,
      };
    }
    return acc;
  }, {});
  return res;
}

export function getGroupValueBRStrings(businessRules, dataPointsValues, groupsValues, ruleResults) {
  let res = {};
  groupsValues.forEach((group) => {
    group.subgroups.forEach(subgroup => {
      subgroup.values.forEach(value => {
        const extractionGroupCode = `eg_${group.group_id}_${value.label}`;
        if (
          value.prevalidated
          && businessRules.some(br => br.extractor_codes.includes(extractionGroupCode))
        ) {
          const businessRulesStrings = getValueBusinessRules(
            businessRules,
            group.group_id,
            'eg',
            ruleResults,
            dataPointsValues,
            groupsValues,
            subgroup.id
          );
          res = {
            ...res,
            [`${extractionGroupCode}_${subgroup.id}`]: businessRulesStrings,
          };
        }
      });
    });
  });
  return res;
}

export function parseJsonLogic(
  jsonLogic,
  level = 1,
  type = 'internal',
  dataPoints = [],
  labelGroups = [],
) {
  if (typeof jsonLogic !== 'object' || jsonLogic === null) {
    return jsonLogic.toString();
  }

  const operators = Object.keys(jsonLogic);
  let operator = operators[0];
  const conditions = jsonLogic[operator];

  if (!operator) {
    return '';
  }
  if (operator === 'var') {
    if (!conditions) {
      return '_';
    }
    if (conditions.startsWith('dp_')) {
      const dp = dataPoints.find(dp => dp.id === parseInt(conditions.split('_')[1]));
      if (dp) {
        return dp.name;
      }
      return '_';
    } else if (conditions.startsWith('eg_')) {
      const group = labelGroups.find(g => g.id === parseInt(conditions.split('_')[1]));
      if (group) {
        const label = conditions.split('_').slice(2).join('_');
        if (type === 'subgroup') {
          return `${group.name}.${label}`;
        }
        return `SUM(${group.name}.${label})`;
      }
      return '_';
    }
    return conditions;
  }
  if (operator === 'abs') {
    let arg = parseJsonLogic(conditions, level + 1, type, dataPoints, labelGroups);
    if (arg.startsWith('(') && arg.endsWith(')')) {
      arg = arg.substring(1, arg.length - 1);
    }
    return `ABS(${arg})`;
  }
  if (!Array.isArray(conditions)) {
    return '';
  }
  const infixConditions = conditions.map(condition => parseJsonLogic(condition, level + 1, type, dataPoints, labelGroups));
  if (operator === '==') {
    operator = '=';
  }
  // eslint-disable-next-line no-irregular-whitespace
  const infixExpression = infixConditions.join(` ${operator} `);

  if (level === 2 || conditions.length === 1) {
    return infixExpression;
  }
  return `(${infixExpression})`;
}