import { http } from '@/plugins/axios';
import { store } from '@/plugins/store';
import { AuthenticationUtils } from '@/utils/AuthenticationUtils';

const BASE_PATH = 'search/api/v1/';

export class SearchAPI {
  static search(searchString, folderId, filters) {
    return http
      .post(`${BASE_PATH}search/`, filters, {
        params: {
          query: searchString,
          folder_id: folderId,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static postFeedback(queryId, thumbsUp, thumbsDown) {
    return http
      .post(`${BASE_PATH}search/thumbs/`, null, {
        params: {
          query_id: queryId,
          thumb_up: thumbsUp,
          thumb_down: thumbsDown,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getPageImage(fileId, page) {
    return http
      .get(`${BASE_PATH}image/${fileId}/${page}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async rag(searchString, chunks, queryId, onData, onError) {
    await AuthenticationUtils.refreshTokenIfNeeded(true);
    const baseURL = store.getters.config.backends.search;
    try {
      const response = await fetch(
        `${baseURL}${BASE_PATH}search/rag/?query=${searchString}&query_id=${queryId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AuthenticationUtils.getToken()}`,
          },
          body: JSON.stringify(chunks),
        });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      /* eslint-disable no-constant-condition */
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        buffer += decoder.decode(value, { stream: true });

        let boundary;
        while ((boundary = buffer.indexOf('\n')) !== -1) {
          const chunk = buffer.slice(0, boundary);
          buffer = buffer.slice(boundary + 1);

          if (chunk.trim()) {
            try {
              const jsonChunk = JSON.parse(chunk);
              if (jsonChunk.msg) {
                onData(jsonChunk.msg);
              }
            } catch (parseError) {
              console.error('Error parsing JSON chunk:', parseError);
              onError('Error parsing search answer.');
            }
          }
        }
      }
      /* eslint-enable no-constant-condition */
    } catch (error) {
      console.error('Error fetching data:', error);
      onError('Error streaming search answer.');
    }
  }

  static getQueryHistory() {
    return http
      .get(`${BASE_PATH}search/last`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getRecentFiles() {
    return http
      .get(`${BASE_PATH}file/last_files`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getLogs(timeRange, includeAi) {
    return http
      .get(`${BASE_PATH}search/logs`, {
        params: {
          start_time: timeRange[0],
          end_time: timeRange[1],
          include_llm: includeAi,
        },
        responseType: "arraybuffer",
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
