<template>
  <div
    v-if="email !== null"
    class="page-padding py-7"
  >
    <BackLink
      v-if="datasetName !== null"
      :text="datasetName"
      @click="() => $router.push({ name: 'EmailsDatasetView', params: { datasetId } })"
      show-text
    />
    <Email
      :email="email"
    />
  </div>
</template>

<script>
import { EmailsAPI } from '@/API/classify/EmailsAPI';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import Email from '@/components/common/elements/Emails/Email.vue';
import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI';


export default {
  name: 'EmailView',
  components: {
    BackLink,
    Email,
  },

  data() {
    return {
      email: null,
      datasetName: null,
    };
  },

  async created() {
    await Promise.all[this.fetchEmail(), this.fetchEmailsDataset()];
  },

  methods: {
    async fetchEmail() {
      this.email = await EmailsAPI.fetchEmail({ emailId: this.emailId });
    },

    async fetchEmailsDataset() {
      const response = await EmailsDatasetsAPI.get(this.datasetId);
      this.datasetName = response.data.name;
    },
  },

  props: {
    emailId: {
      type: Number,
      required: true,
    },
    datasetId: {
      type: Number,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>