<template>
  <div class="value-highlights">
    <div
      v-for="(dataPoint, i) in displayableValues"
      :key="`${dataPoint.data_point_name}_${i}`"
      :ref="`${dataPoint.data_point_name}_${i}`"
    >
      <v-tooltip
        v-if="!validNA(dataPoint) && hasLocationData(dataPoint)"
        :value="isHighlighted(dataPoint)"
        bottom
      >
        <template #activator="{ props }">
          <div
            :ref="`value${dataPoint.data_point_id}`"
            :class="{
              'highlight-frame': !dataPoint.group,
              'highlight-line-group': dataPoint.group,
              'highlight-frame--pending': isPending(dataPoint),
              'highlight-frame--valid': isValid(dataPoint),
              'highlight-frame--invalid': isInvalid(dataPoint),
              'highlight-line-group--pending': isPendingGroup(dataPoint),
              'highlight-line-group--valid': isValidGroup(dataPoint),
              'highlight-line-group--invalid': isInvalidGroup(dataPoint),
              'highlight-frame--pending__active': !dataPoint.group && isHighlighted(dataPoint),
              'highlight-frame--valid__active': isValid(dataPoint) && isHighlighted(dataPoint),
              'highlight-frame--invalid__active': isInvalid(dataPoint) && isHighlighted(dataPoint),
              'highlight-line-group--pending__active': dataPoint.group && isHighlightedGroup(dataPoint),
              'highlight-line-group--valid__active': isValidGroup(dataPoint) && isHighlightedGroup(dataPoint),
              'highlight-line-group--invalid__active': isInvalidGroup(dataPoint) && isHighlightedGroup(dataPoint),
            }"
            :style="{
              left: `${getHighlightX(dataPoint)}vh`,
              top: `${getHighlightY(dataPoint)}vh`,
              width: `${getWidth(dataPoint)}vh`,
              height: `${getHeight(dataPoint)}vh`,
            }"
            v-bind="props"
            @mouseover="$emit('highlight', dataPoint), updateHighlight(dataPoint)"
            @mouseout="$emit('deHighlight')"
            @click="$emit('verify', dataPoint)"
          />
        </template>
        <div class="d-flex flex-column align-center">
          <div class="highlight-value ellipsis">
            <div
              v-for="(line, index) in getValue(dataPoint).split('\n')"
              :key="index"
            >
              {{ line }}
            </div>
          </div>
          <div>
            ({{ dataPoint.data_point_name }})
          </div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueHighlights",

  computed: {
    displayableValues() {
      return this.extractorValues.filter(
        dp => (
          (dp.value.status !== 'invalid' && dp.value.value) ||
          (dp.value.status === 'invalid' && dp.value.valid_value)
        )
      );
    }
  },

  methods: {
    // On the corrections page we don't want to highlight anything in red
    isPending(dp) {
      return !dp.group && dp.value.status === 'pending';
    },

    isValid(dp) {
      return (
        !dp.group && (
          dp.value.status === 'valid' ||
          (
            dp.value.status === 'invalid' &&
            this.$route.name === 'DocCorrections'
          )
        )
      );
    },

    isInvalid(dp) {
      return !dp.group && dp.value.status === 'invalid' && this.$route.name !== 'DocCorrections';
    },

    isPendingGroup(dp) {
      return dp.group && dp.value.status === 'pending';
    },

    isValidGroup(dp) {
      return (
        dp.group &&
        (
          dp.value.status === 'valid' ||
          (
            dp.value.status === 'invalid' &&
            this.$route.name === 'DocCorrections'
          )
        )
      );
    },

    isInvalidGroup(dp) {
      return dp.group && dp.value.status === 'invalid' && this.$route.name !== 'DocCorrections';
    },

    validNA(dp) {
      // Checks if the value was 'N/A', has been invalidated, but validated after.
      return (dp.value.status === 'valid' && !dp.value.value && dp.value.valid_value);
    },

    hasLocationData(dp) {
      return (
        dp &&
        dp.value && (
          (dp.value.valid_location && Object.prototype.hasOwnProperty.call(dp.value.valid_location , 'x_max') && this.area(dp.value.valid_location) > 0) ||
          (dp.value.location && Object.prototype.hasOwnProperty.call(dp.value.location , 'x_max') && this.area(dp.value.location) > 0)
        )
      );
    },

    area(location) {
      return (location.x_max - location.x_min) * (location.y_max - location.y_min);
    },

    getHighlightX(dp) {
      if (dp.value && dp.value.status === 'invalid' && dp.value.valid_location) {
        return dp.value.valid_location.x_min * this.pageWidth;
      }
      if (dp.value && dp.value.status !== 'invalid' && dp.value.location) {
        return dp.value.location.x_min * this.pageWidth;
      }
    },

    getHighlightY(dp) {
      if (dp.value && dp.value.status === 'invalid' && dp.value.valid_location) {
        return dp.value.valid_location.y_min * this.pageHeight;
      }
      if (dp.value && dp.value.status !== 'ivalid' && dp.value.location) {
        return dp.value.location.y_min * this.pageHeight;
      }
    },

    getWidth(dp) {
      if (dp.value && dp.value.status === 'invalid' && dp.value.valid_location) {
        return (dp.value.valid_location.x_max - dp.value.valid_location.x_min) * this.pageWidth;
      }
      if (dp.value && dp.value.status !== 'invalid' && dp.value.location) {
        return (dp.value.location.x_max - dp.value.location.x_min) * this.pageWidth;
      }
    },

    getHeight(dp) {
      if (dp.value && dp.value.status === 'invalid' && dp.value.valid_location) {
        return (dp.value.valid_location.y_max - dp.value.valid_location.y_min) * this.pageHeight;
      }
      if (dp.value && dp.value.status !== 'invalid' && dp.value.location) {
        return (dp.value.location.y_max - dp.value.location.y_min) * this.pageHeight;
      }
    },

    getValue(dp) {
      if (dp.value.status !== 'invalid') {
        if (dp.value.value) {
          let value = dp.value.value;
          if (
            dp.value.normalized_value !== null &&
              dp.value.normalized_value !== undefined &&
              dp.value.normalized_value_type !== 'string' &&
             !(['integer', 'float'].includes(dp.value.normalized_value_type) && dp.value.normalized_value.toString() === dp.value.value)) {
            value += ` [${dp.value.normalized_value}]`;
          }
          return value;
        }
        return '';
      }
      return String(dp.value.valid_value);
    },

    isHighlighted(dataPoint) {
      if (this.highlighted && Object.keys(this.highlighted).length > 0) {
        if (this.highlighted.highlighted === false) {
          return false;
        }
        const hasSameId = this.highlighted.data_point_id === dataPoint.data_point_id;
        const hasSameType = this.highlighted.group === dataPoint.group;
        return hasSameId && hasSameType;
      }
      return false;
    },

    isHighlightedGroup(dataPoint) {
      if (this.highlighted && Object.keys(this.highlighted).length > 0) {
        if (this.highlighted.highlighted === false) {
          return false;
        }
        const hasSameId = this.highlighted.data_point_id === dataPoint.data_point_id;
        const hasSameName = this.highlighted.data_point_name === dataPoint.data_point_name;
        return hasSameId && hasSameName || (this.highlighted.value.group_id === dataPoint.value.group_id && this.highlighted.value.subgroup_index === dataPoint.subgroup_index);
      }
      return false;
    },

    updateHighlight(dp) {
      setTimeout(() => this.isHighlighted(dp), 100)
    },
  },

  props: {
    extractorValues: {
      type: Array,
      default: () => [],
    },
    highlighted: {
      type: Object,
      default: () => {},
    },
    verified: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
    originalWidth: {
      type: Number,
      required: true,
    },
    originalHeight: {
      type: Number,
      required: true,
    },
  },

  emits: ['highlight', 'deHighlight', 'verify'],
}
</script>

<style lang="scss" scoped>
.highlight {
  opacity: 0.4;
  position: absolute;
  cursor: pointer;
}

.highlight-value {
  max-width: 100%;
}

.highlight-frame {
  position: absolute;
  cursor: pointer;
  border-radius: 2px;

  &--pending {
    outline: 3px solid $pending-base;
    &__active {
      background-color: $pending-transparent;
    }
  }

  &--valid {
    outline: 3px solid $valid-base;
    &__active {
      background-color: $valid-transparent;
    }
  }

  &--invalid {
    outline: 3px solid $invalid-base;
    &__active {
      background-color: $invalid-transparent;
    }
  }
}

.highlight-line-group {
  position: absolute;
  cursor: pointer;

  &--pending {
    border-bottom: 3px solid $pending-base;
    &__active {
      background-color: $pending-transparent;
    }
  }

  &--valid {
    position: absolute;
    cursor: pointer;
    border-bottom: 3px solid $valid-base;
    &__active {
      background-color: $valid-transparent;
    }
  }

  &--invalid {
    position: absolute;
    cursor: pointer;
    border-bottom: 3px solid $invalid-base;
    &__active {
      background-color: $invalid-transparent;
    }
  }
}
</style>
