
import { http } from '@/plugins/axios';

const BASE_PATH = 'config/';

export class ConfigAPI {
  static get() {
    return http
      .get(BASE_PATH)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static update(config) {
    return http
      .put(BASE_PATH, config)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
