<template>
  <div class="page-location-config top-gap-sm">
    <div
      class="canvas"
      @mouseup="drawEnd"
      @mousedown="drawEnd"
      @mousemove="drawMove"
    />
    <div class="noselect">
      {{ $t('dataPoints.select_area') }}
    </div>
    <div class="page-container">
      <div
        class="canvas"
        @mouseup="drawEnd"
        @mousedown="drawEnd"
        @mousemove="drawMove"
      />
      <div
        class="page-template"
        :style="{
          width: `${pageWidth}px`,
          height: `${pageHeight}px`,
        }"
      >
        <div
          v-if="area > 0"
          class="coordinate-box"
          :style="{
            left: `${getHighlightX(updatedCoordinates.x_min)}px`,
            top: `${getHighlightY(updatedCoordinates.y_min)}px`,
            width: `${getHighlightX(updatedCoordinates.x_max - updatedCoordinates.x_min)}px`,
            height: `${getHighlightY(updatedCoordinates.y_max - updatedCoordinates.y_min)}px`,
          }"
        />
        <div class="coordBar middleBar1" />
        <div class="coordBar middleBar2" />
        <div class="coordBar topBar" />
        <div class="coordBar bottomBar" />
        <div class="coordBar leftBar" />
        <div class="coordBar rightBar" />
        <div
          class="canvas"
          @mousedown="drawStart"
          @mouseup="drawEnd"
          @mousemove="drawMove"
        />
      </div>
      <div class="coordBar topBar2" />
      <div class="coordBar bottomBar2" />
      <div class="coordBar leftBar2" />
      <div class="coordBar rightBar2" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLocationConfig',

  data() {
    return ({
      show: false,
      locationRadio: 'custom',
      pageWidth: 180,
      pageHeight: 250,
      lastOffsetX: 0,
      lastOffsetY: 0,
      updatedCoordinates: {
        x_min: 0,
        x_max: 0,
        y_min: 0,
        y_max: 0,
      },
      sets: {
        all: {
          x_min: 0,
          x_max: 1,
          y_min: 0,
          y_max: 1,
        },
        top: {
          x_min: 0,
          x_max: 1,
          y_min: 0,
          y_max: 0.5,
        },
        bottom: {
          x_min: 0,
          x_max: 1,
          y_min: 0.5,
          y_max: 1,
        },
        left: {
          x_min: 0,
          x_max: 0.5,
          y_min: 0,
          y_max: 1,
        },
        right: {
          x_min: 0.5,
          x_max: 1,
          y_min: 0,
          y_max: 1,
        },
        topleft: {
          x_min: 0,
          x_max: 0.5,
          y_min: 0,
          y_max: 0.5,
        },
        topright: {
          x_min: 0.5,
          x_max: 1,
          y_min: 0,
          y_max: 0.5,
        },
        bottomleft: {
          x_min: 0,
          x_max: 0.5,
          y_min: 0.5,
          y_max: 1,
        },
        bottomright: {
          x_min: 0.5,
          x_max: 1,
          y_min: 0.5,
          y_max: 1,
        },
        custom: {
          x_min: 0,
          x_max: 0,
          y_min: 0,
          y_max: 0,
        },
      },
      drawing: false,
      cornerX: 'right',
      cornerY: 'bottom',
    })
  },

  computed: {
    setKeys() {
      const setKeys = Object.keys(this.sets);
      setKeys.pop();
      return setKeys;
    },

    area() {
      const area = (this.updatedCoordinates.x_max - this.updatedCoordinates.x_min) * (this.updatedCoordinates.y_max - this.updatedCoordinates.y_min);
      return area;
    }
  },

  watch: {
    coordinates() {
      if (this.coordinates) {
        this.updatedCoordinates = this.coordinates;
        this.setRadio();
      }
    },
  },

  mounted() {
    if (this.coordinates) {
      this.updatedCoordinates = this.coordinates;
      this.setRadio();
    }
  },

  methods: {
    drawStart(event) {
      this.drawing = true;
      const newX = event.offsetX / this.pageWidth;
      const newY = event.offsetY / this.pageHeight;
      this.updatedCoordinates.x_min = newX;
      this.updatedCoordinates.x_max = newX;
      this.updatedCoordinates.y_min = newY;
      this.updatedCoordinates.y_max = newY;
      this.lastOffsetX = event.offsetX;
      this.lastOffsetY = event.offsetY;
    },
    drawMove(event) {
      if (this.drawing) {
        const newX = event.offsetX / this.pageWidth;
        const newY = event.offsetY / this.pageHeight;
        const normalizedX = Math.min(Math.max(0, newX), 1);
        const normalizedY = Math.min(Math.max(0, newY), 1);

        if (this.cornerX === 'right') {
          if (newX > this.updatedCoordinates.x_min) {
            if (this.lastOffsetX - event.offsetX <= 1) {
              this.updatedCoordinates.x_max = normalizedX;
              this.lastOffsetX = event.offsetX;
            }
          } else {
            this.cornerX = 'left'
          }
        } else if (this.cornerX === 'left') {
          if (newX < this.updatedCoordinates.x_max) {
            if (event.offsetX - this.lastOffsetX <= 1) {
              this.updatedCoordinates.x_min = normalizedX;
              this.lastOffsetX = event.offsetX;
            }
          } else {
            this.cornerX = 'right'
          }
        }

        if (this.cornerY === 'bottom') {
          if (newY > this.updatedCoordinates.y_min) {
            if (this.lastOffsetY - event.offsetY <= 1) {
              this.updatedCoordinates.y_max = normalizedY;
              this.lastOffsetY = event.offsetY;
            }
          } else {
            this.cornerY = 'top'
          }
        } else if (this.cornerY === 'top') {
          if (newY < this.updatedCoordinates.y_max) {
            if (event.offsetY - this.lastOffsetY <= 1) {
              this.updatedCoordinates.y_min = normalizedY;
              this.lastOffsetY = event.offsetY;
            }
          } else {
            this.cornerY = 'bottom'
          }
        }
        this.setRadio();
      }
    },
    setRadio() {
      let set = false;
      this.setKeys.forEach(key => {
        if (
          this.updatedCoordinates.x_min === this.sets[key].x_min &&
          this.updatedCoordinates.x_max === this.sets[key].x_max &&
          this.updatedCoordinates.y_min === this.sets[key].y_min &&
          this.updatedCoordinates.y_max === this.sets[key].y_max
        ) {
          this.locationRadio = key;
          set = true;
        }
      });
      if (!set) {
        this.locationRadio = 'custom';
      }
    },
    drawEnd() {
      this.drawing = false;
      this.$emit('updateCoordinates', this.updatedCoordinates);
    },
    setCoordinates(value) {
      if (value !== 'custom') {
        let newCoordinates = {};
        newCoordinates = Object.assign(newCoordinates, this.sets[value])
        this.updatedCoordinates = newCoordinates;
      } else {
        this.updatedCoordinates = this.sets[value];
      }
      this.drawEnd();
    },
    getHighlightX(x) {
      return x * this.pageWidth
    },
    getHighlightY(y) {
      return y * this.pageHeight
    },
  },

  props: {
    coordinates: {
      type: Object,
      required: true,
    },
  },

  emits: ['updateCoordinates'],
}
</script>

<style lang="scss" scoped>
.page-location-config {
  position: relative;
  font-size: 0.9rem;

  .canvas {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  .radio-container {
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
    position: relative;
  }

  .page-container {
    display: inline-block;
    vertical-align: top;
    width: 190px;
    height: 300px;
    background-color: #f4f5f9;
    position: relative;

    .coordBar {
      position: absolute;
      background-color: rgb(var(--v-theme-grey-darken2));
    }

    .topBar {
      top: -7px;
      right: 50%;
      height: 14px;
      width: 1px;
    }

    .topBar2 {
      top: 20px;
      right: 50%;
      height: 7px;
      width: 1px;
    }

    .bottomBar {
      bottom: -7px;
      right: 50%;
      height: 14px;
      width: 1px;
    }

    .bottomBar2 {
      bottom: 20px;
      right: 50%;
      height: 7px;
      width: 1px;
    }

    .leftBar {
      top: 50%;
      left: -7px;
      width: 14px;
      height: 1px;
    }

    .leftBar2 {
      top: 50%;
      left: 0px;
      width: 7px;
      height: 1px;
    }

    .rightBar {
      top: 50%;
      right: -7px;
      width: 14px;
      height: 1px;
    }

    .rightBar2 {
      top: 50%;
      right: 0px;
      width: 7px;
      height: 1px;
    }

    .middleBar1 {
      top: 50%;
      left: 50%;
      width: 14px;
      height: 1px;
      transform: translateX(-50%);
    }

    .middleBar2 {
      top: 50%;
      left: 50%;
      width: 1px;
      height: 14px;
      transform: translateY(-50%);
    }

    .page-template {
      background-color: white;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
      cursor: crosshair;
      border: 2px solid rgb(var(--v-theme-grey-darken2));
      border-radius: 4px;

      .coordinate-box {
        border: rgb(var(--v-theme-primary)) dashed 2px;
        position: absolute;
        background-color: rgba(80, 43, 255, 0.3);
      }
    }
  }
}
</style>
