<template>
  <div class="document-labels">
    <div class="top-gap-sm bottom-gap">
      {{ $t('models.label_types.document') }}
      <v-icon
        v-if="open"
        class="clickable left-gap-sm"
        size="16"
        @click="open = false"
      >
        fas fa-chevron-down
      </v-icon>
      <v-icon
        v-else
        class="clickable left-gap-sm"
        style="margin-top: -1px"
        size="16"
        @click="open = true"
      >
        fas fa-chevron-right
      </v-icon>
    </div>
    <div v-if="open">
      <div
        v-for="(label, i) in filteredLabels"
        :key="i"
      >
        <div style="position: relative; top: -10px">
          <ItemName
            :key="label.id"
            class="right-gap-sm"
            style="font-weight: bold !important; font-size: 0.9rem; max-width: 300px;"
            :item="label"
            :clickable="false"
            :show-id="false"
            :show-edit-icon="true"
            @save-file-name="updateLabelName"
            editing-allowed
          />
        </div>
        <div
          v-if="label.options && label.options.length > 1"
          class="bottom-gap-lg"
          style="position: relative; width: 335px;"
        >
          <v-icon
            class="clickable close-icon"
            size="12"
            @click="deleting = label.id; deleteDialog = true;"
          >
            fas fa-times
          </v-icon>
          <v-select
            v-if="gotValues"
            v-model="file.doc_annotations[label.id]"
            style="margin-top: -5px; width: 300px"
            class="inline-middle mt-0"
            variant="outlined"
            color="primary"
            density="compact"
            :items="label.options"
            :multiple="!label.exclusive"
            :placeholder="$t('models.not_specified')"
            @update:model-value="handleChange(label.id)"
          />
          <v-select
            v-else
            style="margin-top: -5px; width: 300px"
            class="inline-middle mt-0"
            variant="outlined"
            color="primary"
            density="compact"
            disabled
          />
        </div>
        <div
          v-else
          style="position: relative"
        >
          <v-icon
            class="clickable close-icon"
            size="12"
            @click="deleting = label.id; deleteDialog = true;"
          >
            fas fa-times
          </v-icon>
          <textarea
            v-if="gotValues"
            v-model="file.doc_annotations[label.id]"
            class="custom-textarea right-gap"
            style="margin-top: -5px"
            variant="outlined"
            color="primary"
            @focus="$emit('editingDocLabel', true)"
            @blur="$emit('editingDocLabel', false)"
          />
          <v-textarea
            v-else
            class="right-gap"
            style="margin-top: -5px"
            variant="outlined"
            color="primary"
            disabled
          />
          <div style="text-align: right">
            <v-btn
              class="add-button top-gap-sm right-gap"
              color="primary"
              @click="handleChange(label.id)"
              rounded
            >
              <v-icon
                size="16"
                start
              >
                fas fa-check
              </v-icon>
              {{ $t("save") }}
            </v-btn>
          </div>
        </div>
      </div>
      <div
        v-if="trimmedFilter !== '' && filteredLabels.length === 0"
        class="no-labels"
      >
        {{ $t('studio.no_labels') }}
      </div>
      <AddDocumentLabel
        class="top-gap"
        style="margin-left: -12px"
        :dataset-id="parseInt($route.params.id, 10)"
        @get-labels="reloadData"
      />
    </div>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('models.delete_label')"
      :message="$t('models.label_delete_confirmation')"
      @confirm="deleteLabel"
    />
  </div>
</template>

<script>
import { http } from "@/plugins/axios";

import ItemName from '@/components/common/elements/General/ItemName';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import AddDocumentLabel from '@/components/extract/elements/Datasets/AddDocumentLabel';

export default {
  name: 'DocumentLabels',

  components: {
    ItemName,
    AddDocumentLabel,
    ConfirmDialog,
  },

  data() {
    return ({
      deleting: -1,
      deleteDialog: false,
      gotValues: false,
      newLabel: "",
      docLabels: [],
      open: false,
    });
  },

  computed: {
    trimmedFilter() {
      return this.filter.trim().toLowerCase();
    },

    filteredLabels() {
      return this.docLabels.filter(label => label.name.toLowerCase().includes(this.trimmedFilter));
    },

    trimmedLabel() {
      return this.newLabel.trim();
    },
  },

  watch: {
    file(file, old) {
      if (file && (!old || file.id != old.id)) {
        this.gotValues = false;
        setTimeout(() => {
          this.getLabelValues();
        }, 10);
      }
    },

    trimmedFilter() {
      this.$emit('calculateHeight');
    }
  },

  async mounted() {
    await this.reloadData();
  },

  methods: {
    async reloadData() {
      await this.getDocLabels();
      if (this.file && this.docLabels.length > 0) {
        await this.getLabelValues();
      }
    },

    async deleteLabel() {
      await http.delete(`dataset/label/${this.deleting}/?type=entry`);
      this.deleting = -1;
      this.deleteDialog = false;
      this.reloadData();
    },

    handleChange(label_id) {
      setTimeout(() => {
        const value = this.file.doc_annotations[label_id];
        this.updateLabelValue(label_id, value);
      }, 10);
    },

    async getLabelValues() {
      try {
        this.$store.commit("setLoadingScreen", true);
        const response = await http.get(
          `dataset/entry_label_value/${this.file.id}/`
        );
        const annotations = response.data;
        const nb_labels = this.docLabels.length;
        this.file.doc_annotations = {};
        for (let i = 0; i < nb_labels; i++) {
          this.file.doc_annotations[this.docLabels[i].id] = null;
        }
        const nb_annotated = annotations.length;
        for (let i = 0; i < nb_annotated; i++) {
          const annotation = annotations[i];
          const label = this.docLabels.find(l => l.id === annotation.entry_label_id);
          if (label && (!label.options || label.exclusive) && annotation.value) {
            this.file.doc_annotations[annotation.entry_label_id] = annotation.value[0];
          } else {
            this.file.doc_annotations[annotation.entry_label_id] = annotation.value;
          }
        }
        this.$emit('refreshFile');
        this.gotValues = true;
        this.docLabels = [...this.docLabels];
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async updateLabelValue(id, value) {
      try {
        this.$store.commit('setLoadingScreen', true);
        if (value) {
          const label = this.docLabels.find(l => l.id === id);
          if (label && (!label.options || label.exclusive)) {
            value = [value];
          }
        }
        await http.post(
          `dataset/entry_label_value/`,
          {
            entry_id: this.file.id,
            entry_label_id: id,
            value,
          }
        );
        this.$store.commit('setSuccessMessage', this.$t('models.label_value_posted'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async updateLabelName(id, newName) {
      if (newName.trim() !== '') {
        try {
          this.$store.commit('setLoadingScreen', true);
          await http.put(
            `dataset/label/${id}/?type=entry`,
            { name: newName.trim() }
          );
          await this.reloadData();
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    async getDocLabels() {
      try {
        this.$store.commit("setLoadingScreen", true);
        this.gotValues = false;
        const response = await http.get(
          `dataset/label/${this.$route.params.id}/?type=entry`
        );
        this.docLabels = response.data.sort((a, b) => {
          if (a.options && b.options) {
            return 0;
          }
          if (!a.options && b.options) {
            return 1;
          }
          if (a.options && !b.options) {
            return -1;
          }
        });
        this.$emit('calculateHeight');
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async addLabel() {
      if (this.trimmedLabel !== "") {
        try {
          await http.post(`dataset/label/${this.$route.params.id}/?type=entry`, {
            name: this.newLabel,
          });
          this.newLabel = "";
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
        this.getDocLabels();
      }
    },
  },

  props: {
    file: {
      type: Object,
      required: true,
    },

    filter: {
      type: String,
      required: true,
    }
  },

  emits: ['editingDocLabel', 'calculateHeight', 'refreshFile'],
}
</script>

<style lang="scss" scoped>
.document-labels {
  .no-labels {
    font-style: italic;
    text-align: center;
    margin-top: 12px;
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 0px;
  }

  .label-field {
    width: 230px;
  }

  .add-button {
    box-shadow: none;
  }
}
</style>
