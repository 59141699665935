export default {
  data() {
    return {
      trimmedFilter: '',
      deleteDialog: false,
      total: 0,
      paginated: [],
      allSelected: false,
      loading: false,
      forceCompute: Math.random(),
    };
  },

  computed: {
    selected: {
      get() {
        this.forceCompute;
        if (this.paginated.length > 0) {
          return this.paginated.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  methods: {
    toggleSelectAll() {
      this.paginated.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },

    handleSelect() {
      this.allSelected = this.paginated.every(i => i.selected);
      this.forceCompute = Math.random();
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      const items = this.paginated.map(i => {
        i.selected = false;
        return i;
      });
      this.paginated = [...items];
    },

    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },

    datediff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
  },
}
