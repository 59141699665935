<template>
  <SmoothStepEdge
    style=" stroke-width: 2"
    :style="{
      stroke: selected ? 'rgb(var(--v-theme-primary))' : 'rgb(var(--v-theme-grey))'
    }"
    :source="source"
    :target="target"
  />
</template>

<script>
import { SmoothStepEdge } from '@vue-flow/core';

export default {
  name: 'CustomEdge',

  components: {
    SmoothStepEdge,
  },

  props: {
    source: {
      type: String,
      required: true,
    },

    target: {
      type: String,
      required: true,
    },

    selected: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.selected {
  stroke: rgb(var(--v-theme-primary)) !important;
}
</style>
