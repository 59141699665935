<template>
  <div class="page-padding py-7">
    <BreadcrumbComponent />
    <div class="classification-agent-actions-container">
      <v-text-field
        v-model="searchedClassificationAgentName"
        class="right-gap name-filter-field inline-middle"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('filter')"
        @input="onNameFilterInput"
      />
      <div class="classification-agents-button__container">
        <template v-if="!selectedClassificationAgents.length">
          <ButtonWithIcon
            icon="fas fa-plus"
            color="primary"
            variant="outlined"
            :message="$t('classificationAgents.create')"
            @click="openClassificationAgentEditor()"
            rounded
          />
          <ButtonWithIcon
            icon="fas fa-upload"
            color="primary"
            variant="outlined"
            :message="$t('import')"
            @click="showUploadDialog = true"
            rounded
          />
        </template>
        <template v-else>
          <TableAction
            v-if="selectedClassificationAgents.length === 1"
            prepend-icon="fas fa-download"
            :label="$t('download')"
            @click="downloadClassificationAgent(selectedClassificationAgents[0].id)"
          />
          <TableAction
            prepend-icon="fas fa-trash"
            :label="$tc('classificationAgents.delete_classification_agent', selectedClassificationAgents.length)"
            @click="deleteDialog = true"
          />
        </template>
      </div>
    </div>
    <TableWithFooter
      :loading="false"
      :paginated-items-length="classificationAgents.length"
      :total="totalClassificationAgents"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      @sort="onSort"
      sortable
    >
      <template #header>
        <v-col cols="auto">
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            hide-details
          />
        </v-col>
        <v-col cols="3">
          {{ $t('classificationAgents.classification_agent') }}
        </v-col>
        <v-col cols="3">
          {{ $t('classificationAgents.classification_model') }}
        </v-col>
        <v-col cols="2">
          {{ $t('classificationAgents.ocr_model') }}
        </v-col>
        <v-col cols="2">
          {{ $t('classificationAgents.threshold') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="classificationAgent in classificationAgents"
            :key="classificationAgent.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="classificationAgent.selected"
                class="mt-0"
                hide-details
              />
            </v-col>
            <v-col cols="3">
              <ItemName
                :key="classificationAgent.id"
                type="classificationAgent"
                class="inline-top"
                :item="classificationAgent"
                @click="openClassificationAgentEditor(classificationAgent.id)"
              />
            </v-col>
            <v-col cols="3">
              <ItemName
                class="inline-top"
                :item="{ name: classificationAgent.model.name }"
                :clickable="false"
              />
            </v-col>
            <v-col cols="2">
              {{ classificationAgent.ocr_model }}
            </v-col>
            <v-col cols="2">
              {{ classificationAgent.threshold }}
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('classificationAgents.delete_confirmation.title')"
      :message="$t('classificationAgents.delete_confirmation.message')"
      @confirm="deleteSelectedClassificationAgents"
    />
    <UploadDialog
      v-model="showUploadDialog"
      :title="$t('classificationAgents.import_classification_agent')"
      :input="uploadInput"
      :select-btn="$t('classificationAgents.import_classification_agent')"
      @change="uploadClassificationAgent"
    />
  </div>
</template>

<script>

import ItemName from '@/components/common/elements/General/ItemName.vue';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter.vue';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent.vue";
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';
import TableAction from '@/components/common/elements/Tables/TableAction.vue';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog.vue";
import UploadDialog from '@/components/common/elements/Forms/UploadDialog.vue';

export default {
  name: 'ClassificationAgentsView',

  components: {
    ItemName,
    TableWithFooter,
    BreadcrumbComponent,
    ButtonWithIcon,
    TableAction,
    ConfirmDialog,
    UploadDialog,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      classificationAgents: [],
      totalClassificationAgents: 0,
      itemsPerPage,
      currentPage,
      searchedClassificationAgentName: '',
      descendingSort: true,
      deleteDialog: false,
      showUploadDialog: false,
      checkUploadedAgent: null,
      timeToNextCheck: 2000,
      pendingUpload: false,
    };
  },

  computed: {
    selectedClassificationAgents() {
      return this.classificationAgents.filter((classificationAgent) => classificationAgent.selected);
    },

    allSelected: {
      get() {
        if (this.classificationAgents.length === 0) {
          return false;
        }

        return this.classificationAgents.every(item => item.selected);
      },

      set(allSelected) {
        this.classificationAgents.forEach((classificationAgent) => {
          classificationAgent.selected = allSelected;
        });
      }
    },
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
      this.fetchClassificationAgents();
    },

    currentPage() {
      this.fetchClassificationAgents();
    }
  },

  created() {
    this.setBreadcrumb();
    this.fetchClassificationAgents();
    this.uploadInput = {
      label: this.$t('classificationAgents.classification_agent_name'),
      placeholder: this.$t('classificationAgents.type_new_classification_agent_name'),
      hint: this.$t('classificationAgents.classification_agent_name_hint')
    };
  },

  methods: {
    async fetchClassificationAgents() {
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const searchedClassificationAgentName = this.searchedClassificationAgentName.trim().toLowerCase();
      const response = await ClassificationAgentsAPI.fetchAll({
        name: searchedClassificationAgentName, offset, limit: this.itemsPerPage, sortDesc: this.descendingSort});

      this.classificationAgents = response.data;
      this.totalClassificationAgents = parseInt(response.headers['x-total-count'], 10);
    },

    async onNameFilterInput() {
      this.resetCurrentPage();
      await this.fetchClassificationAgents();
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },

    openClassificationAgentEditor(classificationAgentId = undefined) {
      this.$router.push({ name: 'ClassificationAgentEditor', params: { id: classificationAgentId } });
    },

    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
          { title: this.$t('menu.studio') },
          { title: this.$t('menu.classification') },
        ]
      );
    },

    onSort(descendingSort) {
      this.descendingSort = descendingSort;
      this.fetchClassificationAgents();
    },

    async deleteSelectedClassificationAgents() {
      const ids = this.selectedClassificationAgents.map((classificationAgent) => classificationAgent.id);
      await ClassificationAgentsAPI.deleteClassificationAgents({ ids });
      this.deleteDialog = false;
      this.allSelected = false;
      this.fetchClassificationAgents();
    },

    async downloadClassificationAgent(id) {
      const response = await ClassificationAgentsAPI.downloadClassificationAgent({ id });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `classification_agent_${id}.zip`);
      document.body.appendChild(link);
      link.click();
    },

    checkUploaded() {
      this.fetchClassificationAgents();
      clearInterval(this.checkUploadedAgent);
      this.checkUploadedAgent = setInterval(() => {
        if (this.pendingUpload) {
          clearInterval(this.checkUploadedAgent);
        } else {
          this.fetchClassificationAgents();
        }
      }, this.timeToNextCheck);
    },

    async uploadClassificationAgent(payload) {
      const { name, zipfile } = payload;
      this.$store.commit('setLoadingScreen', true);
      const formData = new FormData();
      formData.append('name', name);
      formData.append('file', zipfile);
      try {
        await ClassificationAgentsAPI.uploadClassificationAgent(formData);
        this.showUploadDialog = false;
        this.checkUploaded();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },

  emits: [],
}
</script>

<style lang="scss" scoped>

.classification-agent-actions-container {
  display: inline-flex;
  gap: .25em;
  align-items: start;
}

.name-filter-field {
  width: 300px;
}

.classification-agents-button__container {
  height: 42px;
  display: flex;
  gap: 1em;
  align-items: center;
}

</style>
