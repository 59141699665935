import { store } from '@/plugins/store';
import { ClassifyHealthAPI } from '@/API/classify/ClassifyHealthAPI';
import { AuthHealthAPI } from '@/API/authenticator/AuthHealthAPI';
import { ExtractHealthAPI } from '@/API/extract/ExtractHealthAPI';
import { SearchHealthAPI } from '@/API/search/SearchHealthAPI';
import { WorkflowHealthAPI } from '@/API/workflows/WorkflowHealthAPI';

class ProductHealthCheckClass {
  static get serviceHealthAPI() {
    return {
      auth: AuthHealthAPI,
      extract: ExtractHealthAPI,
      classify: ClassifyHealthAPI,
      search: SearchHealthAPI,
      workflows: WorkflowHealthAPI,
    };
  }

  static async check(products) {
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const status = store.getters.serviceStatus[product];
      if (!status.checked) {
        try {
          const serviceStatus = await this.serviceHealthAPI[product].get();
          store.commit('setServiceVersion', {service: product, version: serviceStatus.version});
          store.commit('setServiceStatus', {service: product, status: serviceStatus.status});
        } catch {
          store.commit('setServiceStatusUnreachable', product);
        } finally {
          store.commit('setServiceStatusChecked', product);
        }
      }
    }
  }
}

export const ProductHealthCheck = ProductHealthCheckClass;
