<template>
  <v-dialog
    v-model="openDialog"
    width="600"
  >
    <v-card class="dialog-card">
      <h2 class="dialog-title mb-6">
        {{ $t('emailsDatasets.import_emails_datasets') }}
      </h2>
      <p
        class="font-weight-medium mb-3"
      >
        {{ $t('emailsDatasets.import_emails_datasets_strategy_title') }}
      </p>
      <v-radio-group
        v-model="selectedImportStrategy"
      >
        <div
          v-for="strategy in importStrategies"
          :key="strategy.value"
          class="option-container"
        >
          <v-radio
            :label="strategy.label"
            :value="strategy.value"
          />
          <v-tooltip>
            <template #activator="{ props }">
              <v-icon
                color="primary"
                size="16"
                v-bind="props"
              >
                fas fa-info-circle
              </v-icon>
            </template>
            {{ strategy.description }}
          </v-tooltip>
        </div>
      </v-radio-group>
      <v-card-actions>
        <v-btn
          color="primary"
          @click="onUploadZipFileButtonClick"
        >
          {{ $t('emailsDatasets.upload_zip_file') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <input
      ref="fileUploader"
      type="file"
      class="d-none"
      accept=".zip"
      @change="handleUpload"
    >
  </v-dialog>
</template>

<script>

import { uploadZippedEmailsDatasets } from './EmailUploadsHelper';

export default {
  name: 'UploadZippedEmailsDatasetsDialog',

  data() {
    return {
      openDialog: this.modelValue,
      importStrategies: [{
        label: this.$t('emailsDatasets.import_strategies.merge.label'),
        value: 'merge',
        description: this.$t('emailsDatasets.import_strategies.merge.description'),
      }, {
        label: this.$t('emailsDatasets.import_strategies.add.label'),
        value: 'add',
        description: this.$t('emailsDatasets.import_strategies.add.description'),
      }, {
        label: this.$t('emailsDatasets.import_strategies.ignore.label'),
        value: 'ignore',
        description: this.$t('emailsDatasets.import_strategies.ignore.description'),
      }],
      selectedImportStrategy: 'merge',
    };
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },
    modelValue(show) {
      this.openDialog = show;
    },
  },

  methods: {
    onUploadZipFileButtonClick() {
      this.$refs.fileUploader.click();
    },

    async handleUpload({ target: { files } }) {
      try {
        await uploadZippedEmailsDatasets({zipFile: files[0], importStrategy: this.selectedImportStrategy});
        this.reset();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    reset() {
      this.openDialog = false;
      this.selectedImportStrategy = "merge";
    },
  },

  props: {
    modelValue: Boolean,
  },

  emits: ['change', 'update:modelValue'],
};
</script>

<style scoped>

.dialog-card {
    padding: 20px;
}

.dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.option-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 200px;

    .v-selection-control {
      width: fit-content;
      flex: none;
      white-space: nowrap;
    }

    .v-icon {
      margin-left: 6px;
    }
}

</style>