<template>
  <v-dialog
    v-model="show"
    max-width="750"
    height="700"
  >
    <v-card class="dialog-card pb-2 overflow-unset">
      <div class="d-flex align-center justify-space-between mb-4">
        <h1 class="text-h5 primary--text">
          {{ $t('workflows.documents.copy_to_dataset_title') }}
        </h1>
        <div>
          <v-icon
            class="close-icon"
            size="16px"
            @click="closeDialog"
          >
            fas fa-times
          </v-icon>
        </div>
      </div>
      <div class="dialog-content">
        <CustomSelect
          style="width: 400px;"
          :selected="selectedDatasetId"
          :items="datasets"
          :loading="loading"
          :placeholder="$t('workflows.documents.select_dataset')"
          @selected-changed="onDatasetChange"
          @get-more="loadMoreDatasets"
        />
        <v-text-field
          v-model="newEntryName"
          class="mt-4"
          variant="outlined"
          density="compact"
          style="width: 300px;"
          :placeholder="$t('workflows.documents.new_entry_name')"
        />
        <div class="d-flex justify-end">
          <v-btn
            class="clickable"
            color="primary"
            :disabled="!isValid"
            @click="copyToDataset"
            rounded
          >
            {{ $t('workflows.documents.copy_to_dataset') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';

export default {
  name: 'CopyToDatasetDialog',

  components: {
    CustomSelect,
  },

  data() {
    return {
      selectedDatasetId: null,
      newEntryName: '',
      datasets: [],
      loading: false,
      currentOffset: 0,
      listSize: 20,
      totalDatasets: 0,
    };
  },

  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    isValid() {
      return this.selectedDatasetId && this.newEntryName?.trim();
    },
  },

  watch: {
    async modelValue(newVal) {
      if (newVal) {
        await this.loadMoreDatasets(true);
      } else {
        this.resetForm();
      }
    }
  },

  methods: {
    closeDialog() {
      this.show = false;
    },

    onDatasetChange(datasetId) {
      this.selectedDatasetId = datasetId;
    },

    async loadMoreDatasets(reset) {
      if (this.loading || this.currentOffset > this.totalDatasets) return;

      try {
        this.loading = true;

        if (reset) {
          this.datasets = [];
          this.currentOffset = 0;
        } else {
          this.currentOffset += this.listSize;
        }

        const response = await this.fetchDatasets({
          offset: this.currentOffset,
          limit: this.listSize
        });

        this.datasets = this.datasets.concat(...response.data);
        this.totalDatasets = parseInt(response.headers['x-total-count'], 10);

      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.selectedDatasetId = null;
      this.newEntryName = '';
      this.datasets = [];
      this.currentOffset = 0;
    },

    copyToDataset() {
      if (!this.isValid) return;

      this.$emit('copy-to-dataset', {
        dataset_id: this.selectedDatasetId,
        entry_name: this.newEntryName.trim()
      });

      this.closeDialog();
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true
    },

    fetchDatasets: {
      type: Function,
      required: true
    }
  },

  emits: [
    'update:modelValue',
    'copy-to-dataset',
  ],
};
</script>

<style scoped>
.overflow-unset {
  overflow: visible !important;
}

.input-width {
  width: 400px;
}
</style>
