<template>
  <div class="mailbox-config">
    <BackLink
      class="bottom-gap-sm"
      :target="{ name: 'Workflows', query: { activeTab: 'input' } }"
      :text="$t('mailboxes.title')"
    />
    <h1 class="text-h3 primary--text bottom-gap">
      {{ id === 'new' && $t('mailboxes.config.create_title') || $t('mailboxes.config.title') }}
    </h1>
    <v-card class="mailbox-config-card">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('mailboxes.config.name') }}
          </h4>
        </div>
        <div class="settings-right">
          <v-text-field
            v-model="mailbox.name"
            variant="outlined"
            color="primary"
            density="compact"
            :placeholder="$t('mailboxes.config.name_placeholder')"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <hr class="divider-line my-3">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('mailboxes.config.imap') }}
          </h4>
        </div>
        <div class="settings-right">
          <div style="margin-bottom: -10px">
            <label
              class="form-label right-gap font-weight-bold"
              for="email"
            >
              {{ $t('mailboxes.email') }}
            </label>
            <v-text-field
              id="email"
              v-model="mailbox.email"
              style="margin-top: 5px"
              type="email"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="id !== 'new'"
              :rules="[rules.emailRequired, rules.email]"
            />
          </div>
          <div style="margin-bottom: -10px">
            <label
              class="form-label right-gap font-weight-bold"
              for="host"
            >
              {{ $t('mailboxes.config.host') }}
            </label>
            <v-text-field
              id="host"
              v-model="mailbox.host"
              style="margin-top: 5px"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="id !== 'new'"
              :rules="[rules.required]"
            />
          </div>
          <div style="margin-bottom: -10px">
            <label
              class="form-label right-gap font-weight-bold"
              for="host"
            >
              {{ $t('mailboxes.config.port') }}
            </label>
            <v-text-field
              id="host"
              v-model="mailbox.port"
              style="margin-top: 5px"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="id !== 'new'"
              :rules="[rules.required]"
            />
          </div>
        </div>
      </div>
      <hr class="divider-line my-3">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('mailboxes.config.input.section_title') }}
          </h4>
        </div>
        <div class="settings-right">
          <div style="margin-bottom: -10px">
            <label
              class="form-label right-gap font-weight-bold"
              for="folder"
            >
              {{ $t('mailboxes.config.input.folder') }}
            </label>
            <v-text-field
              id="folder"
              v-model="mailbox.folder"
              class="mt-2"
              variant="outlined"
              color="primary"
              density="compact"
              :rules="[rules.required]"
            />
          </div>
        </div>
      </div>
      <hr class="divider-line my-3">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('mailboxes.config.auth_method.section_title') }}
          </h4>
        </div>
        <div class="settings-right">
          <div
            v-if="mailbox.auth_method === 'OAUTH2'"
            class="mb-5"
          >
            <label
              class="form-label right-gap font-weight-bold"
              for="oauth2_provider"
            >
              {{ $t('mailboxes.config.auth_method.oauth2_provider') }}
            </label>
            <v-select
              id="oauth2_provider"
              v-model="mailbox.oauth2_provider"
              class="mt-2 inline-middle"
              style="width: 300px;"
              variant="outlined"
              color="primary"
              density="compact"
              :items="oauthProviders"
              disabled
            />
          </div>
          <!-- <div
            v-if="mailbox.auth_method === 'OAUTH2'"
            class="mb-5"
          >
            <label
              class="form-label right-gap font-weight-bold"
              :class="{
                'grey--text': mailbox.oauth2_provider === 'GOOGLE',
              }"
              for="tenantId"
            >
              {{ $t('mailboxes.config.auth_method.oauth2_tenant_id') }}
            </label>
            <v-text-field
              id="tenantId"
              v-model="mailbox.oauth2_tenant_id"
              class="mt-2"
              :disabled="mailbox.oauth2_provider !== 'MICROSOFT'"
              :rules="[rules.required]"
            />
          </div> -->
          <div
            v-if="mailbox.auth_method === 'OAUTH2'"
            style="margin-bottom: -10px"
          >
            <label
              class="form-label right-gap font-weight-bold"
              for="clientId"
            >
              {{ $t('mailboxes.config.auth_method.oauth2_client_id') }}
            </label>
            <v-text-field
              id="clientId"
              v-model="mailbox.oauth2_client_id"
              style="margin-top: 5px"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="id !== 'new'"
              :rules="[rules.required]"
            />
          </div>
          <div
            v-if="mailbox.auth_method === 'OAUTH2'"
            style="margin-bottom: -10px"
          >
            <label
              class="form-label right-gap font-weight-bold"
              for="clientSecret"
            >
              {{ $t('mailboxes.config.auth_method.oauth2_client_secret') }}
            </label>
            <v-text-field
              id="clientSecret"
              v-model="mailbox.oauth2_client_secret"
              style="margin-top: 5px"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="id !== 'new'"
              :rules="[rules.required]"
            />
          </div>
        </div>
      </div>
      <hr class="divider-line my-3">
      <div class="settings-body">
        <div class="settings-left">
          <h4>
            {{ $t('mailboxes.config.action.section_title') }}
          </h4>
        </div>
        <div class="settings-right">
          <div style="margin-bottom: -10px">
            <label
              class="form-label right-gap font-weight-bold"
              for="folder"
            >
              {{ $t('mailboxes.config.action.workflow') }}
            </label>

            <WorkflowSelect
              id="workflow_ref"
              style="margin-left: auto"
              :workflows="workflows"
              :selected="mailbox.workflow_ref"
              @selected-changed="selectWorkflow"
              @get-more="(filter, reset) => getMoreWorkflows(filter, reset)"
              custom-select-wide
              select-panel-wide
              select-panel-on-top
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-end mt-6">
        <v-btn
          color="primary"
          :disabled="!isComplete"
          @click="save"
          rounded
        >
          <v-icon
            v-if="saveLoading"
            class="mr-2"
            size="16"
          >
            fas fa-spinner fa-pulse
          </v-icon>
          <template v-else>
            {{ $t('save') }}
          </template>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import { http } from '@/plugins/axios';

import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import WorkflowSelect from "@/components/extract/elements/Workflows/WorkflowSelect";
import BackLink from '@/components/common/elements/Navigation/BackLink';

export default {
  name: 'MailboxesConfig',

  components: {
    WorkflowSelect,
    BackLink,
  },

  data() {
    return {
      mailbox: {
        workflow_ref: null,
      },
      workflows: [],
      oauth2Changed: false,
      oauthProviders: [
        {
          title: 'Google',
          value: 'GOOGLE',
        },
        // {
        //   title: 'Microsoft',
        //   value: 'MICROSOFT',
        // }
      ],
      rules: {
        emailRequired: value => !!value || this.$t('forms.email_required'),
        required: value => !!value || this.$t('forms.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('forms.email_must_valid')
        },
      },
      saveLoading: false,
      showPassword: false,
    }
  },

  computed: {
    id() {
      return this.$route.params.id;
    },

    isComplete() {
      return this.mailbox.name
          && this.mailbox.email
          && this.mailbox.host
          && this.mailbox.port
          && this.mailbox.folder
          && this.mailbox.auth_method
          && (
            this.mailbox.auth_method === 'LEGACY'
            && (this.mailbox.password || this.mailbox.id !== 'new')
            || this.mailbox.auth_method === 'OAUTH2'
            && (
              this.mailbox.oauth2_provider === 'GOOGLE'
              || this.mailbox.oauth2_provider === 'MICROSOFT' && this.mailbox.oauth2_tenant_id
            )
            && this.mailbox.oauth2_client_id
            && this.mailbox.oauth2_client_secret
          );
    },
  },

  async mounted() {
    if (
      this.id !== 'new'
    ) {
      await this.getMailbox();
    } else {
      const empty = {
        auth_method: 'OAUTH2',
        type: 'WORKFLOW',
        host: 'imap.gmail.com',
        port: 993,
        folder: 'INBOX',
        oauth2_provider: 'GOOGLE',
      };
      this.mailbox = empty;

    }

    this.getMoreWorkflows();
  },

  methods: {
    async getMailbox() {
      try {
        const { data } = await http.get(
          `adapter/api/v1/mailboxes/${this.id}`,
        );
        this.mailbox = data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    selectWorkflow(id) {
      this.mailbox.workflow_ref = id;
    },

    async getMoreWorkflows(name) {
      const [workflows,] = await WorkflowAPI.getByUUID(null, name);
      this.workflows = workflows;
    },

    save() {
      if (
        this.id === 'new' && !this.mailbox.oauth2_refresh_token
      ) {
        this.oauth2();
      } else {
        this.postChanges();
      }
    },

    async postChanges() {
      if (!this.saveLoading) {
        if (!this.mailbox.type) {
          this.mailbox.type = 'WORKFLOW';
        }
        try {
          this.saveLoading = true;
          if (this.id === 'new') {
            await http.post(
              'adapter/api/v1/mailboxes/',
              this.mailbox,
            );
          } else {
            await http.put(
              `adapter/api/v1/mailboxes/${this.id}`,
              this.mailbox,
            );
          }
          this.$store.commit(
            'setSuccessMessage', this.$t('mailboxes.config.saved')
          );
          this.$store.commit('setSuccessSnackbar', true);
          this.$router.push({
            name: 'Workflows',
          });
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        } finally {
          this.saveLoading = false;
        }
      }
    },

    async oauth2() {
      const provider = this.mailbox.oauth2_provider;
      const settings  = {
        tenant_id: this.mailbox.oauth2_tenant_id,
        client_id: this.mailbox.oauth2_client_id,
        client_secret: this.mailbox.oauth2_client_secret,
      };

      if (
        (this.mailbox.oauth2_provider === "MICROSOFT" && (!settings.tenant_id ))
          || !settings.client_id
          || !settings.client_secret
      ) {
        this.$store.commit('setErrorMessage', this.$t('mailboxes.config.auth_method.oauth2_microsoft_error'));
        this.$store.commit('setSnackbar', true);
        return;
      }

      try {
        const { data } = await http.post(
          `adapter/api/v1/mailboxes/oauth2/${provider.toLowerCase()}/authorize/`,
          settings,
          {
            params: {
              origin_app: 'workflows',
            },
          }
        );
        const popup = window.open(
          data.uri, 'mailbox-oauth2', 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
        );

        const options = {
          capture: true,
          once: true
        };

        const callback = async (event) => {
          if (event.origin !== window.location.origin) {
            return;
          }

          if (event.data.type !== 'mailbox-oauth2-redirect-code') {
            return;
          }

          window.removeEventListener('message', callback, options);

          popup.close();

          const tokenPayload = {
            ...settings,
            code: event.data.code,
            code_verifier: data.code_verifier,
            state: data.state,
          };

          try {
            const { data: tokenData } = await http.post(
              `adapter/api/v1/mailboxes/oauth2/${provider.toLowerCase()}/token`,
              tokenPayload,
              {
                params: {
                  origin_app: 'workflows',
                },
              }
            );

            if (!tokenData.access_token || !tokenData.refresh_token) {
              this.$store.commit('setErrorMessage', this.$t('mailboxes.config.auth_method.oauth2_token_error'));
              this.$store.commit('setSnackbar', true);
              return;
            }
            this.mailbox.oauth2_refresh_token = tokenData.refresh_token;
            this.mailbox.oauth2_access_token = null;
            this.mailbox.oauth2_expires = null;
            this.postChanges();
          } catch (error) {
            error.handleGlobally && error.handleGlobally();
          }
        };
        window.addEventListener('message', callback, options);

      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .mailbox-config {
    padding: 28px 60px 28px 60px;
    height: fit-content;

    .settings-body {
      display: flex;

      .settings-left {
        width: 30%;
        padding-right: 20px;
      }

      .settings-right {
        width: 70%;
      }
    }

    .form-label {
      font-size: 0.8rem;
    }
  }

  .mailbox-config-card {
    max-width: 700px;
  }
</style>
