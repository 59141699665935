<template>
  <div
    :key="selectedId"
    class="workflow-select d-flex"
  >
    <div
      class="custom-select noselect"
      :class="{ disabled, 'custom-select-wide': customSelectWide }"
      @click="toggleSelectOn"
    >
      <div
        v-if="!(filter && selectOn)"
        class="workflow-name ellipsis"
      >
        <span :style="{'font-style': numberOfWorkflows ? 'normal' : 'italic' }">
          {{ selectedWorkflow.name }}
        </span>
        <v-icon
          v-if="clearable && selectedId"
          class="clear-icon clickable fas fa-times"
          size="13"
          :disabled="disabled"
          @click="$emit('selectedChanged', null)"
        />
      </div>
      <input
        v-else
        ref="filterInput"
        v-model="rawFilter"
        class="workflow-name"
        :placeholder="$t('filter')"
        @keydown.enter="handleEnter"
        @keydown.esc="handleEscape"
      >
      <v-icon
        class="open-icon"
        size="17"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        :class="{ 'select-panel-wide': selectPanelWide, 'select-panel-on-top': selectPanelOnTop }"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="(workflow, i) in workflows"
          :ref="`Option${i}`"
          :key="workflow.uuid"
          class="option ellipsis"
          :value="workflow.uuid"
          @click="handleSelect(workflow.uuid)"
        >
          {{ workflow.name }}
        </div>
        <div
          v-if="trimmedFilter && workflows.length === 0"
          class="option no-results"
        >
          {{ $t('workflows.no_results') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'WorkflowSelect',

  data() {
    return ({
      selectedId: -1,
      selectOn: false,
      rawFilter: '',
    })
  },

  computed: {
    lastPos() {
      return this.numberOfWorkflows - 1;
    },

    numberOfWorkflows() {
      return this.workflows.length;
    },

    selectedWorkflow() {
      if (!this.selectedId) {
        return {name: this.$t('workflows.select_workflow'), status: ''};
      }
      const workflow = this.workflows.find(d => d.uuid === this.selectedId);
      return workflow || { name: this.$t('workflows.no'), status: '' };
    },

    trimmedFilter() {
      return this.rawFilter.toLowerCase().trim();
    },
  },

  watch: {
    selected() {
      this.selectedId = this.selected;
    },

    selectedId(id) {
      setTimeout(() => {
        this.selectOn = false;
      }, 10);
      this.$emit('selectedChanged', id);
    },

    trimmedFilter: _.debounce(function() {
      const reset = true;
      this.$emit('getMore', this.trimmedFilter, reset);
    }, 300),
  },

  mounted() {
    this.selectedId = this.selected;
  },

  methods: {
    handleScroll() {
      const TOP_DIFFERENCE = 300;
      const selectPanel = this.$refs.selectPanel;
      const lastOption = this.$refs[`Option${this.lastPos}`];
      if (selectPanel && lastOption) {
        const panelTop = selectPanel.getBoundingClientRect().top;
        const lastTop = lastOption[0].getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loading
        ) {
          const reset = false;
          this.$emit('getMore', this.trimmedFilter, reset);
        }
      }
    },

    handleSelect(workflow_uuid) {
      this.selectedId = workflow_uuid;
      this.rawFilter = '';
    },

    handleEnter(e) {
      if (this.selectOn) {
        e.stopPropagation();
      }
      this.handleSelect(this.workflows[0] && this.workflows[0].id || 0);
      this.selectOn = false;
    },

    handleEscape(e) {
      if (this.selectOn) {
        e.stopPropagation();
      }
      this.selectOn = false;
      this.rawFilter = '';

    },

    toggleSelectOn() {
      if (this.disabled) {
        return;
      }
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        setTimeout(() => {
          const filterInput = this.$refs.filterInput;
          if (filterInput) {
            filterInput.focus();
          }
        }, 50);
      } else {
        this.rawFilter = '';
      }
    }
  },

  props: {
    workflows: {
      type: Array,
      required: true,
    },

    selected: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: Boolean,
      default: true,
    },

    customSelectWide: {
      type: Boolean,
      default: false,
    },

    selectPanelWide: {
      type: Boolean,
      default: false,
    },

    selectPanelOnTop: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['getMore', 'selectedChanged'],
}
</script>

<style lang="scss" scoped>
.workflow-select {
  .custom-select {
    width: 100%;
    max-width: 300px;
    height: 24px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;

    .workflow-name {
      max-width: 250px;
    }

    .open-icon {
      position: absolute;
      right: 11px;
      top: 11px;
      color: rgb(var(--v-theme-dark));
      opacity: var(--v-medium-emphasis-opacity);
    }

    .select-panel {
      width: 100%;
      max-width: 300px;
      max-height: 285px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 40px;
      left: -1px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;

      .option {
        height: 40px;
        padding: 8px;
        background-color: white !important;
        box-shadow: 0 0 10px 100px white inset;
        color: black !important;

        &.no-results {
          font-style: italic;
          color: rgb(var(--v-theme-grey-darken3)) !important;
        }
      }
    }

    .clear-icon {
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translateY(-50%);
      color: rgb(var(--v-theme-grey-darken4));
      opacity: 0.7;
    }

    .select-panel-wide {
      max-width: 100%;
    }

    .select-panel-on-top {
      top: auto;
      bottom: 40px;
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .disabled {
      background-color: #F5F5F5;
      color: #C8C8C8;
      cursor: not-allowed;
    }
  }

  .custom-select-wide {
    max-width: 100%;
  }
}
</style>
