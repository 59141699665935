<template>
  <v-card class="training-stepper">
    <NumberBullet
      :num="1"
      :incomplete="step <= 1"
    />
    <div class="inline-middle stepper-title">
      <div>{{ $t('models.select_dataset') }}</div>
      <small v-if="step > 1">
        {{ selectedDatasetObject.name }}
      </small>
    </div>
    <div class="stepper-content">
      <div
        v-if="step === 1"
        class="step-one-content"
      >
        <InfoBox
          v-if="completeDatasets.length > 0"
          class="bottom-gap"
        >
          {{ $t('models.select_complete_dataset', {number: $store.getters.MIN_ENTRIES}) }}
        </InfoBox>
        <div v-else>
          <div class="font-italic">
            {{ $t('models.no_complete_dataset') }}
          </div>
          <v-btn
            class="top-gap bottom-gap"
            color="primary"
            @click="$router.push('/suite/studio/datasets')"
            rounded
          >
            {{ $t('models.configure_datasets') }}
          </v-btn>
        </div>
        <DatasetSelect
          v-if="completeDatasets.length > 0"
          :datasets="completeDatasets"
          :selected="selectedDataset"
          @selected-changed="(id) => {selectedDataset = id}"
          @get-more="getCompleteDatasets(numberOfCompleteDatasets)"
        />
        <v-btn
          class="top-gap"
          color="primary"
          :disabled="selectedDataset === -1"
          @click="step = 2"
          rounded
        >
          <v-icon start>
            fas fa-check
          </v-icon>
          {{ $t('continue') }}
        </v-btn>
      </div>
    </div>
    <NumberBullet
      :num="2"
      :incomplete="step <= 2"
    />
    <div class="inline-middle stepper-title">
      <div>
        {{ $t('models.name') }}
      </div>
      <small v-if="step > 2">
        {{ modelName }}
      </small>
    </div>
    <div class="stepper-content">
      <div v-if="step === 2">
        <v-text-field
          v-model="modelName"
          variant="outlined"
          color="primary"
          density="compact"
          :hint="nameValid ? '' : `${$t('forms.name_hint')} ${$t('forms.unique_hint')}`"
          :placeholder="$t('models.type_new_name')"
          :disabled="!writableName"
          @keydown.enter="validateName"
        />
        <div class="separator-container top-gap bottom-gap">
          <div class="or-separator">
            {{ $t('or') }}
          </div>
        </div>
        <div class="model-select-container">
          <CustomSelect
            :items="orgModels"
            :selected="selectedModel"
            :disabled="disableModels"
            @selected-changed="handleCustomModelClick"
            @get-more="(nameFilter, reset) => getMoreModels(numberOfCustomModels, nameFilter, reset)"
          />
          <v-tooltip bottom>
            <template #activator="{ props }">
              <v-icon
                class="clickable primary--text clear-icon"
                size="16"
                :disabled="disableModels"
                v-bind="props"
                @click="clearCustomModel"
              >
                fas fa-eraser
              </v-icon>
            </template>
            {{ $t('forms.clear_selection') }}
          </v-tooltip>
        </div>
        <v-btn
          class="top-gap"
          color="primary"
          :disabled="!nameValid"
          @click="step = 3"
          rounded
        >
          <v-icon start>
            fas fa-check
          </v-icon>
          {{ $t('continue') }}
        </v-btn>
      </div>
    </div>
    <TrainingStep
      :step="3"
      :current-step="step"
      @click-launch="startTraining"
    />
  </v-card>
</template>

<script>
import model_mixin from '@/mixins/model.js';

import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { isNameValid } from '@/utils/FormValidation';

import NumberBullet from "@/components/common/elements/General/NumberBullet";
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';
import DatasetSelect from "@/components/extract/elements/Datasets/DatasetSelect";
import TrainingStep from '@/components/extract/elements/Training/TrainingStep';
import InfoBox from '@/components/common/elements/General/InfoBox';

export default {
  name: 'ExtractTrainingSteps',

  mixins: [
    model_mixin,
  ],

  components: {
    NumberBullet,
    CustomSelect,
    DatasetSelect,
    TrainingStep,
    InfoBox,
  },

  data() {
    return ({
      step: 1,
      modelName: '',
      writableName: true,
      selectedModel: 0,
      completeDatasets: [],
      totalCompleteDatasets: 0,
      selectedDataset: -1,
      selectedDatasetObject: {name: ''},
      newVersion: false,
      modelVersions: [],
    });
  },

  computed: {
    nameValid() {
      return isNameValid(this.modelName);
    },

    numberOfCustomModels() {
      return this.orgModels.length;
    },

    orgModels() {
      return this.customModels.filter(model => model.org_id === this.user.org_id);
    },

    user() {
      return this.$store.getters.loggedInUser;
    },

    disableModels() {
      return this.orgModels.length === 0;
    },

    numberOfCompleteDatasets() {
      return this.completeDatasets.length;
    },
  },

  watch: {
    async selectedDataset(newId, oldId) {
      if (newId != oldId) {
        this.$store.commit('setLoadingScreen', true);
        this.selectedDatasetObject = await this.getDataset(newId);
        this.$store.commit('setLoadingScreen', false);
      }
    }
  },

  async mounted() {
    await this.getCompleteDatasets();
    this.getCustomModels();
    if (this.numberOfCompleteDatasets > 0) {
      if (this.datasetId) {
        this.selectedDataset = this.datasetId;
        this.step = 2;
      } else {
        this.selectedDataset = this.completeDatasets[0].id;
      }
    }
  },

  methods: {
    validateName() {
      if (this.nameValid) {
        this.step = 3;
      }
    },

    handleCustomModelClick(id) {
      if (id > 0) {
        const model = this.orgModels.find(item => item.id === id);
        this.modelName = model.name;
        this.newVersion = true;
        this.writableName = false;
      } else {
        this.modelName = '';
        this.newVersion = false;
        this.writableName = true;
      }
    },

    clearCustomModel() {
      this.modelName = '';
      this.modelVersions = [];
      this.selectedModel = 0;
      this.newVersion = false;
      this.writableName = true;
    },

    async startTraining() {
      try {
        await DatasetAPI.export(
          this.selectedDataset,
          this.modelName.trim(),
          this.newVersion,
        );
        this.reset();
        this.$store.commit(
          'setSuccessMessage',
          this.$t('models.being_trained')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.step = 1;
      }
    },

    async getDataset(id) {
      try {
        if (id === -1) {
          return {name: ''};
        }
        return await DatasetAPI.getSingle(id);
      } catch (error) {
        return {name: ''};
      }
    },

    async getCompleteDatasets(offset = 0, limit = 20) {
      if (offset && offset >= this.totalCompleteDatasets) {
        return;
      }
      try {
        this.$store.commit('setLoadingScreen', true);
        let response = await DatasetAPI.get({
          offset: offset,
          limit: limit,
          status: 'complete',
        });
        if (offset > 0) {
          this.completeDatasets = [...this.completeDatasets, ...response.data];
        } else {
          this.completeDatasets = response.data;
        }
        this.totalCompleteDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    reset() {
      this.$emit('reset');
      this.step = 1;
      this.selectedDataset = -1;
      this.modelName = '';
      this.selectedModel = 0;
    },
  },

  props: {
    datasetId: {
      type: Number,
      default: 0,
    },
  },

  emits: ['reset'],
}
</script>

<style lang="scss" scoped>
.training-view {
  .training-stepper {
    width: 55%;
    padding-right: 30px;
    margin-right: 30px;

    .stepper-content {
      border-left: 1px solid rgb(var(--v-theme-grey));
      padding-left: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 12px;
      margin-top: 10px;
      margin-bottom: 10px;

      .step-one-content {
        height: 200px;
      }
    }

    .stepper-title {
      height: 20px;
    }

    .model-select-container {
      position: relative;
    }

    .clear-icon {
      position: absolute;
      top: 20px;
      left: 315px;
      transform: translateY(-50%);
    }

    .separator-container {
      position: relative;
      border-bottom: 2px solid #cacaca;
      color: #cacaca;
    }

    .or-separator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      font-size: small;
      font-style: italic;
      padding: 0 10px;
      text-transform: uppercase;
    }
  }
}
</style>
