<template>
  <div class="checkbox-ellipsis d-flex">
    <v-icon
      v-if="modelValue"
      color="primary"
      class="mr-2"
      :class="{ clickable: !disabled }"
      :style="{ opacity: disabled ? 0.5 : 1 }"
      @click="handleClick"
    >
      fas fa-check-square
    </v-icon>
    <div
      v-else
      color="grey-lighten-1"
      class="checkbox-empty mr-2"
      :class="{ clickable: !disabled }"
      :style="{ opacity: disabled ? 0.5 : 1 }"
      @click="handleClick"
    />
    <ItemName
      class="noselect checkbox-label"
      style="margin-top: -3px"
      :style="{ opacity: disabled ? 0.5 : 1 }"
      :item="{ name: label }"
      :clickable="!disabled"
      :show-id="false"
      @click="handleClick"
    />
  </div>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'CheckboxEllipsis',

  components: {
    ItemName,
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue);
      }
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: [ 'update:modelValue' ]
}
</script>

<style lang="scss" scoped>
.checkbox-ellipsis {
  max-width: 100%;

  .checkbox-empty {
    width: 20px;
    height: 20px;
    border: 2px lightgrey solid;
    border-radius: 4px;
    flex-shrink: 0;
  }

  .checkbox-label {
    flex-shrink: 0;
    max-width: 200px;
  }
}
</style>
