import { http } from '@/plugins/axios';

const BASE_PATH = `auth/api/v1/metrics`;

export class MetricsAPI {
  static get(params) {
    return http
      .get(BASE_PATH, {
        params,
      })
      .then(response => ({
        metrics: response.data,
        total: parseInt(response.headers["x-total-count"])
      }))
      .catch(error => Promise.reject(error));
  }

  static download(params) {
    return http
      .get(BASE_PATH + '/download', {
        params,
        responseType: 'arraybuffer',
      })
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  }
}
