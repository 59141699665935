<template>
  <div class="recycle-bin-view page-padding py-7">
    <PageTabs
      v-if="config && displayTabItems.length > 0 && activeTab != ''"
      v-model="activeTab"
      :tabs="displayTabItems"
    />
    <DeletedDocTypes
      v-if="config && displayTabItems.length > 0 && activeTab === 'doctypes'"
      :config="config"
      @change-total="total => docTypesTotal = total"
    />
    <DeletedDatasets
      v-if="config && displayTabItems.length > 0 && activeTab === 'datasets'"
      :config="config"
      @change-total="total => datasetsTotal = total"
    />
    <DeletedWorkflows
      v-if="displayTabItems.length > 0 && activeTab === 'workflows'"
      @change-total="total => workflowsTotal = total"
    />
    <v-card
      v-else-if="activeTab === ''"
      class="empty-bin-card vertical-centered"
    >
      <h3 class="bottom-gap text-h3">
        <RecycleBinIcon
          class="right-gap-sm inline-middle"
          style="margin-top: -4px; display: inline-block !important;"
          color="primary"
        />
        {{ $t('recycleBin.empty') }}
      </h3>
      <div v-if="config">
        {{ $t('recycleBin.empty_message', {number: config.delete_retention_days}) }}
      </div>
    </v-card>
  </div>
</template>

<script>
import PageTabs from '@/components/common/elements/General/PageTabs';
import DeletedDocTypes from '@/components/extract/views/RecycleBin/DeletedDocTypes';
import DeletedDatasets from '@/components/extract/views/RecycleBin/DeletedDatasets';
import DeletedWorkflows from '@/components/extract/views/RecycleBin/DeletedWorkflows';
import RecycleBinIcon from '@/components/common/elements/Navigation/RecycleBinIcon';
import { ConfigAPI } from '@/API/extract/ConfigAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';

export default {
  name: 'RecycleBinView',

  components: {
    PageTabs,
    DeletedDocTypes,
    DeletedDatasets,
    DeletedWorkflows,
    RecycleBinIcon,
  },

  data() {
    return ({
      activeTab: '',
      docTypesTotal: 0,
      datasetsTotal: 0,
      workflowsTotal: 0,
      config: null,
    })
  },

  computed: {
    tabItems() {
      return [
        {
          title: this.$t('docTypes.doc_types'),
          name: 'doctypes',
          bigNumber: this.docTypesTotal,
          products: ['extract'],
          fetchTotal: this.getDeletedDoctypesTotal,
        },
        {
          title: this.$t('models.datasets'),
          name: 'datasets',
          bigNumber: this.datasetsTotal,
          products: ['extract'],
          fetchTotal: this.getDeletedDatasetsTotal,
        },
        {
          title: this.$t('workflows.title'),
          name: 'workflows',
          bigNumber: this.workflowsTotal,
          products: ['workflows'],
          fetchTotal: this.getDeletedWorkflowsTotal,
        }
      ];
    },

    filteredTabItems() {
      return this.tabItems.filter(
        tab => tab.products.every(
          product => this.$store.getters.loggedInUser.products.includes(product),
        )
      );
    },

    displayTabItems() {
      return this.filteredTabItems.filter(tab => tab.bigNumber > 0)
    }
  },

  watch: {
    docTypesTotal() {
      this.getActiveTab();
    },

    datasetsTotal() {
      this.getActiveTab();
    },

    workflowsTotal() {
      this.getActiveTab();
    }
  },

  async created() {
    if (this.filteredTabItems.filter(tab => tab.products.includes('extract')).length > 0) {
      await this.getConfig();
    }
    const fetchTotals = this.filteredTabItems.map(tab => tab.fetchTotal());
    await Promise.all(fetchTotals);
    this.getActiveTab();
  },

  methods: {
    async getConfig() {
      this.config = await ConfigAPI.get();
    },

    getActiveTab() {
      if (this.displayTabItems.length > 0) {
        this.activeTab = this.displayTabItems[0].name;
      } else {
        this.activeTab = '';
      }
    },

    async getDeletedDatasetsTotal() {
      try {
        const response = await DatasetAPI.get({
          offset: 0,
          limit: 1,
          status: 'deleted',
        });
        this.datasetsTotal = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        console.log(error);
      }
    },

    async getDeletedDoctypesTotal() {
      try {
        const response = await DocTypeAPI.get(1, 0, null, 'deleted');
        this.docTypesTotal = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        console.log(error);
      }
    },

    async getDeletedWorkflowsTotal() {
      try {
        const [, total] = await WorkflowAPI.getByUUID(null, '', false, 0, 1, true);
        this.workflowsTotal = total;
      } catch (error) {
        // pass
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recycle-bin-view {
  position: relative;
  height: 100%;
}

.empty-bin-card {
  width: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}

</style>
