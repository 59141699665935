<template>
  <div class="entity-select">
    <v-text-field
      v-model="displayText"
      variant="outlined"
      color="primary"
      density="compact"
      class="v-select"
      :placeholder="placeholder"
      :disabled="disabled"
      :clearable="clearable"
      @click:clear="selectItem(null)"
      hide-details
    />

    <v-menu
      v-model="showMenu"
      location="bottom"
      activator="parent"
      :close-on-content-click="false"
    >
      <v-card class="entity-select-menu">
        <v-list class="pa-0">
          <v-infinite-scroll
            height="200"
            :items="internalItems"
            @load="loadMore"
          >
            <template
              v-for="item in internalItems"
              :key="item.id"
            >
              <v-list-item
                class="custom-list-item"
                :active="item[valueField] === selectedId"
                @click="selectItem(item)"
              >
                <ItemName
                  :item="item"
                  :name-field="nameField"
                  :show-id="false"
                  :clickable="false"
                />
              </v-list-item>
            </template>
            <template #loading>
              <v-icon
                style="top: -2px; margin-right: 5px;"
                size="16"
                color="primary"
              >
                fas fa-spinner fa-pulse
              </v-icon>
            </template>
            <template #empty>
              <span class="ml-2">
                {{ $t('no_more_results') }}
              </span>
            </template>
          </v-infinite-scroll>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import _ from 'lodash';
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'EntitySelect',

  components: {
    ItemName,
  },

  data() {
    return {
      showMenu: false,
      searchText: '',
      selectedId: null,
      internalItems: [],
      totalItems: 0,
    }
  },

  computed: {
    displayText: {
      get() {
        return this.selectedItem ? this.selectedItem[this.nameField] : this.searchText;
      },
      set(value) {
        this.searchText = value;
      }
    },

    numberOfItems() {
      return this.items.length;
    },
  },

  watch: {
    selectedItem: {
      immediate: true,
      handler(val) {
        this.selectedId = val?.[this.valueField];
        const selectedItem = this.internalItems.find(item => item[this.valueField] === val);
        this.searchText = selectedItem ? selectedItem[this.nameField] : '';
      }
    },

    searchText(val, oldVal) {
      if (this.selectedId && val?.length < oldVal?.length) {
        this.selectedId = null;
        this.searchText = '';
      }
      this.handleSearch(val);
    }
  },

  mounted() {
    this.loadInitialItems();
  },

  methods: {
    async loadInitialItems() {
      if (this.getItemsFn) {
        const data = await this.getItemsFn({
          limit: this.limit,
          offset: 0,
          filter: this.searchText?.toLowerCase().trim()
        });
        this.internalItems = data.items;
        this.totalItems = data.total;
      } else {
        this.internalItems = this.items;
      }
    },

    selectItem(item) {
      this.selectedId = item?.[this.valueField];
      this.searchText = item?.[this.nameField] || '';
      this.showMenu = false;
      this.$emit('selectedChanged', item?.[this.valueField]);
    },

    handleSearch: _.debounce(function(val) {
      if (val) {
        this.loadInitialItems();
      }
    }, 300),

    loadMore({done}) {
      console.log('loadMore', this.internalItems.length);
      this.getItemsFn({
        limit: this.limit,
        offset: this.internalItems.length,
        filter: this.searchText?.toLowerCase().trim()
      }).then(data => {
        const mappedItems = data.items.map(item => ({
          id: item[this.valueField],
          name: item[this.nameField]
        }));
        this.internalItems = [...this.internalItems, ...mappedItems];
        this.totalItems = data.total;
        if (data.items.length === 0) {
          done("empty");
        } else {
          done("ok");
        }
      });
    },
  },

  props: {
    getItemsFn: {
      type: Function,
      required: true,
    },

    limit: {
      type: Number,
      default: 5,
    },

    selectedItem: {
      type: Object,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    nameField: {
      type: String,
      default: 'name',
    },

    valueField: {
      type: String,
      default: 'id',
    },

    placeholder: {
      type: String,
      default: () => this.$t('select_entity'),
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['selectedChanged'],
}
</script>

<style lang="scss" scoped>
.entity-select {
  position: relative;
  font-size: 0.9rem;
}

.entity-select-menu {
  background-color: white;
}

:deep(.custom-list-item) {
  min-height: 40px;
  padding: 8px 16px;

  &:hover, &.highlighted, &.v-list-item--active {
    background-color: rgb(var(--v-theme-grey-lighten4));
  }
}
</style>
