import { http } from '@/plugins/axios';

const BASE_PATH = 'system_2/verify/';

export class VerifyAPI {
  static postGroupValue(valueId, value, externalToken=null) {
    return http
      .post(`${BASE_PATH}${valueId}/groups/`, value,
        {
          params: {
            token: externalToken || null,
            noAuth: !!externalToken,
            external: !!externalToken,
          }
        }
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static postMissingLabel(subgroupId, values, externalToken=null) {
    return http
      .post(`${BASE_PATH}groups/add_missing/${subgroupId}/`, values,
        {
          params: {
            token: externalToken || null,
            noAuth: !!externalToken,
            external: !!externalToken,
          }
        }
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static postMissingSubgroup(groupId, fileId, subgroup, externalToken=null) {
    return http
      .post(`${BASE_PATH}groups/${groupId}/`, subgroup,
        {
          params: {
            file_id: fileId,
            token: externalToken || null,
            noAuth: !!externalToken,
            external: !!externalToken,
          }
        }
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static confirmSubgroupValues(subgroupId, externalToken=null) {
    return http
      .post(`${BASE_PATH}groups/subgroup/${subgroupId}/confirm/`, {},
        {
          params: {
            token: externalToken || null,
            noAuth: !!externalToken,
            external: !!externalToken,
          }
        }
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static confirmGroupValues(groupId, fileId, externalToken=null) {
    return http
      .post(`${BASE_PATH}groups/${groupId}/confirm/`, {},
        {
          params: {
            file_id: fileId,
            token: externalToken || null,
            noAuth: !!externalToken,
            external: !!externalToken,
          }
        }
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getExtractionAgentsToReview({offset = 0, limit = 20, sortDesc = true, nameFilter = ''}) {
    return http.get(BASE_PATH, {
      params: {
        sort_desc: sortDesc,
        name_filter: nameFilter,
        offset: offset,
        limit: limit,
      },
    })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
