<template>
  <div class="inline-middle">
    <v-btn
      class="primary--text"
      style="top: -1px; box-shadow: none"
      variant="outlined"
      @click="toggleReplaceModelDialog = true"
      rounded
    >
      <v-icon
        size="16"
        start
      >
        fas fa-sync
      </v-icon>
      {{ $t('datatable.header.replaceExtractorModel') }}
    </v-btn>
    <v-dialog
      v-model="toggleReplaceModelDialog"
      max-width="45%"
    >
      <v-card class="dialog-card replace-model-card">
        <h2 class="dialog-title mb-3">
          <span>{{ $t("models.replace.title") }}</span>
        </h2>
        <div class="extractor-update-info bottom-gap">
          {{ $t('models.replace.info') }}
        </div>
        <v-row>
          <v-col cols="5">
            <CustomSelect
              class="clickable"
              :items="usedModels"
              :selected="selectedUsedModel"
              :filter="false"
              @selected-changed="handleUsedModelChange"
            />
            <v-select
              v-if="selectedUsedModel !== 0"
              v-model="selectedUsedModelVersion"
              class="mt-6 select-box"
              variant="outlined"
              color="primary"
              density="compact"
              item-title="version"
              :disabled="selectedUsedModel === 0"
              :items="selectedUsedModelVersions"
              :placeholder="$t('models.select_version')"
            />
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-center mt-10"
          >
            <v-icon
              color="primary"
              x-large
            >
              fas fa-chevron-right
            </v-icon>
          </v-col>
          <v-col cols="5">
            <CustomSelect
              :class="{ clickable: !disabled }"
              :items="compatibleModels"
              :selected="selectedNewModel"
              :disabled="disabled"
              :filter="false"
              @selected-changed="handleNewModelChange"
            />
            <v-select
              v-model="selectedNewModelVersion"
              class="mt-6 select-box"
              variant="outlined"
              color="primary"
              density="compact"
              item-title="version"
              :class="{ clickable: !disabled }"
              :items="selectedNewModelVersions"
              :placeholder="$t('models.no_version_available')"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
        <div style="text-align: right">
          <v-btn
            class="mt-5"
            style="box-shadow: none"
            color="primary"
            :disabled="selectedNewModelVersion <= 0 || !selectedNewModelVersion"
            @click="replaceModel"
            rounded
          >
            {{ $t("models.replace.replaceModel") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import model_mixin from '@/mixins/model.js';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';


export default {
  name: "ReplaceExtractorModel",

  mixins: [
    model_mixin,
  ],

  components: {
    CustomSelect,
  },

  data() {
    return ({
      toggleReplaceModelDialog: false,
      selectedUsedModel: 0,
      selectedUsedModelVersion: 0,
    });
  },

  computed: {
    usedModel() {
      if (this.selectedUsedModel > 0) {
        return this.usedModels.find(m => m.id === this.selectedUsedModel);
      }
      return null;
    },

    usedVersion() {
      if (this.selectedUsedModelVersion > 0) {
        return this.usedModel.versions.find(v => v.version === this.selectedUsedModelVersion);
      }
      return null;
    },

    selectedUsedModelVersions() {
      return this.usedModel ? this.usedModel.versions : [];
    },

    newModel() {
      if (this.selectedNewModel > 0) {
        return this.compatibleModels.find(m => m.id === this.selectedNewModel);
      }
      return null;
    },

    newVersion() {
      if (this.selectedNewModelVersion > 0) {
        return this.newModel.versions.find(v => v.version === this.selectedNewModelVersion);
      }
      return null;
    },

    selectedNewModelVersions() {
      return this.newModel ? this.newModel.versions : [];
    },

    disabled() {
      return !this.compatibleModels.length;
    },
  },

  watch: {
    usedModels() {
      this.selectedUsedModel = this.usedModels[0].id;
      this.selectedUsedModelVersion = this.usedModels[0].versions[0].version;
      this.getCompatibleModels(
        this.selectedUsedModel,
        this.selectedUsedModelVersion,
        this.usedVersion.used_labels,
      );
    }
  },

  mounted() {
    this.selectedUsedModel = this.usedModels[0].id;
    this.selectedUsedModelVersion = this.usedModels[0].versions[0].version;
    if (this.selectedUsedModel && this.selectedUsedModelVersion) {
      this.getCompatibleModels(
        this.selectedUsedModel,
        this.selectedUsedModelVersion,
        this.usedVersion.used_labels,
      );
    }
  },

  methods: {
    async handleUsedModelChange(id) {
      this.selectedUsedModel = id;
      this.selectedUsedModelVersion = this.usedModel.versions[0].version;
      await this.getCompatibleModels(
        this.selectedUsedModel,
        this.selectedUsedModelVersion,
        this.usedVersion.used_labels,
      );
    },

    handleNewModelChange(id) {
      this.selectedNewModel = id;
      if (this.newModel){
        this.selectedNewModelVersion = this.newModel.versions[0].version;
      } else {
        this.selectedNewModelVersion = null;
      }
    },

    async replaceModel() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.post(
          `system_2/document_type/${this.$route.params.id}/replace_extractor_model`,
          {},
          {
            params: {
              origin_model_version_id: this.usedVersion.id,
              destination_model_version_id: this.newVersion.id,
            }
          }
        );
        this.$store.commit('setSuccessMessage', this.$t('models.replace.success'));
        this.$store.commit('setSuccessSnackbar', true);
        this.toggleReplaceModelDialog = false;
        this.$emit('refreshUsedModels');
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },

  props: {
    usedModels: {
      type: Array,
      required: true,
    }
  },

  emits: ['refreshUsedModels'],
}
</script>

<style>
.extractor-update-info {
  padding: 10px;
  border: 1px solid rgb(var(--v-theme-primary));
  border-radius: 5px;
  background-color: #502BFF33;
}
.clickable input {
  cursor: pointer;
}
.replace-model-card {
  height: 510px;
  position: relative;
}
.select-box {
  max-width: 300px;
}
</style>
