<template>
  <div
    v-if="email !== null && model !== null"
    class="page-padding py-7"
  >
    <div class="email-correction-view__header">
      <BackLink
        :text="'All emails review'"
        @click="() => $router.push({ name: 'Corrections', query: { activeTab: 'emailsReview' } })"
        show-text
      />
      <ClassificationCorrection
        v-model="email.category_id"
        :submitting-review="submittingReview"
        :categories="model.categories"
        :discard-reasons="discardReasons"
        :reviewer-comment="reviewerComment"
        :fetch-datasets="handleFetchDatasets"
        @copy-to-dataset="copyToEmailsDataset"
        @submit-review="submitCorrection"
        @submit-discard-reason="submitCorrection"
        @update:reviewer-comment="(value) => reviewerComment = value"
        show-categories
      />
    </div>
    <Email
      :email="email"
    />
  </div>
</template>

<script>

import { EmailsAPI } from '@/API/classify/EmailsAPI';
import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import Email from '@/components/common/elements/Emails/Email.vue';
import ClassificationCorrection from '@/components/extract/views/Corrections/ClassificationCorrection.vue';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { displaySnackbarError, displaySnackbarSuccess } from '@/utils/SnackbarUtils';


export default {
  name: 'EmailClassificationReview',
  components: {
    Email,
    BackLink,
    ClassificationCorrection,
  },

  data() {
    return {
      email: null,
      model: null,
      submittingReview: false,
      reviewerComment: '',
      discardReasons: [
        {
          title: this.$t('workflows.emails.email_corrupted'),
          value: this.$t('workflows.emails.email_corrupted'),
        },
        {
          title: this.$t('workflows.documents.other'),
          value: this.$t('workflows.documents.other'),
        },
      ],
    };
  },

  async created() {
    await this.fetchEmail();
    await this.fetchClassificationModel();
  },

  methods: {
    async fetchEmail() {
      this.email = await EmailsAPI.fetchEmail({ emailId: this.emailId });
    },

    async fetchClassificationModel() {
      this.model = await ClassifyModelAPI.getModel(this.email.model_id);
    },

    async submitCorrection(discardReason) {
      this.submittingReview = true;
      try {
        const feedbackCategoryId = discardReason ? null : this.email.category_id;
        await EmailsAPI.updateEmail({
          emailId: this.email.id,
          feedbackCategoryId,
          discardReason,
          reviewerComment: this.reviewerComment,
        });
        const successMessage = discardReason ? this.$t('workflows.documents.review_discarded') : this.$t('workflows.documents.review_submitted');
        displaySnackbarSuccess(successMessage);
        this.$router.push({ name: 'Corrections', query: { activeTab: 'emailsReview' } });
      } catch (error) {
        displaySnackbarError(this.$t('corrections.correction_failed'));
      } finally {
        this.submittingReview = false;
      }
    },

    async handleFetchDatasets({offset = 0, limit = 20} = {}) {
      try {
        const response = await EmailsDatasetsAPI.fetchAll({offset, limit});
        return response
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async copyToEmailsDataset(data) {
      try {
        await EmailsDatasetsAPI.copyToEmailDataset(
          this.email.id,
          data.dataset_id,
          data.entry_name
        );
        displaySnackbarSuccess(this.$t('workflows.emails.copy_to_dataset_success'));
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },

  props: {
    emailId: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

.email-correction-view__header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
}

</style>