<template>
  <div>
    <div
      v-for="(token, i) in tokens"
      :key="i"
    >
      <div
        class="highlight-frame"
        :class="{ 'selected-token-frame': isTokenSelected(token) }"
        :style="{
          'border-color': getBorderColor(token),
          left: `${getHighlightX(token.coordinates.x_min)}vh`,
          top: `${getHighlightY(token.coordinates.y_min)}vh`,
          width: `${getHighlightX(
            token.coordinates.x_max - token.coordinates.x_min
          )}vh`,
          height: `${getHighlightY(
            token.coordinates.y_max - token.coordinates.y_min
          )}vh`,
        }"
        @mousedown="startTokensSelection(token)"
        @mouseenter="addTokenToSelection(token)"
        @mouseleave="(event) => removeTokenFromSelection(token, event)"
        @mouseup="finishTokensSelection"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TokenHighlights",

  data() {
    return {
      selectedTokens: [],
      isSelecting: false,
    };
  },

  created() {
    document.addEventListener('mouseup', this.finishTokensSelection);
  },

  unmounted() {
    document.removeEventListener('mouseup', this.finishTokensSelection);
  },

  methods: {
    getHighlightX(x) {
      return x * this.pageWidth;
    },

    getHighlightY(y) {
      return y * this.pageHeight;
    },

    getBorderColor(token) {
      /* \u2611 is a checkbox with a checkmark, \u2610 is a checkbox without a checkmark */
      if (token.word === '\u2611') {
        return 'red';
      } else if (token.word === '\u2610') {
        return 'violet';
      } else {
        return '#999';
      }
    },

    isTokenSelected(token) {
      return this.selectedTokens.some((selectedToken) => selectedToken === token);
    },

    startTokensSelection(token) {
      this.isSelecting = true;
      this.selectedTokens = [];
      this.selectedTokens.push(token);
    },

    addTokenToSelection(token) {
      if (this.isSelecting) {
        this.selectedTokens.push(token);
      }
    },

    removeTokenFromSelection(token, mouseLeaveEvent) {
      if (this.isSelecting) {
        const elementRect = mouseLeaveEvent.target.getBoundingClientRect();
        if (mouseLeaveEvent.clientX < elementRect.left) {
          this.selectedTokens = this.selectedTokens.filter((selectedToken) => selectedToken !== token);
        }
      }
    },

    finishTokensSelection() {
      if (this.isSelecting) {
        this.isSelecting = false;
        const coordinates = {};
        coordinates.x_min = Math.min(...this.selectedTokens.map((token) => token.coordinates.x_min));
        coordinates.x_max = Math.max(...this.selectedTokens.map((token) => token.coordinates.x_max));
        coordinates.y_min = Math.min(...this.selectedTokens.map((token) => token.coordinates.y_min));
        coordinates.y_max = Math.max(...this.selectedTokens.map((token) => token.coordinates.y_max));
        this.$emit('selectToken', coordinates);
        this.selectedTokens = [];
      }
    },
  },

  props: {
    tokens: {
      type: Array,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  },

  emits: ['selectToken'],
};
</script>

<style lang="scss" scoped>

.highlight-frame {
  position: absolute;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  opacity: 0.4;
  background-color: transparent;
}

.selected-token-frame {
  background-color: rgb(var(--v-theme-primary));
}

</style>
