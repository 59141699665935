<template>
  <div
    class="docs-view"
    @dragenter="dragInsideEnter"
    @dragover="dragInsideEnter"
    @dragleave="inside = false; dragging = false;"
    @drop="dropFile"
  >
    <FilesTable
      v-if="!dragging"
      ref="filesTable"
      :loading="loading"
      @select-files="selectFiles"
      @validate="f => $emit('validate', f)"
    />
    <v-card
      v-else
      class="upload-card pa-4"
    >
      <div
        class="
          d-flex
          flex-column
          justify-center
          align-center
          upload-container
        "
        :class="{ 'upload-container__active': inside }"
      >
        <div
          class="text-h3"
          :class="{
            'upload-container__active': inside,
            'primary--text': !inside,
          }"
        >
          {{ $t("dropFile") }}
        </div>
      </div>
    </v-card>
    <FileInput
      ref="uploader"
      @change="uploadRefresh"
    />
  </div>
</template>

<script>
import { FileAPI } from '@/API/extract/FileAPI';

import file_mixin from "@/mixins/file.js";
import FilesTable from "@/components/extract/elements/Documents/FilesTable";
import FileInput from '@/components/common/elements/Forms/FileInput';

export default {
  name: 'DocsView',

  mixins: [file_mixin],

  components: {
    FilesTable,
    FileInput,
  },

  data() {
    return ({
      dragging: false,
      inside: false,
    });
  },

  computed: {
    loading() {
      return this.docTypeLoading || this.$store.getters.loadingScreen;
    },
  },

  methods: {
    async handleDrop(files) {
      await this.uploadRefresh(files);
    },

    async uploadRefresh(files) {
      await this.uploadFiles(files);
      this.$refs.filesTable.checkStatus(true);
    },

    async startUpload(file) {
      try {
        await FileAPI.post(this.$route.params.id, file);
        this.totalFiles = this.totalFiles + 1;
        this.totalFilesDisplay = this.totalFilesDisplay + 1;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    selectFiles() {
      this.$refs.uploader.click();
    },
  },

  props: {
    docTypeLoading: {
      required: true,
      type: Boolean,
    },
  },

  emits: ['validate'],
}
</script>

<style lang="scss" scoped>
  .docs-view {
    .upload-card {
      width: 100%;
      height: 500px;
    }

    .upload-container {
      width: 100%;
      height: 100%;
      border: 2px dashed rgb(var(--v-theme-primary-lighten1));

      &__active {
        background-color: rgb(var(--v-theme-primary-lighten2));
        color: rgb(var(--v-theme-primary-lighten1));
      }
    }
  }
</style>
