<template>
  <TableWithFooter
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-row class="flex-nowrap table-row">
        <v-col cols="auto">
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            style="margin-left: 16px"
            :disabled="disabled"
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <ItemName
            :item="{ name: $t('datatable.header.dataPointName') }"
            :clickable="false"
            :editing-allowed="false"
            :show-id="false"
          />
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          cols="2"
        >
          <ItemName
            :item="{ name: $t('datatable.header.value_type') }"
            :clickable="false"
            :editing-allowed="false"
            :show-id="false"
          />
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          cols="2"
          class="d-flex align-center justify-center"
        >
          <ItemName
            style="width: fit-content"
            :item="{ name: $t('datatable.header.precision') }"
            :clickable="false"
            :editing-allowed="false"
            :show-id="false"
          />
        </v-col>
        <v-col
          v-if="user.role === 'orgadmin'"
          cols="3"
          class="d-flex align-center"
        >
          <ItemName
            :item="{ name: $t('dataPoints.model') }"
            :clickable="false"
            :editing-allowed="false"
            :show-id="false"
          />
        </v-col>
        <v-col
          v-if="user.role === 'sysadmin'"
          cols="3"
          class="d-flex align-center"
        >
          <ItemName
            :item="{ name: $t('dataPoints.label') }"
            :clickable="false"
            :editing-allowed="false"
            :show-id="false"
          />
        </v-col>
      </v-row>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <draggable
          v-model="pages[currentPage]"
          ghost-class="ghost-dp"
          handle=".handle"
          :disabled="repositioningDisabled"
          @change="(event) => handleDrag(event, pages[currentPage])"
        >
          <v-row
            v-for="item in pages[currentPage]"
            :key="item.id"
            class="table-row fade-in table-row-height flex-nowrap"
          >
            <v-col cols="auto">
              <v-icon
                color="grey"
                size="17"
                class="handle"
                :class="{ clickable: !repositioningDisabled }"
                :style="{ opacity: repositioningDisabled ? 0 : 0.5 }"
              >
                fas fa-grip-vertical
              </v-icon>
              <v-checkbox
                v-model="item.selected"
                class="mt-0"
                :disabled="disabled"
                @change="$emit('setItems', [...allItems])"
                hide-details
              />
            </v-col>
            <v-col cols="4">
              <ItemName
                :key="item.id"
                :ref="`name_${item.id}`"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :clickable="!disabled"
                @save-file-name="(id, newName) => {saveDPName(id, newName, item);}"
                @name-click="handleEditButton($route.params.id, item.id)"
              />
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              cols="2"
              :class="{
                'clickable': !disabled,
                'primary--text': !disabled,
              }"
              @click="handleEditButton($route.params.id, item.id)"
            >
              {{ $t(`datatable.value_type.${item.value_type}`) }}
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              class="d-flex align-center justify-center"
              cols="2"
            >
              <StatItem
                v-if="(item.nb_valid_values + item.nb_invalid_values) > 0"
                :left="item.nb_valid_values"
                :right="item.nb_valid_values + item.nb_invalid_values"
              />
              <span v-else>
                —
              </span>
            </v-col>
            <v-col cols="3">
              <span v-if="user.role === 'orgadmin' && item.category === 'generative'">
                {{ $t('dataPoints.generative.title') }}
              </span>
              <div
                v-else-if="user.role === 'orgadmin' && ['custom', 'group_based'].includes(item.category) && item.model_name"
                style="width: calc(100% - 20px)"
                class="d-flex"
              >
                <ItemName
                  :item="{ name: item.model_name }"
                  :clickable="false"
                  :editing-allowed="false"
                  :show-id="false"
                  :badge="item.model_version ? `v${item.model_version}` : ''"
                />
              </div>
              <div v-else-if="user.role === 'sysadmin'">
                <ItemName
                  :item="{ name: `${getEntityInfo(item)}`}"
                  :clickable="false"
                  :editing-allowed="false"
                  :show-id="false"
                />
              </div>
              <span v-else>
                —
              </span>
            </v-col>
          </v-row>
        </draggable>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import { DataPointAPI } from '@/API/extract/DataPointAPI';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { VueDraggableNext } from "vue-draggable-next";

import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import StatItem from '@/components/extract/elements/DocTypes/StatItem';

export default {
  name: 'DataPointsTable',

  components: {
    ItemName,
    TableWithFooter,
    StatItem,
    draggable: VueDraggableNext,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      extractionMethodMap: {
        'custom': this.$t('dataPoints.model'),
        'classic': this.$t('dataPoints.rules'),
      },
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
    isAdmin() {
      return ['orgadmin', 'sysadmin'].includes(this.user.role);
    },

    allSelected: {
      get() {
        if (this.pages[this.currentPage]) {
          return this.pages[this.currentPage].every(item => item.selected);
        }
        return false;
      },
      set(allSelected) {
        const selected = this.allItems.map(item => {
          if (this.pages[this.currentPage].includes(item)) {
            item.selected = allSelected;
          }
          return item;
        });
        this.$emit('setItems', [...selected]);
      }
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },

    repositioningDisabled() {
      return this.disabled || this.filteredItems.length < this.allItems.length;
    }
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
    },
  },

  methods: {
    handleDrag(event, currentPageDataPoints) {
      if (this.repositioningDisabled) {
        return
      }
      const dataPoints = [...this.allItems];
      const currentIndex = (this.currentPage - 1) * this.itemsPerPage;
      this.updatePos(event.moved.element.id, currentIndex + event.moved.newIndex);
      // For smooth transition until position is updated, and items are reloaded
      dataPoints.splice(currentIndex, this.itemsPerPage, ...currentPageDataPoints);
      this.$emit('setItems', [...dataPoints]);
    },

    async updatePos(id, pos) {
      try {
        this.$store.commit('setLoadingScreen', true);
        await DataPointAPI.put({ id, position: pos });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
        this.$emit('getItems');
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    handleEditButton(id, itemId) {
      if (this.user.role === 'orgadmin' && !this.disabled) {
        {
          this.$router.push({
            name: 'DatapointConfig',
            params: {
              id, itemId,
            },
          });
        }
      }
    },

    async saveDPName(id, name, dp) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await DataPointAPI.put({ id, name });
        this.allSelected = false;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
        this.$store.commit('setSuccessSnackbar', true);
        dp.name = name;
        this.$emit('saveName');
      } catch (error) {
        this.docLoading = false;
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    getEntityInfo(dp) {
      const eps = dp.extraction_paths;
      if (eps.length === 1) {
        const entity = eps[0].what.custom_entity;
        if (entity) {
          if (this.$te(`entities.${entity[0][1]}`)) {
            return this.$t(`entities.${entity[0][1]}`);
          }
          return entity[0][1];
        }
      }
      return '-';
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setItems', 'getItems', 'saveName', 'copyGenerative'],
}
</script>
