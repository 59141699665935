<template>
  <v-card
    class="file-uploader top-gap pa-4"
    :disabled="disabled"
    @dragenter="dragInsideEnter"
    @dragover="dragInsideEnter"
    @dragleave="inside = false"
    @drop="f => dropFile(f, supportedExtensions)"
  >
    <div
      class="d-flex flex-column justify-center align-center upload-container"
      :class="{ 'upload-container__active': inside }"
    >
      <div
        style="text-align: center"
        :class="{ 'upload-container__active': inside }"
      >
        <div v-if="loading">
          <v-icon
            color="primary"
            size="30"
          >
            fas fa-spinner fa-pulse
          </v-icon>
          <div class="top-gap">
            {{ $t('fileUploader.processing') }}
          </div>
        </div>
        <div v-else-if="uploadingFiles.length === 0">
          <div
            v-if="dragging"
            class="text-h3"
            :class="{ 'upload-container__active': inside, 'primary--text': !inside }"
          >
            {{ $t('dropFile') }}
          </div>
          <div v-else>
            <v-icon
              color="primary"
              size="30"
            >
              fas fa-upload
            </v-icon>
            <div class="top-gap bottom-gap-lg">
              {{ $t('noFilesMessage') }}
            </div>
            <v-btn
              color="primary"
              style="box-shadow: none"
              variant="outlined"
              @click="selectFiles"
              rounded
            >
              <v-icon
                size="16"
                start
              >
                fas fa-plus
              </v-icon>
              {{ $t('addFiles') }}
            </v-btn>
          </div>
        </div>
        <div v-else>
          <v-icon
            color="primary"
            size="30"
          >
            fas fa-file
          </v-icon>
          <div class="top-gap bottom-gap-lg">
            {{ uploadingFiles[0].name }}
            <span v-if="uploadingFiles.length > 1">
              {{
                $tc(
                  'fileUploader.and_other_files',
                  uploadingFiles.length - 1,
                  { count: uploadingFiles.length - 1 }
                )
              }}
            </span>
            {{ $tc('readyForUpload', uploadingFiles.length > 1 ? 2 : 1) }}
          </div>
        </div>
        <FileInput
          ref="uploader"
          :supported-extensions="supportedExtensions"
          @change="handleUploadClick"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import file_mixin from '@/mixins/file.js';
import FileInput from '@/components/common/elements/Forms/FileInput';

export default {
  name: 'FileUploader',

  mixins: [file_mixin],

  components: {
    FileInput,
  },

  data() {
    return ({
      dragging: false,
      inside: false,
      uploadingFiles: [],
    })
  },

  watch: {
    initialsFiles() {
      this.uploadingFiles = this.initialsFiles;
      this.$refs.uploader.reset();
    },
  },

  created() {
    this.uploadingFiles = this.initialsFiles;
  },

  methods: {
    reset() {
      this.uploadingFiles = [];
      this.$refs.uploader.reset();
    },

    selectFiles() {
      this.$refs.uploader.click()
    },

    handleUploadClick(files) {
      if (files.length > 0) {
        this.uploadingFiles = files;
        this.$emit('filesSelected', this.uploadingFiles);
      }
    },

    handleDrop(files) {
      this.uploadingFiles = files;
      this.$emit('filesSelected', this.uploadingFiles);
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    initialsFiles: {
      type: Array,
      default: () => [],
    },

    supportedExtensions: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['filesSelected'],
}
</script>

<style lang="scss" scoped>
.file-uploader {
    width: 100%;
    height: 500px;
    text-align: center;

  .upload-container {
    width: 100%;
    height: 100%;
    border: 3px dashed rgb(var(--v-theme-primary-lighten2));

    &__active {
      background-color: rgb(var(--v-theme-primary-lighten2));
      color: rgb(var(--v-theme-primary-lighten1));
    }
  }
}
</style>
