import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI.js';
import { i18n } from '@/plugins/i18n/i18n';
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';


const locale = i18n.global;

export async function uploadEmails(datasetId, emailsFiles) {
  const length = emailsFiles.length;
  if (length <= 0) {
    return;
  }

  await startUpload(datasetId, emailsFiles);
}

export async function uploadZippedEmailsDatasets({zipFile, importStrategy}) {
  await EmailsDatasetsAPI.uploadZippedEmailsDatasets({zipFile, importStrategy});
  displaySnackbarSuccess(locale.t('emailsDatasets.emails_upload_started'));
}

async function startUpload(datasetId, emailsFiles) {
  await EmailsDatasetsAPI.uploadEmailFile(datasetId, emailsFiles);
  displaySnackbarSuccess(locale.t('emailsDatasets.emails_upload_started'));
}

export const SUPPORTED_EXTENSIONS = ['.eml', '.msg', '.txt', '.pst'];